import React, {useEffect, useState} from 'react';
import Input from "../../components/Input";
import AuthHOC from "../HOC/Auth";
import {withRouter} from "react-router-dom";
import {useRegister} from "../../hooks/useAuth";
import i18next from "i18next";

const Login = ({history,match}) => {



    const urlParams = new URLSearchParams(window.location.search);
    const defaultEmail = urlParams.get('email');

    const { email,onNameChange,onCompanyChange,onEmailChange,onPasswordChange,onSubmit,validation } = useRegister({
        defaultEmail:defaultEmail
    });



    return (
        <div className="auth">
            <div className="auth__main" style={{backgroundImage: 'url("/assets/img/bg-login-mobile.jpg")'}}>
                <div className="auth__wrap">
                    <div className="auth__preview"><img className="auth__pic" src="/assets/img/logo-white.svg" width={48} alt="Logo" /></div>
                    <div className="auth__title title title_xl">{i18next.t("Register")}</div>
                    <div className="auth__subtitle title title_sm">{i18next.t("Subcharger register description")}</div>
                </div>
            </div>
            <div className="auth__container">
                <div className="auth__inner">
                    <div className="auth__head">
                        <div className="auth__title title title_xl">
                            <p>{i18next.t("Register")}</p>
                        </div>
                    </div>

                        <div className="field auth__field">
                            <div className="field__label">{i18next.t("Company name")}</div>
                            <div className="field__wrap">
                                <Input onChange={(val)=>{
                                    onCompanyChange(val)
                                }}

                                       placeholder={i18next.t("Enter your company name")}
                                       errors={validation.company}
                                />
                                <div className="field__icon"><i className="la la-user-alt " /></div>
                            </div>
                        </div>
                        <div className="field auth__field">
                            <div className="field__label">Full name</div>
                            <div className="field__wrap">
                                <Input onChange={(val)=>{
                                    onNameChange(val)
                                }}
                                       placeholder={i18next.t("Enter your fullname")}
                                       errors={validation.name}
                                />
                                <div className="field__icon"><i className="la la-user-alt " /></div>
                            </div>
                        </div>
                        <div className="field auth__field">
                            <div className="field__label">{i18next.t("Email")}</div>
                            <div className="field__wrap">
                                <Input onChange={(val)=>{
                                    onEmailChange(val)
                                }}
                                       value={email}
                                       placeholder={i18next.t("Enter your e-mail")}
                                       type="email"
                                       errors={validation.email}
                                />
                                <div className="field__icon"><i className="la la-envelope " /></div>
                            </div>
                        </div>
                        <div className="field auth__field">
                            <div className="field__label">{i18next.t("Password")}</div>
                            <div className="field__wrap">
                                <Input onChange={(val)=>{
                                    onPasswordChange(val)
                                }}
                                       placeholder={i18next.t("Enter your password")}
                                       type="password"
                                       errors={validation.password}
                                />
                                <div className="field__icon"><i className="la la-lock " /></div>
                            </div>
                        </div>
                        <div className="auth__flex"><label className="switch auth__switch"><input className="switch__input" type="radio" name="terms" defaultChecked /><span className="switch__content">{i18next.t("I agree with terms & conditions")}</span></label></div>
                        <div className="auth__btns">
                            <button className="btn auth__btn" onClick={onSubmit}>{i18next.t("Sign Up")}</button>
                            <button className="btn btn_light auth__btn" onClick={()=>{
                            history.push("/auth/login")
                        }}>{i18next.t("Sign In")}</button></div>
                </div>
            </div>
            <div className="auth__bg" style={{backgroundImage: 'url("/assets/img/bg-login-sign-up.jpg")'}} />
        </div>

    );
}

export default withRouter (AuthHOC(Login));
