import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import Table from "../../../components/Table/table";
import Head from "../../../components/Table/head";
import Avatar from "react-avatar";
import getStatusColorByAccount, {dateFormat, statusAccount} from "../../../utils/func";
import {DropdownMenu} from "../../../components/Button";
import Pagination from "../../../components/Table/pagination";
import {useUsers} from "../../../hooks/useUsers";
import i18next from "i18next";
import moment from "moment";
import ReactTooltip from "react-tooltip";

const Users = ({history,location}) => {

    const context = useContext(ProfileContext);
    const sidebar = <SidebarChild history={history}/>;
    const { data , onChangePagination,totalPage,page , onChangeFilters} = useUsers({
        onLoad: () => {
            ReactTooltip.rebuild();
        }
    });

    useEffect(()=>{
        onChangeFilters({});
    },[location]);

    return (
       <>
           <Layout sidebar={sidebar} title={i18next.t("Users")}>
               <div>
                   {/* panel*/}
                   <div className="panel js-panel">
                       <div className="panel__head panel__head_line">
                           {/* btn group*/}
                           <div className="panel__group btn-group btn-group_tabs">
                               <button className="btn btn_light btn_icon" onClick={()=>{
                                   history.push("/settings/users/create");
                               }}><i className="la la-pencil-alt "></i> {i18next.t("New User")}</button>
                           </div>
                       </div>
                       <div className="panel__body">
                           <div className="panel__tab js-panel-tab" style={{display: 'block'}}>
                               {/* data*/}
                               <div className="data data_list">
                                   <div className="data__container">
                                       <Table head={
                                           <>
                                               <Head extraClassName="data__cell_md" onClick={onChangeFilters} sortKey={"name"}>{i18next.t("Name")}</Head>
                                               <Head extraClassName="data__cell_md" onClick={onChangeFilters} sortKey={"email"}>{i18next.t("Email")}</Head>
                                               <Head extraClassName="data__cell_md" onClick={onChangeFilters} sortKey={"email"}>{i18next.t("Last Seen")}</Head>
                                               <Head extraClassName={"data__cell_xs text-right"}>{i18next.t("Actions")}</Head>
                                           </>
                                       }>
                                           {
                                               data.map((v,i)=>{
                                                   var local = moment(v.last_seen, 'YYYY-MM-DD HH:mm:ss');
                                                   return (
                                                       <div className="data__item" key={i}>
                                                           <div className="data__row">
                                                               <div className="data__cell data__cell_md">
                                                                   <div className="data__main">
                                                                       <div className="data__avatar">
                                                                           <Avatar name={v.name} maxInitials={2} round={true} size={50}/>
                                                                       </div>
                                                                       <div className="data__wrap">
                                                                           <div className="data__content"><strong>{v.name}</strong></div>
                                                                           <div className="data__label">{context.value.company.name}</div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                               <div className="data__cell data__cell_md">
                                                                   <div className="data__content">{v.email}</div>
                                                                   <div className="data__label">{i18next.t("Email")}</div>
                                                               </div>
                                                               <div className="data__cell data__cell_md">
                                                                   <div className="data__content">
                                                                       <span data-tip={dateFormat(v.last_seen,'DD MMMM YYYY HH:mm')}>{local.startOf('minute').fromNow()}</span>
                                                                   </div>
                                                                   <div className="data__label">{v.company.timezone}</div>
                                                               </div>
                                                               <div className="data__cell data__cell_xs text-right">
                                                                   <div className="data__content">
                                                                       <div className="action action_stroke" onClick={()=>{
                                                                           history.push('/settings/user/'+v.id);
                                                                       }}>
                                                                           <div className={"c-p"}>
                                                                               <i className="la la-ellipsis-h "/>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   );
                                               })
                                           }
                                       </Table>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="panel__foot">
                           <Pagination totalPage={totalPage}  activePage={page} onChange={onChangePagination}/>
                       </div>
                   </div>
               </div>
           </Layout>
       </>
    );
}

export default withRouter (AppHOC(Users));
