import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import SidebarChild from "./sidebarChild";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import Table from "../../components/Table/table";
import Head from "../../components/Table/head";
import Avatar from "react-avatar";
import {useAccounts} from "../../hooks/useAccounts";
import Pagination from "../../components/Table/pagination";
import getStatusColorByAccount, {
    capitalize,
    dateFormat,
    getStatusColorByCollection,
    statusAccount,
    statusSubscriptions
} from "../../utils/func";
import {DropdownMenu} from "../../components/Button";
import i18next from "i18next";
import * as R from 'ramda';
import ReactTooltip from "react-tooltip";
import ActionMenu from "./action_menu";
import ProgressIndicator from "../../components/Theme/ProgressIndicator";
import {useSubscriptions} from "../../hooks/useSubscriptions";
import {ConfirmButton} from "../../components/Button/confirm";
import {Trans} from "react-i18next";
const Subscriptions = ({history,i18}) => {

    const [sortMenu,setSortMenu] = useState(false);
    const [actionOpen,setActionOpen] = useState(false);
    const [status,setStatus] = useState(null);
    const [activeBtn,setActiveBtn] = useState(null);
    const [menu,setMenu] = useState("active");
    const [currentSubscription,setCurrentSubscription] = useState(-1);

    const { data , onChangePagination,totalPage,page , onChangeFilters , summary ,onChange,setRefresh , loading , onExport,onChangePage} = useSubscriptions({
        onLoadData: () => {
            ReactTooltip.rebuild();
            setActiveBtn(null);
        }
    });

    useEffect(()=>{
        ReactTooltip.rebuild();
    },[activeBtn]);

    const sidebar = <SidebarChild history={history} menu={menu} setMenu={setMenu} onChangePage={onChangePage}  onChangeFilters={onChangeFilters} data={summary}/>;

    const GetActions = ({item}) => {
        if(item.actions.length == 0){
            return (
                <>
                    {i18next.t("No Available Actions")}
                </>
            )
        }
        return (
            item.actions.map((a,i)=>{
                let title = i18next.exists("message." + a + "_title") ? <Trans i18nKey={"message." + a + '_title'}/> : i18next.t("Are you sure?");
                let message = i18next.exists("message." + a) ? <Trans i18nKey={"message." + a}/> : null;
                return (
                    <ConfirmButton onClick={async ()=>{
                        await onChange(item.id,{
                            status:a.toUpperCase(),
                        },setRefresh)
                        setActiveBtn(null);
                    }} title={title} styles={"action action_stroke action_btn "+getStatusColorByCollection(a)} message={message}>
                        {i18next.t(a.toUpperCase())}
                    </ConfirmButton>
                );
            })

        )
    };


    return (
        <Layout sidebar={sidebar} title={i18next.t("Subscriptions")} onSearch={onChangeFilters}>

            <div>
                {/* panel*/}
                <div className="panel js-panel">
                    <div className="panel__head panel__head_line">
                        <div className="panel__group btn-group text-right btn-group_tabs action_menu_group">

                            <button className="btn btn_light btn_icon" style={{float:"right"}} onClick={()=>{
                                onExport();
                            }}><i className="la la-file-excel "></i> {i18next.t("Export")}</button>

                        </div>

                        {/* btn group*/}
                    </div>
                    <div className="panel__body">
                        <div className="panel__tab js-panel-tab" style={{display: 'block'}}>
                            {/* data*/}
                            <div className="data data_list">
                                <div className="data__container">
                                   <Table head={
                                           <>
                                               <Head extraClassName="data__cell_lg" onClick={onChangeFilters}>{i18next.t("Name")}</Head>
                                               <Head extraClassName="data__cell_md" onClick={onChangeFilters}>{i18next.t("Plan")}</Head>
                                               <Head extraClassName="data__cell_md" onClick={onChangeFilters} sortKey={"started_at"}>{i18next.t("Start Date")}</Head>
                                               <Head extraClassName="data__cell_md">{i18next.t("Status")}</Head>
                                               <Head extraClassName={"data__cell_sm text-center"}>{i18next.t("Actions")}</Head>
                                           </>
                                       }
                                   loading={loading}>
                                       {
                                           data.map((v,i)=>{
                                               return (
                                                   <>
                                                   <div className="data__item" key={i} onMouseEnter={()=>{
                                                       setCurrentSubscription(i);
                                                       console.log("hover");
                                                   }} onMouseLeave={()=>{
                                                       if(currentSubscription===i){
                                                           setCurrentSubscription(-1);
                                                       }
                                                   }}>
                                                       <div className={"data__row "+(activeBtn===i ? "action_menu_button_list" : null)+""}>
                                                           {activeBtn === i ?
                                                               <div
                                                                   className="data__cell data__cell_lg action_btn_list float-div">
                                                                   <GetActions item={v}/>
                                                               </div>
                                                               :
                                                               <>
                                                                   <div className="data__cell data__cell_lg c-p" onClick={()=>{
                                                                       //history.push('/collections?filter__account_id='+v.account.id);
                                                                   }}>
                                                                       <div className="data__main">
                                                                           <div className="data__avatar">
                                                                               <Avatar name={v.account.full_name}
                                                                                       maxInitials={2} round={true}
                                                                                       size={50}/>
                                                                           </div>
                                                                           <div className="data__wrap text_wrap_email">
                                                                               <div className="data__content">
                                                                                   <strong>{v.account.full_name}</strong></div>
                                                                               <div
                                                                                   className="data__label">{v.account.contact_email}</div>
                                                                           </div>
                                                                       </div>
                                                                       <div style={{width:"28%",maxHeight:'30px',minHeight:'30px',position:"absolute"}}>
                                                                         <ProgressIndicator active={currentSubscription} that={i} values={v.progressIndicator} />
                                                                       </div>
                                                                   </div>
                                                                   <div className="data__cell data__cell_md">
                                                                       <div className="data__wrap text_wrap_email">
                                                                           <div className="data__content">{v.plan_dump.title}</div>
                                                                       </div>
                                                                   </div>

                                                                   <div className="data__cell data__cell_md">
                                                                       <div className="data__content">
                                                                           {dateFormat(v.started_at,"DD MMMM YYYY HH:mm")}
                                                                       </div>
                                                                   </div>

                                                                   <div className="data__cell data__cell_md">
                                                                       <div className="data__content">
                                                                           {statusSubscriptions(v.status,dateFormat(v.user?.last_seen,"DD MMMM YYYY HH:mm"))}
                                                                       </div>
                                                                   </div>

                                                               </>
                                                           }


                                                           <div className="data__cell data__cell_sm text-center action_btn_row">
                                                               <div className="data__content">
                                                                   <DropdownMenu currentActive={activeBtn} active={i} onClick={()=>{
                                                                       if(activeBtn!=i)
                                                                           setActiveBtn(i);
                                                                       else
                                                                           setActiveBtn(null);
                                                                   }}>
                                                                   </DropdownMenu>
                                                               </div>
                                                           </div>

                                                       </div>


                                                   </div>
                                               </>
                                               );
                                           })
                                       }
                                   </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel__foot">
                        <Pagination totalPage={totalPage}  activePage={page} onChange={onChangePagination}/>
                    </div>
                </div>
            </div>


        </Layout>
    );
}

export default withRouter (AppHOC(Subscriptions));
