import React from 'react';
import * as R from 'ramda';
import Input from "./index";
import i18next from "i18next";

export default function Radio({name,label,onClick,value,selected}) {
    console.log(value+"==="+selected);
    return (
        <>
            <label className="switch"><input className="switch__input" type="radio" value={value} checked={selected==value ? true : false} name={name} onClick={onClick}/><span className="switch__content"> {label}</span></label>
        </>

    );
}
