import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import config from '../../../config';
import Back from "../../layouts/back";
import Delete from "../../layouts/delete";
import {useUser} from "../../../hooks/useUsers";
import i18next from "i18next";
import {useEmailTemplate} from "../../../hooks/useEmailTemplate";
import CKEditor from "ckeditor4-react";
import Head from "../../../components/Table/head";
import moment from "moment";
import Avatar from "react-avatar";
import {dateFormat} from "../../../utils/func";
import Table from "../../../components/Table/table";


const EmailTemplateForm = ({history, match, edit}) => {

    const sidebar = <SidebarChild history={history}/>;
    const context = useContext(ProfileContext);
    const {data, onSubmit, onDataChange, validation, form, onDelete, actions} = useEmailTemplate();
    const [activeLanguageTab, setActiveLanguageTab] = useState(config.languages[0].key);
    useEffect(() => {
        onDataChange({id: match.params.id});
    }, [match.params.id]);


    if(edit && form?.id===undefined){
        return null;
    }



    return (
        <Layout sidebar={sidebar} title={i18next.t(form.name)}>
            <div className="panel">
                <Back onClick={() => {
                    history.goBack()
                }}/>
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings___inner">

                            <div className="panel__group btn-group btn-group_tabs mb-30">
                                {
                                    config.languages.map((item, index) => {
                                        const number = index + 1;
                                        return (
                                            <button key={"contract_tab" + number}
                                                    className={"btn btn_light btn_icon js-panel-btn " + (activeLanguageTab === item.key || (activeLanguageTab === item.key && index === 0) ? 'active' : '')}
                                                    onClick={() => {
                                                        setActiveLanguageTab(item.key);
                                                    }}>{item.value}</button>
                                        )
                                    })
                                }
                            </div>

                            {config.languages.map((item, index) => {
                                const template = Object.values(form).find(i => i.lang === item.key);

                                const template_id = item.key;
                                return (
                                    <div key={item.key}
                                         className={activeLanguageTab === item.key || (activeLanguageTab === 0 && index === 0) ? '' : 'hidden'}>
                                        <div className="form__row">
                                            <div className="form__col flex_100">
                                                <div className="field form__field">
                                                    <Field label={i18next.t("Title")} value={form?.template?.[template_id]?.title}
                                                           type="text" onChange={(e) => {
                                                        onDataChange({
                                                            template:{
                                                                ...form.template,
                                                                [template_id]: {
                                                                    ...form?.template?.[template_id],
                                                                    title: e.target.value
                                                                }
                                                            }
                                                        })
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="settings__inner">

                                            <div className="form__row">
                                                <div className="form__col flex_100">
                                                    <CKEditor
                                                        data={form?.template?.[template_id]?.content || ""}
                                                        config={{
                                                            allowedContent: true,
                                                            height: '700px',
                                                            basicEntities:false,
                                                            htmlEncodeOutput:false
                                                        }}
                                                        onChange={(event) => {
                                                            onDataChange({
                                                                template:{
                                                                    ...form.template,
                                                                    [template_id]: {
                                                                        ...form?.template?.[template_id],
                                                                        content: event.editor.getData()
                                                                    }
                                                                }
                                                            })

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );


                            })}


                        </div>

                    </div>

                </div>
            </div>

            <h2 className={"panel-title"}>{i18next.t("Variables")}</h2>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings___inner">
                            <Table head={
                                <>
                                    <Head extraClassName="data__cell_md">{i18next.t("Key")}</Head>
                                    <Head extraClassName="data__cell_md">{i18next.t("Value")}</Head>

                                </>

                            }
                            minimal={true}
                            className={"simple-table"}
                            >
                                {
                                    Object.values(form.variables).map((item,key)=>{

                                        return (
                                            <div className="data__item" key={key}>
                                                <div className="data__row">

                                                    <div className="data__cell data__cell_md">
                                                        <div className="data__content">{Object.keys(form.variables)[key]}</div>

                                                    </div>
                                                    <div className="data__cell data__cell_md">
                                                        <div className="data__content">{item}</div>

                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings__foot mt-20 width_100 flex_100">

                <div className="settings__btns" style={{display: "block"}}>
                    <button className="settings__btn btn text-right" onClick={() => {
                        onSubmit();
                    }}>{i18next.t('Save')}</button>
                </div>
            </div>

        </Layout>
    );
}

export default withRouter(AppHOC(EmailTemplateForm));
