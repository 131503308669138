import React, {useState} from 'react';
const AuthHOC = (WrapperComponent) => {

    class HOC extends React.Component{

        render(){


            return (
                <>
                    <WrapperComponent {...this.props} />
                </>
            );
        }
    }

    return HOC;


};

export default AuthHOC
