import React from 'react';
import * as R from 'ramda';
import Input from "./index";
import i18next from "i18next";
import Field from "../Input/field";
import Select from "../Select";
import {useIntegrations, usePlan} from "../../hooks/usePlan";
import {usePaymentMethods} from "../../hooks/usePaymentMethods";
import * as moment from "moment";
import Checkbox from "../Checkbox";

export default function AddPlan({data,onChange,validation}) {
    const {data:plans} = usePlan();
    const {data:paymentMethods} = usePaymentMethods();
    const {gateways} = useIntegrations("ACCOUNTING");


    let plansList = plans.map((item)=>{
        return {"key":item.id,"value":item.title};
    });


    let paymentMethodList = paymentMethods && paymentMethods.map((item)=>{
        return {"key":item.key,"value":item.name};
    });

    let einvoicesList = gateways && Object.values(gateways).map((item,key)=>{
        return {"key":Object.keys(gateways)[key],"value":item};
    });



    return (
        <>
            <div className="form__row">
                <div className="form__col">
                    <div className="field form__field">
                        <Select onChange={(e)=>{
                            onChange({
                                plan_id:e.target.value
                            });
                        }} selected={data?.plan_id} label={i18next.t("Plan")} errors={validation?.plan_id} options={plansList} />
                    </div>
                </div>
                <div className="form__col">
                    <div className="field form__field">
                        <Field onChange={(e)=>{
                            onChange({subscription_started_at:e.target.value});
                        }}  value={data?.subscription_started_at || moment().format('YYYY-MM-DDTHH:00:00')} type={"datetime-local"} label={i18next.t("Subscription Start Date")} />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__col">
                    <div className="field form__field">
                        <Select onChange={(e)=>{
                            onChange({
                                payment_method:e.target.value
                            });
                        }} selected={data?.payment_method} errors={validation?.payment_method} label={i18next.t("Ödeme Yöntemi")} options={paymentMethodList} />
                    </div>
                </div>
            </div>
            <div className={"form__row "+(data?.payment_method!=="bank_transfer" ? "hidden" : null)}>
                <div className={"form__col"}>
                    <Checkbox onClick={(e)=>{
                        onChange({
                            first_invoice_paid:e.target.checked
                        });
                    }} selected={data?.first_invoice_paid}  label={i18next.t("Mark the first bill as paid")} />
                </div>
            </div>
        </>
    );
}
