
import {useEffect, useState} from "react";
import * as R from 'ramda';
import {error, success, sumObject} from "../utils/func";
import {
    createAccount,
    getAccount,
    getAccounts,
    getAccountsExportUrl,
    getAccountsSummary,
    putAccount, sendInvitation, sendPaymentMethodNotify
} from "../api/account";
import {useHistory} from "react-router-dom";
import {forgotPassword} from "../api/auth";

export const useAccounts = ({onLoadData=f=>f}) => {
    const [data, setData] = useState([]);
    const [entity, setEntity] = useState(0);
    const [form, setForm] = useState({
        company:null,
        contact_email:null,
        name:null,
        contact_phone_number:null,
        invite:true
    });
    const [validation,setValidation] = useState();
    const [submit, setSubmit] = useState(false);
    const [exportFile, setExportFile] = useState(false);
    const [update, setUpdate] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        status:"OPEN"
    });
    const [summaryLoading,setSummaryLoading] = useState(false);
    const [summary,setSummary] = useState({
        active : 0,
        invited: 0,
        invited_failed: 0,
        closed: 0,
        draft: 0,
        not_found_credit_card:0
    });
    const history = useHistory();

    useEffect(() => {
        const getData = async () => {
            const res = await getAccounts({
                page: page,
                filters: filters,
            });
            await setData(res.data)
            await setTotalPage(res.meta.last_page)
            setLoading(true);
            onLoadData();

        };
        setLoading(false);
        getData();
        return () => {

        };
    }, [page,filters]);

    useEffect(()=>{
        setPage(1);
    },[filters]);

    useEffect(()=>{
        const getData = async () => {
            const res = await getAccountsSummary();
            await setSummary({
                ...res,
                all : res.OPEN + res.INVITED + res.INVITED_FAILED + res.CLOSED + res.DRAFT,
                active : res.OPEN,
                invited: res.INVITED,
                invited_failed: res.INVITED_FAILED,
                closed: res.CLOSED,
                draft: res.DRAFT,

            })
            await setSummaryLoading(true);
        };
        if(!summaryLoading){
            getData();
        }

    },[summaryLoading]);

    useEffect(()=>{
        const updateData = async () => {
            if(R.prop('id',update)!==undefined){
                try{
                    const res = await putAccount(update.id,update.data);
                    update.callback()
                    success("")
                }catch (e) {
                    error("ERROR")
                }

            }
        };
        updateData();
    },[update]);

    useEffect(()=>{
        const syncData = async () => {

            try{
                if(form.id){
                    const res = await putAccount(form.id,form);
                }else {
                    const res = await createAccount(form);
                }
                success("")
                history.push("/accounts")
            }catch (e) {
                if(e.group === "validation"){
                    let validate = {};

                    for (let error in e.data){
                        validate = {...validate,[error] : e.data[error][0]};
                    }

                    setValidation(validate)
                }else{
                    error(e.message);
                }
            }

        };
        if(submit) {
            syncData();

        }
        setSubmit(false)
    },[submit]);

    useEffect(()=>{
        const getData = async () => {

            try{
                const res = await getAccount(entity);
                setForm({
                    id:res.id,
                    company:res.company,
                    contact_email:res.contact_email,
                    firstname:res.firstname,
                    lastname:res.lastname,
                    contact_phone_number:res.contact_phone_number,
                    status:res.status
                });

            }catch (e) {

                error(e.message);
                history.push("/accounts")

            }

        };
        if(entity > 0) {
            getData();
        }

    },[entity]);

    useEffect(()=>{

        if(exportFile){

            const getExportFile = async () => {
                const res = await getAccountsExportUrl({
                    page: page,
                    filters: filters,
                });


                window.location.href = res.url;
            }
            getExportFile();
            setExportFile(false);
        }

    },[exportFile]);




    return {
        form,
        validation,
        submit,
        data,
        totalPage,
        page,
        summary,
        loading,
        onChangePagination : (e) => {
            setPage(e);
        },
        onChangeFilters: (e) => {
            setFilters({...filters,...e})
        },
        onChange: (id,data,callback) => {
            setUpdate({
                id: id,
                data: data,
                callback:callback
            })
        },
        setRefresh: () => {
            setSummaryLoading(false);
            setFilters({...filters,fake:true});
        },
        onLoadEntity: (id) => {
            setEntity(id);
        },
        onDataChange: (v) => {
            setForm({...form,...v});
        },
        onSubmit: (v) =>{
            setSubmit(true)
        },
        onExport: () => {
            setExportFile(true);
        },
        onChangePage: (i) => {
            setPage(i)
        },
        actions : {
            inviteAll : (state) => {
                const sendNotify = async () => {
                    try{
                        const res = sendInvitation(state);
                        success();
                        setSummaryLoading(false);
                        setFilters({...filters,fake:Math.random()});

                    }catch (e) {
                        error(e)
                    }
                }

                sendNotify();

            },
            resetPassword : (email) => {
                const sendPassword = async (email) => {
                    try{
                        const res = forgotPassword({email:email});
                        success();
                    }catch (e) {
                        error(e)
                    }
                }

                sendPassword(email);

            },
            paymentMethodNotify : (state) => {

                const sendNotify = async () => {
                    try{
                        const res = sendPaymentMethodNotify(state);
                        success();
                        setSummaryLoading(false);
                        setFilters({...filters,fake:Math.random()});

                    }catch (e) {
                        error(e)
                    }
                }

                sendNotify();

            },

        }
    }
};
