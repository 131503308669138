import React from 'react';
import * as R from 'ramda';

export default function Input({value=null,onChange=f=>f,placeholder=null,type='text',errors,readony=false,style={}}) {
    const isError = R.isNil(errors);
    return (
        <>
            <input readOnly={readony} style={style} className={'field__input '+(isError ? null : 'invalid_input')} value={value} onChange={(e) => {
                onChange(e)
            }} type={type} placeholder={placeholder}/>
            {
                isError ? null :
                <>
                    <em className={"invalid_text"}>{errors}</em>
                    <br />
                </>
            }
        </>

    );
}
