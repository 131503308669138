import React from 'react';
import * as R from 'ramda';
import Input from "./index";

export default function Field({value=null,onChange=f=>f,placeholder=null,type='text',errors,label='',description='',icon='',readonly=false}) {
    const isError = R.isNil(errors);
    return (
        <>
            <div className="field__label">{label}</div>
            <div className="field__wrap  mb-30">
                <Input readony={readonly} onChange={onChange} value={R.isNil(value) ? '' : value} placeholder={placeholder} type={type} errors={errors} />
                {
                    !R.empty(icon) ? <div className="field__icon"><i className={"la la-"+icon} /></div> : ''
                }
                {
                    !R.empty(description) ? <em className={"description"}>{description}</em> : ''
                }

            </div>
        </>

    );
}
