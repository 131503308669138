import React, {useEffect, useState} from 'react';
import {post} from "../utils/http";
import storage from "../utils/storage";
import i18next from "i18next";
import ReactTooltip from "react-tooltip";
import {checkUrl, redirectCreditCard} from "../utils/func";
import {useHistory} from "react-router-dom";
import config from "../config";
import ReactGA from "react-ga";
import * as R from 'ramda';
export const DomainContext = React.createContext();


export function DomainProvider (props) {
    const [value, setValue] = useState({
         name: null,
         logo:null,
         lang:null,
         sidebar:null,
         timezone:null
    });

    function getData(){
        async function fetchData() {

            const response = await post('/utils/domain',{
                host:window.location.hostname
            });
            setValue({
                ...response
            });
            let analyticsId = config.analytics;

            if(response?.lang && storage.get("language")==null){
                storage.set("language",response?.lang)
                await i18next.changeLanguage(storage.get("language"));
            }
            if(response?.tracking_codes?.analytics_id && !R.isEmpty(response?.tracking_codes?.analytics_id)  && checkUrl(window.location.pathname,'/checkout')){
                analyticsId = response?.tracking_codes?.analytics_id;
            }

            ReactGA.initialize(analyticsId);
            ReactGA.pageview(window.location.pathname + window.location.search);

        }

        fetchData()
    }

    useEffect (() => {

        try{
            //if(window.location.hostname!==config.admin_url){
                getData();
            //}

        }catch (e) {

        }
    }, []);



    return (
        <DomainContext.Provider value={{
            value
        }}>
            {props.children}
        </DomainContext.Provider>
    )
}
