import {get, put , post} from "../utils/http";

export const savePlan = async (data) => {
    try {
        return await post('/plans',data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const updateEmailTemplate = async (id,data) => {
    try {
        return await put('/email-templates/'+id,data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getEmailTemplates = async (filters) => {
    try {
        return await get('/email-templates',filters);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getEmailTemplate = async (id) => {
    try {
        return await get('/email-templates/'+id);
    }catch (e) {
        throw e.response.data.error;
    }
}




