import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../HOC/App";
import {ProfileContext} from "../../contexts/ProfileContext";
import Layout from "../layouts";
import SidebarChild from "./sidebarChild";
import Field from "../../components/Input/field";
import Back from "../layouts/back";
import {useUser} from "../../hooks/useUsers";
import i18next from "i18next";
import {useAccountForm, useAccounts} from "../../hooks/useAccounts";
import * as R from 'ramda';
import AddPlan from "../../components/Plan/addPlan";
import {InvoiceForm} from "../../components/Plan/invoiceForm";
import {DomainContext} from "../../contexts/DomainContext";



const AccountForm = ({history,match,edit}) => {

    const [submitForm,setSubmitForm] = useState(false);

    const context = useContext(ProfileContext);
    const dContext = useContext(DomainContext);
    const { form  ,onDataChange,onSubmit,onChangeFilters,summary,validation,onLoadEntity,actions} = useAccounts({
        onLoadData: () => {

        }
    });



    useEffect(()=>{
        onLoadEntity(match.params.id);
    },[]);

    const sidebar = <SidebarChild history={history} onChangeFilters={onChangeFilters} data={summary}/>;



    return (
        <Layout sidebar={sidebar} title={i18next.t(edit ? "Update Account" : "New Account")}>
            <div className="panel">

                {
                    edit ?  <Back onClick={()=>{history.goBack()}} right={
                        edit && form.status==="OPEN" ?  <div className="tag blue c-p" style={{float:"right"}}  onClick={()=>{
                                        actions.resetPassword(form.contact_email)
                                    }}>{i18next.t("Send Reset Password Email")}</div>
                                 : null
                    }  /> : <Back onClick={()=>{history.goBack()}}  />
                }
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings___inner">

                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field  label={i18next.t("Company Name")} value={form.company} type="text" onChange={(e)=>{
                                            onDataChange({
                                                company: e.target.value
                                            })
                                        }} errors={validation?.company} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field  label={i18next.t("Firstname")} value={form.firstname} type="text" onChange={(e)=>{
                                            onDataChange({
                                                firstname: e.target.value
                                            })
                                        }} errors={validation?.firstname} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field  label={i18next.t("Lastname")} value={form.lastname} type="text" onChange={(e)=>{
                                            onDataChange({
                                                lastname: e.target.value
                                            })
                                        }} errors={validation?.lastname} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field  readonly={edit} label={i18next.t("Email")} value={form.contact_email} type="email" onChange={(e)=>{
                                            onDataChange({
                                                contact_email: e.target.value
                                            })
                                        }} errors={validation?.contact_email} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field label={i18next.t("Phone")} value={form.contact_phone_number} type="text" onChange={(e)=>{
                                            onDataChange({
                                                contact_phone_number: e.target.value
                                            })
                                        }} errors={validation?.contact_phone_number} />
                                    </div>
                                </div>
                            </div>

                            {
                            form.status === "DRAFT" || !edit ?

                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <label className="switch"><input value={true} checked={form.invite} className="switch__input" type="checkbox"
                                                                             onChange={(e)=>{
                                                                                 onDataChange({
                                                                                     invite: e.target.checked
                                                                                 })
                                                                             }} />
                                                <span className="switch__content">{i18next.t("ACCOUNT_INVITE")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                : null }
                            {
                                form.status === "DRAFT" || !edit ?
                                    dContext.value?.type==="MEMBER_CRM" ? null :
                                    <div className="form__row">
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <label className="switch"><input value={true} checked={form.add_plan} className="switch__input" type="checkbox"
                                                                                 onChange={(e)=>{
                                                                                     onDataChange({
                                                                                         add_plan: e.target.checked,
                                                                                         first_invoice_paid: e.target.checked
                                                                                     })
                                                                                 }} />
                                                    <span className="switch__content">{i18next.t("Add Plan?")}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    : null }


                        </div>

                    </div>

                </div>
            </div>
            {form?.add_plan ?
            <>
                <h2 className={"panel-title"}>{i18next.t("Plan Details")}</h2>

                <div className="panel">
                    <div className="settings">
                        <div className="settings__container">
                            <div className="settings___inner">
                                <AddPlan data={form} validation={validation} onChange={onDataChange} />
                            </div>
                        </div>
                    </div>
                </div>


                <h2 className={"panel-title"}>{i18next.t("Billing Details")}</h2>
                <div className="panel">
                    <div className="settings">
                        <div className="settings__container">
                            <div className="settings___inner">
                                <InvoiceForm data={form} validation={validation} onChange={onDataChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
                : null}

            <button className="settings__btn width_100 mt-20 btn" onClick={()=>{
                onSubmit();
            }}>{i18next.t(edit ? 'Update' : 'Save')}</button>

        </Layout>
    );
}

export default withRouter (AppHOC(AccountForm));
