import React from "react";
import * as R from 'ramda';
import i18next from "i18next";
import ReactPaginate from 'react-paginate';

const Pagination = ({totalPage, activePage = 15, onChange}) => {

    if (totalPage <= 1) return null;
    return (

        <div className={"paginator"}>
            <ReactPaginate pageCount={totalPage}  onPageChange={(data) => {
                onChange(data.selected + 1)
            }}
                           forcePage={activePage-1}
                           previousLabel={<><i className="la la-angle-left"/> {i18next.t("Prev")}</>}
                           nextLabel={<>{i18next.t("Next")} <i className="la la-angle-right "/></>}
                           activeLinkClassName={"active"}
                           previousLinkClassName={"btn btn_light"}
                           nextLinkClassName={"btn btn_light"}
                           pageLinkClassName={"pager__link action "}
                           pageRangeDisplayed={3}
            />

        </div>

    );
}

export default Pagination;
