import {get, put , post} from "../utils/http";

export const getSubscriptions = async (filters) => {
    try {
        return await get('/subscriptions',filters);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getSubscriptionsExportUrl = async (filters) => {
    try {
        return await get('/subscriptions/export',filters);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getSubscriptionsSummary = async (filters) => {
    try {
        return await get('/subscriptions/summary');
    }catch (e) {
        throw e.response.data.error;
    }
}

export const putSubscription = async (id,data) => {
    try {
        return await put('/subscriptions/'+id,data);
    }catch (e) {
        throw e.response.data.error;
    }
}

