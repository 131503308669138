import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import Select from "../../../components/Select";
import {useSettings} from "../../../hooks/useSettings";
import Input from "../../../components/Input";
import i18next from "i18next";
import {usePlan} from "../../../hooks/usePlan";
import Image from "../../../components/Input/image";


import CKEditor from 'ckeditor4-react';

const CheckoutSettings = ({history}) => {


    const sidebar = <SidebarChild history={history}/>;



    const {data,onDataChange,onSubmit,onGroupChange} = useSettings();
    const {data: plans} = usePlan();
    const value = {
        ...data
    };
    useEffect(()=>{
        onGroupChange("checkout");
    });

    useEffect(()=>{
        console.log(data);
    },[data]);

    const activeOptions = [
        {"key":"1","value":i18next.t("Active")},
        {"key":"0","value":i18next.t("Passive")},
    ];

    const showHide = [
        {"key":"1","value":i18next.t("Yes")},
        {"key":"0","value":i18next.t("No")},
    ];

    const taxRate = [
        {"key":"18","value":"Turkey"},
    ];


    const [activePlanTab, setActivePlanTab] = useState(0);
    const [activeContractTab, setActiveContractTab] = useState(0);


    return (
        <Layout sidebar={sidebar} title={i18next.t("Checkout Settings")} pageClass={"checkout_settings"}>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings___inner">
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Select options={activeOptions} onChange={(e)=>{
                                            onDataChange({
                                                ...data,
                                                is_active: e.target.value
                                            })
                                        }} selected={value.is_active} label={i18next.t("Is Active?")} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row ">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field onChange={(e)=>{
                                            onDataChange({...data,page_title:e.target.value});
                                        }} label={i18next.t("Checkout Page Title")} value={value.page_title} type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h2 className={"panel-title"}>{i18next.t("Checkout Settings")}</h2>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings___inner">

                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Select options={showHide} onChange={(e)=>{
                                            onDataChange({
                                                ...data,
                                                hide_tax: e.target.value
                                            })
                                        }} selected={value.hide_tax} label={i18next.t("Show excluding VAT on plan page")} />
                                    </div>
                                </div>
                                {value?.hide_tax && value.hide_tax=="1" ?
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Select options={taxRate} onChange={(e)=>{
                                                onDataChange({
                                                    ...data,
                                                    tax_rate: e.target.value
                                                })
                                            }} selected={value.tax_rate} label={i18next.t("Tax Manager")} />
                                        </div>
                                    </div> : null}
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Select options={showHide} onChange={(e)=>{
                                            onDataChange({
                                                ...data,
                                                phone_input: e.target.value
                                            })
                                        }} selected={value.phone_input} label={i18next.t("Show phone number")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h2 className={"panel-title"}>{i18next.t("Plan Settings")}</h2>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings___inner">
                            <div className="panel__group btn-group btn-group_tabs mb-30">
                                {
                                    plans.map((item,index) => {
                                       return (
                                           <button key={item.id} className={"btn btn_light btn_icon js-panel-btn "+(activePlanTab===item.id || (activePlanTab===0 && index===0) ? 'active' : '')} onClick={()=>{
                                               setActivePlanTab(item.id);
                                           }}>{item.title}</button>
                                       )
                                    })
                                }
                            </div>
                            {
                                value.is_active=="1" ?
                                    <>
                                        {
                                            plans.map((item,index) => {
                                                const html = data?.plans?.["plan_"+item.id]?.description  || "";
                                                return (
                                                    <div key={item.id} className={activePlanTab===item.id || (activePlanTab===0 && index===0) ? '' : 'hidden'}>
                                                        <div className="form__row">
                                                            <div className="form__col">
                                                                <div className="field form__field">
                                                                    <Field onChange={(e)=>{
                                                                        let added;
                                                                        try{
                                                                            added = data.plans["plan_"+item.id];
                                                                        }catch (e) {
                                                                            added = {"uuid":item.uuid};
                                                                        }
                                                                        onDataChange({...data,plans:{
                                                                                ...data.plans,
                                                                                ["plan_"+item.id] : {
                                                                                    ...added,
                                                                                    title:e.target.value
                                                                                }
                                                                            }});
                                                                    }} label={i18next.t("Title")} value={data?.plans?.["plan_"+item.id]?.title} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form__row">
                                                            <div className="form__col">
                                                                <div className="field form__field">
                                                                    <Field onChange={(e)=>{
                                                                        let added;
                                                                        try{
                                                                            added = data.plans["plan_"+item.id];
                                                                        }catch (e) {
                                                                            added = [];
                                                                        }
                                                                        onDataChange({...data,plans:{
                                                                                ...data.plans,
                                                                                ["plan_"+item.id] : {
                                                                                    ...added,
                                                                                    sale_price:e.target.value
                                                                                }
                                                                            }});
                                                                    }} label={i18next.t("No discount price")} value={data?.plans?.["plan_"+item.id]?.sale_price} type="number" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form__row">
                                                            <div className="form__col">
                                                                <div className="field form__field  mt-20">
                                                                    <div className="field__label mb-30">{i18next.t("Image")}</div>
                                                                    <Image onChange={(e)=>{
                                                                        let added;
                                                                        try{
                                                                            added = data.plans["plan_"+item.id];
                                                                        }catch (e) {
                                                                            added = [];
                                                                        }

                                                                        const reader = new FileReader();
                                                                        reader.onload = (event) => {
                                                                            onDataChange({...data,plans:{
                                                                                    ...data.plans,
                                                                                    ["plan_"+item.id] : {
                                                                                        ...added,
                                                                                        image_url:event.target.result
                                                                                    }
                                                                                }});
                                                                        };
                                                                        reader.readAsDataURL(e.target.files[0])


                                                                    }} title={i18next.t("Select Image")} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form__row flex_100" key={item.id}>
                                                            <div className="form__col flex_100">
                                                                <div className="field form__field  mt-20">
                                                                    <div className="field__label mb-30">{i18next.t("Description")}</div>
                                                                    <CKEditor
                                                                        config={{
                                                                            allowedContent: true,
                                                                            extraAllowedContent:'i(.la,.fa)'

                                                                        }}
                                                                        data={html}
                                                                        onChange={( event )=>{
                                                                            let added;
                                                                            try{
                                                                                added = data.plans["plan_"+item.id];
                                                                            }catch (e) {
                                                                                added = [];
                                                                            }
                                                                            onDataChange({...data,plans:{
                                                                                    ...data.plans,
                                                                                    ["plan_"+item.id] : {
                                                                                        ...added,
                                                                                        description:event.editor.getData()
                                                                                    }
                                                                                }});
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                    </> : null
                            }


                        </div>



                    </div>

                </div>
            </div>

            <h2 className={"panel-title"}>{i18next.t("Terms & Conditions")}</h2>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings___inner">
                            <div className="panel__group btn-group btn-group_tabs mb-30">
                            {
                                [1,2,3].map((item,index) => {
                                    const number = index + 1;
                                    return (
                                        <button key={"contract_tab"+number} className={"btn btn_light btn_icon js-panel-btn "+(activeContractTab===item || (activeContractTab===0 && index===0) ? 'active' : '')} onClick={()=>{
                                            setActiveContractTab(item);
                                        }}>{data?.contracts?.[index]?.title || "#"+number}</button>
                                    )
                                })
                            }
                            </div>

                            {
                                value.is_active=="1" ?
                                    <>
                                        {
                                            [1,2,3].map((item,index) => {
                                                const html = data?.contracts?.[index]?.description || "";
                                                const number = index + 1;
                                                return (
                                                    <div key={"contract_detail_"+index} className={activeContractTab===item || (activeContractTab===0 && index===0) ? '' : 'hidden'}>
                                                        <div className="form__row">
                                                            <div className="form__col">
                                                                <div className="field form__field mt-20">
                                                                    <Field onChange={(e)=>{
                                                                        let added;
                                                                        try{
                                                                            added = data.contracts[index];
                                                                        }catch (e) {
                                                                            added = [];
                                                                        }
                                                                        onDataChange({...data,contracts:{
                                                                                ...data.contracts,
                                                                                [index] : {
                                                                                    ...added,
                                                                                    title:e.target.value
                                                                                }
                                                                            }});
                                                                    }} label={i18next.t("Title")} value={data?.contracts?.[index]?.title} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form__row flex_100">
                                                            <div className="form__col flex_100">
                                                                <div className="field form__field  mt-20">
                                                                    <div className="field__label mb-30">{i18next.t("Description")}</div>
                                                                    <CKEditor
                                                                        data={html}
                                                                        config={{
                                                                            allowedContent: true
                                                                        }}
                                                                        onChange={( event )=>{

                                                                            let added;
                                                                            try{
                                                                                added = data.contracts[index];
                                                                            }catch (e) {
                                                                                added = [];
                                                                            }
                                                                            onDataChange({...data,contracts:{
                                                                                    ...data.contracts,
                                                                                    [index] : {
                                                                                        ...added,
                                                                                        description:event.editor.getData()
                                                                                    }
                                                                                }});
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </> : null}

                        </div>



                    </div>

                </div>
            </div>

            <div className="settings__foot">
                <div className="settings__btns">
                    <button className="settings__btn btn" onClick={()=>{
                        onSubmit();
                    }}>{i18next.t("Update")}</button>
                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(CheckoutSettings));
