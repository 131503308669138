import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import i18next from "i18next";

export const ConfirmButton = ({ onClick, title,children,message ,hideSubmit,styles=null}) => {
    const [showConfirm, setShowConfirm] = useState(false);

    return (
        <>
            <button
                className={styles!=null ? styles : "btn btn_light btn_icon "}
                onClick={e => {
                    e.stopPropagation();
                    setShowConfirm(true);
                }}
            >
                {children}
            </button>
            {showConfirm && (
                <SweetAlert
                    custom
                    showCancel
                    cancelBtnText={i18next.t("Cancel")}
                    confirmBtnText={i18next.t("Yes")}
                    cancelBtnBsStyle="clean"
                    title={title}
                    customButtons={
                       hideSubmit ?  <React.Fragment>
                           <button onClick={()=>{
                               setShowConfirm(false);
                           }} className={"btn btn-primary"}>{i18next.t("Close")}</button>

                       </React.Fragment> : false
                    }
                    onConfirm={() => {

                        onClick();

                        setShowConfirm(false);
                    }}
                    onCancel={() => setShowConfirm(false)}
                    focusCancelBtn
                    closeOnClickOutside={false}
                    openAnim={{ name: 'showSweetAlert', duration: 300 }}
                    closeAnim={{ name: 'hideSweetAlert', duration: 0 }}
                    customIcon={'/assets/img/confirm.jpg'}
                >
                    <div className={"sweetalert_message_container"}>
                        {message}
                    </div>
                </SweetAlert>
            )}
        </>
    );
};
