import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import SidebarChild from "./sidebarChild";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import * as R from "ramda";

import {useAccounts} from "../../hooks/useAccounts";
import Field from "../../components/Input/field";
import Back from "../layouts/back";
import Select from "../../components/Select";
import * as moment from "moment";
import {useCreditCard} from "../../hooks/useCreditCard";
import {type} from "ramda";
import i18next from "i18next";






const PaymentMethods = ({history,match,form}) => {


    const { data , onChange , update, onSubmit ,validation,onDelete,setItem,setDefault} = useCreditCard();



    useEffect(()=>{
        if(match.params.id!=="new"){
            setItem(match.params.id);
        }
    },[match.params.id]);



    let months = [
        {"key":"01","value":"01"},
        {"key":"02","value":"02"},
        {"key":"03","value":"03"},
        {"key":"04","value":"04"},
        {"key":"05","value":"05"},
        {"key":"06","value":"06"},
        {"key":"07","value":"07"},
        {"key":"08","value":"08"},
        {"key":"09","value":"09"},
        {"key":"10","value":"10"},
        {"key":"11","value":"11"},
        {"key":"12","value":"12"},
    ];

    let years = [];
    for(let x = 0; x<= 10; x++){
        let year = moment().add(x,'year').format('YY');
        years.push({"key":year,"value":year})
    }

    const SideBar = () => {
        return (<SidebarChild key={"default"} history={history} defaultMenu={match.params.id} data={data}/>);
    };

    return (
        <Layout sidebar={SideBar()} title={i18next.t(form==="new" ? "Add New Credit Card" : "**** **** **** "+update?.last_digit)}>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">
                            {match.params.id ?
                                <>
                                    <div style={{width: "100%", display: "inline-block"}}>
                                        <div className="tag red c-p" style={{float:"right"}}  onClick={()=>{
                                            onDelete(match.params.id);
                                        }}>{i18next.t("Delete Card")}</div>
                                    </div>
                                </>
                                : null}
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field readonly={update?.readonly} onChange={(e)=>{
                                            onChange({...data,holdername:e.target.value});
                                        }} errors={validation?.holdername}  value={update?.holdername} label={i18next.t("Card Name")} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field readonly={update?.readonly} onChange={(e)=>{
                                            onChange({...data,number:e.target.value});
                                        }} errors={validation?.number}  value={update?.number} label={i18next.t("Card Number")} />
                                    </div>
                                </div>
                            </div>
                            {!update?.readonly ?
                                <>
                                    <div className="form__row">
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Select onChange={(e)=>{
                                                    onChange({...data,exp_month:e.target.value});
                                                }} errors={validation?.exp_month} selected={update?.exp_month} label={i18next.t("Exp. Month")} options={months} />
                                            </div>
                                        </div>
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Select onChange={(e)=>{
                                                    onChange({...data,exp_year:e.target.value});
                                                }} errors={validation?.exp_year} selected={update?.exp_year} label={i18next.t("Exp. Year")} options={years} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Field onChange={(e)=>{
                                                    onChange({...data,cvv:e.target.value});
                                                }} errors={validation?.cvv} value={update?.cvv} label={i18next.t("Cvv")} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : <>
                                    <label className="switch switch_toggle">
                                        <input className="switch__input" type="checkbox" checked={update?.is_default} onClick={()=>{
                                            setDefault(update.id)
                                        }} /><span className="switch__content">{i18next.t("Set Default Card")}</span></label>
                                </>}


                            {!update?.readonly ?
                                <button className="settings__btn btn" onClick={()=>{
                                    onSubmit()
                                }}>{i18next.t("Save")}</button> : null
                            }


                        </div>

                    </div>

                </div>
            </div>
        </Layout>
    );
}


export default withRouter (AppHOC(PaymentMethods));
