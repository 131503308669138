
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {getSettings, putSettings} from "../api/setting";
import {error, success} from "../utils/func";

export const usePaymentMethodEdit = () => {
    const [provider, setProvider] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [privateKey, setPrivateKey] = useState(null);
    const [submit, setSubmit] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const setData = async () => {
            if (submit) {
                try{
                    await putSettings('payment_'+provider,{
                        api_key: apiKey,
                        private_key: privateKey
                    });

                    success("");
                    history.goBack();
                } catch (e) {
                    setSubmit(false);

                    error(e.message);

                }
            }
        };
        const getData = async () => {
            const res = await getSettings('payment_'+provider);
            setApiKey(res.api_key)
            setPrivateKey(res.private_key)
        };

        setData();
        if(provider!=null) {
            getData();
        }
        return () => {

        };
    }, [submit,provider]);

    return {
        apiKey,
        privateKey,
        onProviderChange: (e) => {
            setProvider(e);
        },
        onApiKeyChange: (e) => {
            setApiKey(e.target.value);
        },
        onPrivateKeyChange: (e) => {
            setPrivateKey(e.target.value);
        },
        onSubmit: () => {
            setSubmit(true);
        }
    }
};
