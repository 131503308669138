import {del, get, post, put} from "../utils/http";

export const getIntegrations = async (type) => {
    try {
        return await get('/utils/integrations/'+type);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getUtil = async (data) => {
    try {
        return await get('/utils/'+data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const postUtil = async (url,data) => {
    try {
        return await post('/utils/'+url,data);
    }catch (e) {
        throw e.response.data.error;
    }
}
