import React, {useEffect, useState} from "react";
import i18next from "i18next";
import {useAccounts} from "../../hooks/useAccounts";
import {ConfirmButton} from "../../components/Button/confirm";

const ActionMenu = ({activeMenu,actions,summary,dataLength}) => {


    if(dataLength === 0){
        return (
            <>
            </>
        );
    }


    if(activeMenu==="invited_failed" || activeMenu==="draft"){

        return (
            <>
                <ConfirmButton hideSubmit={(summary[activeMenu.toLowerCase()] - summary.invitation_queue) < 0} onClick={actions.inviteAll.bind(this,activeMenu)} title={i18next.t("Are you sure?")} message={
                    <>
                        <table className="sweet_table" >
                            <tbody>
                            <tr>
                                <td>{i18next.t('Accounts Available')}</td>
                                <td>{(summary[activeMenu.toLowerCase()] - summary.invitation_queue) < 0 ? 0 : summary[activeMenu.toLowerCase()] - summary.invitation_queue}</td>
                                <td>{i18next.t('Send Email')}</td>
                            </tr>
                            <tr>
                                <td>{i18next.t('Already Sent')}</td>
                                <td>{summary.invitation_queue}</td>
                                <td>{i18next.t('Skip Email')}</td>
                            </tr>

                            </tbody>
                        </table>
                    </>
                }>
                    <i className="la la-bell "></i> {i18next.t("Invite All")}
                </ConfirmButton>

            </>
        );

    }else if(activeMenu==="active"){

        return (
            <>
                <ConfirmButton hideSubmit={(summary.not_found_credit_card - summary.credit_card_queue) <= 0} onClick={actions.paymentMethodNotify.bind(this,activeMenu)} title={i18next.t("Are you sure?")} message={
                    <>
                        <table className="sweet_table" >
                            <tbody>
                            <tr>
                                <td>{i18next.t('Accounts Available')}</td>
                                <td>{summary.not_found_credit_card - summary.credit_card_queue}</td>
                                <td>{i18next.t('Send Email')}</td>
                            </tr>
                            <tr>
                                <td>{i18next.t('Already Sent')}</td>
                                <td>{summary.credit_card_queue}</td>
                                <td>{i18next.t('Skip Email')}</td>
                            </tr>

                            </tbody>
                        </table>
                    </>
                }>
                    <i className="la la-bell "></i> {i18next.t("Ask For Credit Card")}
                </ConfirmButton>

            </>
        );

    }








    return (
        <>
        </>
        );
}

export default ActionMenu;
