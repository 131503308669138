import {get, put} from "../utils/http";

export const profileEdit = async (data) => {
    try {
        return await put('/user/profile',data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const changePassword = async (data) => {
    try {
        return await put('/user/change-password',data);
    }catch (e) {
        throw e.response.data.error;
    }
}
