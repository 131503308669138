import axios from 'axios'
import config from '../config';
import * as R from 'ramda';
import {checkUrl, cleanNull, headersAction} from "./func";
import storage from "./storage";
const daxios = axios.create({
    baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' && !config.live ? config.base_url.replace("https","http").replace('api.','api-test.') : config.base_url,
    responseType: 'json',
    transformRequest: [
        function(data, headers) {
            if (R.is(Object, data)) {
                return JSON.stringify(cleanNull(data));
            }
            return data;
        }
    ],
    transformResponse: [
        function(data){
            if(R.prop('status', data)!==undefined) {
                if (data.status === false) {
                    if (data.error.group === "token"
                        && !checkUrl(window.location.href, '/auth', true, false)
                        && !checkUrl(window.location.href, '/checkout', true, false)
                        && !checkUrl(window.location.href, '/public', true, false)
                    ) {
                        window.location = config.route.login;
                    }
                }
            }

            return data;
        }
    ],
    headers: {
        "Content-Type": "application/json",
        "Language" : storage.get("language",config.locale),
        "Tenant-Domain": window.location.host
    }
});


export async function get(url,filters={}) {



    if("page" in filters){
        url += '?'+(new URLSearchParams({page: filters.page}).toString())+'&'+(new URLSearchParams(R.reject(R.equals(null))(filters.filters)).toString())
    }else if (Object.values(filters).length>0){
        url += '?'+(new URLSearchParams(R.reject(R.equals(null))(filters)).toString())
    }

    return daxios
        .get(url, {
            headers: headersAction()
        })
        .then((res)=>{
            if(R.prop('meta', res.data)!==undefined){
                return Promise.resolve(res.data);
            }else{
                return Promise.resolve(res.data.data);
            }
        })
        .catch((error)=>{
            return Promise.reject(error);
        })
}

export async function post(url,data) {
    return daxios
        .post(url, JSON.stringify(data), {
            headers: headersAction()
        })
        .then((res)=>{
            if(res.status === 204){
                return Promise.resolve(res.data);
            }else{
                return Promise.resolve(res.data.data);
            }
        })
        .catch((error)=>{
            return Promise.reject(error);
        })
}

export async function put(url,data) {
    return daxios
        .put(url, JSON.stringify(data), {
            headers: headersAction()
        })
        .then((res)=>{
            if(res.status === 204){
                return Promise.resolve(res.data);
            }else{
                return Promise.resolve(res.data.data);
            }
        })
        .catch((error)=>{
            return Promise.reject(error);
        })
}

export async function del(url,data={}) {
    return daxios
        .delete(url, {
            headers: headersAction()
        })
        .then((res)=>{
            console.log(res);
            if(res.status === 204){
                return Promise.resolve(res.data);
            }else{
                return Promise.resolve(res.data.data);
            }
        })
        .catch((error)=>{
            return Promise.reject(error);
        })
}
