
export default function timezones(){
    return [
    {"key":"Pacific\/Midway","value":"(UTC -11:00) Pacific\/Midway"},
    {"key":"Pacific\/Niue","value":"(UTC -11:00) Pacific\/Niue"},
    {"key":"Pacific\/Pago Pago","value":"(UTC -11:00) Pacific\/Pago Pago"},
    {"key":"Pacific\/Honolulu","value":"(UTC -10:00) Pacific\/Honolulu"},
    {"key":"Pacific\/Rarotonga","value":"(UTC -10:00) Pacific\/Rarotonga"},
    {"key":"Pacific\/Tahiti","value":"(UTC -10:00) Pacific\/Tahiti"},
    {"key":"Pacific\/Marquesas","value":"(UTC -09:30) Pacific\/Marquesas"},
    {"key":"America\/Adak","value":"(UTC -09:00) America\/Adak"},
    {"key":"Pacific\/Gambier","value":"(UTC -09:00) Pacific\/Gambier"},
    {"key":"America\/Anchorage","value":"(UTC -08:00) America\/Anchorage"},
    {"key":"America\/Juneau","value":"(UTC -08:00) America\/Juneau"},
    {"key":"America\/Metlakatla","value":"(UTC -08:00) America\/Metlakatla"},
    {"key":"America\/Nome","value":"(UTC -08:00) America\/Nome"},
    {"key":"America\/Sitka","value":"(UTC -08:00) America\/Sitka"},
    {"key":"America\/Yakutat","value":"(UTC -08:00) America\/Yakutat"},
    {"key":"Pacific\/Pitcairn","value":"(UTC -08:00) Pacific\/Pitcairn"},
    {"key":"America\/Creston","value":"(UTC -07:00) America\/Creston"},
    {"key":"America\/Dawson","value":"(UTC -07:00) America\/Dawson"},
    {"key":"America\/Dawson Creek","value":"(UTC -07:00) America\/Dawson Creek"},
    {"key":"America\/Fort Nelson","value":"(UTC -07:00) America\/Fort Nelson"},
    {"key":"America\/Hermosillo","value":"(UTC -07:00) America\/Hermosillo"},
    {"key":"America\/Los Angeles","value":"(UTC -07:00) America\/Los Angeles"},
    {"key":"America\/Phoenix","value":"(UTC -07:00) America\/Phoenix"},
    {"key":"America\/Tijuana","value":"(UTC -07:00) America\/Tijuana"},
    {"key":"America\/Vancouver","value":"(UTC -07:00) America\/Vancouver"},
    {"key":"America\/Whitehorse","value":"(UTC -07:00) America\/Whitehorse"},
    {"key":"America\/Belize","value":"(UTC -06:00) America\/Belize"},
    {"key":"America\/Boise","value":"(UTC -06:00) America\/Boise"},
    {"key":"America\/Cambridge Bay","value":"(UTC -06:00) America\/Cambridge Bay"},
    {"key":"America\/Chihuahua","value":"(UTC -06:00) America\/Chihuahua"},
    {"key":"America\/Costa Rica","value":"(UTC -06:00) America\/Costa Rica"},
    {"key":"America\/Denver","value":"(UTC -06:00) America\/Denver"},
    {"key":"America\/Edmonton","value":"(UTC -06:00) America\/Edmonton"},
    {"key":"America\/El Salvador","value":"(UTC -06:00) America\/El Salvador"},
    {"key":"America\/Guatemala","value":"(UTC -06:00) America\/Guatemala"},
    {"key":"America\/Inuvik","value":"(UTC -06:00) America\/Inuvik"},
    {"key":"America\/Managua","value":"(UTC -06:00) America\/Managua"},
    {"key":"America\/Mazatlan","value":"(UTC -06:00) America\/Mazatlan"},
    {"key":"America\/Ojinaga","value":"(UTC -06:00) America\/Ojinaga"},
    {"key":"America\/Regina","value":"(UTC -06:00) America\/Regina"},
    {"key":"America\/Swift Current","value":"(UTC -06:00) America\/Swift Current"},
    {"key":"America\/Tegucigalpa","value":"(UTC -06:00) America\/Tegucigalpa"},
    {"key":"America\/Yellowknife","value":"(UTC -06:00) America\/Yellowknife"},
    {"key":"Pacific\/Easter","value":"(UTC -06:00) Pacific\/Easter"},
    {"key":"Pacific\/Galapagos","value":"(UTC -06:00) Pacific\/Galapagos"},
    {"key":"America\/Atikokan","value":"(UTC -05:00) America\/Atikokan"},
    {"key":"America\/Bahia Banderas","value":"(UTC -05:00) America\/Bahia Banderas"},
    {"key":"America\/Bogota","value":"(UTC -05:00) America\/Bogota"},
    {"key":"America\/Cancun","value":"(UTC -05:00) America\/Cancun"},
    {"key":"America\/Cayman","value":"(UTC -05:00) America\/Cayman"},
    {"key":"America\/Chicago","value":"(UTC -05:00) America\/Chicago"},
    {"key":"America\/Eirunepe","value":"(UTC -05:00) America\/Eirunepe"},
    {"key":"America\/Guayaquil","value":"(UTC -05:00) America\/Guayaquil"},
    {"key":"America\/Indiana\/Knox","value":"(UTC -05:00) America\/Indiana\/Knox"},
    {"key":"America\/Indiana\/Tell City","value":"(UTC -05:00) America\/Indiana\/Tell City"},
    {"key":"America\/Jamaica","value":"(UTC -05:00) America\/Jamaica"},
    {"key":"America\/Lima","value":"(UTC -05:00) America\/Lima"},
    {"key":"America\/Matamoros","value":"(UTC -05:00) America\/Matamoros"},
    {"key":"America\/Menominee","value":"(UTC -05:00) America\/Menominee"},
    {"key":"America\/Merida","value":"(UTC -05:00) America\/Merida"},
    {"key":"America\/Mexico City","value":"(UTC -05:00) America\/Mexico City"},
    {"key":"America\/Monterrey","value":"(UTC -05:00) America\/Monterrey"},
    {"key":"America\/North Dakota\/Beulah","value":"(UTC -05:00) America\/North Dakota\/Beulah"},
    {"key":"America\/North Dakota\/Center","value":"(UTC -05:00) America\/North Dakota\/Center"},
    {"key":"America\/North Dakota\/New Salem","value":"(UTC -05:00) America\/North Dakota\/New Salem"},
    {"key":"America\/Panama","value":"(UTC -05:00) America\/Panama"},
    {"key":"America\/Rainy River","value":"(UTC -05:00) America\/Rainy River"},
    {"key":"America\/Rankin Inlet","value":"(UTC -05:00) America\/Rankin Inlet"},
    {"key":"America\/Resolute","value":"(UTC -05:00) America\/Resolute"},
    {"key":"America\/Rio Branco","value":"(UTC -05:00) America\/Rio Branco"},
    {"key":"America\/Winnipeg","value":"(UTC -05:00) America\/Winnipeg"},
    {"key":"America\/Anguilla","value":"(UTC -04:00) America\/Anguilla"},
    {"key":"America\/Antigua","value":"(UTC -04:00) America\/Antigua"},
    {"key":"America\/Aruba","value":"(UTC -04:00) America\/Aruba"},
    {"key":"America\/Asuncion","value":"(UTC -04:00) America\/Asuncion"},
    {"key":"America\/Barbados","value":"(UTC -04:00) America\/Barbados"},
    {"key":"America\/Blanc-Sablon","value":"(UTC -04:00) America\/Blanc-Sablon"},
    {"key":"America\/Boa Vista","value":"(UTC -04:00) America\/Boa Vista"},
    {"key":"America\/Campo Grande","value":"(UTC -04:00) America\/Campo Grande"},
    {"key":"America\/Caracas","value":"(UTC -04:00) America\/Caracas"},
    {"key":"America\/Cuiaba","value":"(UTC -04:00) America\/Cuiaba"},
    {"key":"America\/Curacao","value":"(UTC -04:00) America\/Curacao"},
    {"key":"America\/Detroit","value":"(UTC -04:00) America\/Detroit"},
    {"key":"America\/Dominica","value":"(UTC -04:00) America\/Dominica"},
    {"key":"America\/Grand Turk","value":"(UTC -04:00) America\/Grand Turk"},
    {"key":"America\/Grenada","value":"(UTC -04:00) America\/Grenada"},
    {"key":"America\/Guadeloupe","value":"(UTC -04:00) America\/Guadeloupe"},
    {"key":"America\/Guyana","value":"(UTC -04:00) America\/Guyana"},
    {"key":"America\/Havana","value":"(UTC -04:00) America\/Havana"},
    {"key":"America\/Indiana\/Indianapolis","value":"(UTC -04:00) America\/Indiana\/Indianapolis"},
    {"key":"America\/Indiana\/Marengo","value":"(UTC -04:00) America\/Indiana\/Marengo"},
    {"key":"America\/Indiana\/Petersburg","value":"(UTC -04:00) America\/Indiana\/Petersburg"},
    {"key":"America\/Indiana\/Vevay","value":"(UTC -04:00) America\/Indiana\/Vevay"},
    {"key":"America\/Indiana\/Vincennes","value":"(UTC -04:00) America\/Indiana\/Vincennes"},
    {"key":"America\/Indiana\/Winamac","value":"(UTC -04:00) America\/Indiana\/Winamac"},
    {"key":"America\/Iqaluit","value":"(UTC -04:00) America\/Iqaluit"},
    {"key":"America\/Kentucky\/Louisville","value":"(UTC -04:00) America\/Kentucky\/Louisville"},
    {"key":"America\/Kentucky\/Monticello","value":"(UTC -04:00) America\/Kentucky\/Monticello"},
    {"key":"America\/Kralendijk","value":"(UTC -04:00) America\/Kralendijk"},
    {"key":"America\/La Paz","value":"(UTC -04:00) America\/La Paz"},
    {"key":"America\/Lower Princes","value":"(UTC -04:00) America\/Lower Princes"},
    {"key":"America\/Manaus","value":"(UTC -04:00) America\/Manaus"},
    {"key":"America\/Marigot","value":"(UTC -04:00) America\/Marigot"},
    {"key":"America\/Martinique","value":"(UTC -04:00) America\/Martinique"},
    {"key":"America\/Montserrat","value":"(UTC -04:00) America\/Montserrat"},
    {"key":"America\/Nassau","value":"(UTC -04:00) America\/Nassau"},
    {"key":"America\/New York","value":"(UTC -04:00) America\/New York"},
    {"key":"America\/Nipigon","value":"(UTC -04:00) America\/Nipigon"},
    {"key":"America\/Pangnirtung","value":"(UTC -04:00) America\/Pangnirtung"},
    {"key":"America\/Port of Spain","value":"(UTC -04:00) America\/Port of Spain"},
    {"key":"America\/Port-au-Prince","value":"(UTC -04:00) America\/Port-au-Prince"},
    {"key":"America\/Porto Velho","value":"(UTC -04:00) America\/Porto Velho"},
    {"key":"America\/Puerto Rico","value":"(UTC -04:00) America\/Puerto Rico"},
    {"key":"America\/Santiago","value":"(UTC -04:00) America\/Santiago"},
    {"key":"America\/Santo Domingo","value":"(UTC -04:00) America\/Santo Domingo"},
    {"key":"America\/St Barthelemy","value":"(UTC -04:00) America\/St Barthelemy"},
    {"key":"America\/St Kitts","value":"(UTC -04:00) America\/St Kitts"},
    {"key":"America\/St Lucia","value":"(UTC -04:00) America\/St Lucia"},
    {"key":"America\/St Thomas","value":"(UTC -04:00) America\/St Thomas"},
    {"key":"America\/St Vincent","value":"(UTC -04:00) America\/St Vincent"},
    {"key":"America\/Thunder Bay","value":"(UTC -04:00) America\/Thunder Bay"},
    {"key":"America\/Toronto","value":"(UTC -04:00) America\/Toronto"},
    {"key":"America\/Tortola","value":"(UTC -04:00) America\/Tortola"},
    {"key":"America\/Araguaina","value":"(UTC -03:00) America\/Araguaina"},
    {"key":"America\/Argentina\/Buenos Aires","value":"(UTC -03:00) America\/Argentina\/Buenos Aires"},
    {"key":"America\/Argentina\/Catamarca","value":"(UTC -03:00) America\/Argentina\/Catamarca"},
    {"key":"America\/Argentina\/Cordoba","value":"(UTC -03:00) America\/Argentina\/Cordoba"},
    {"key":"America\/Argentina\/Jujuy","value":"(UTC -03:00) America\/Argentina\/Jujuy"},
    {"key":"America\/Argentina\/La Rioja","value":"(UTC -03:00) America\/Argentina\/La Rioja"},
    {"key":"America\/Argentina\/Mendoza","value":"(UTC -03:00) America\/Argentina\/Mendoza"},
    {"key":"America\/Argentina\/Rio Gallegos","value":"(UTC -03:00) America\/Argentina\/Rio Gallegos"},
    {"key":"America\/Argentina\/Salta","value":"(UTC -03:00) America\/Argentina\/Salta"},
    {"key":"America\/Argentina\/San Juan","value":"(UTC -03:00) America\/Argentina\/San Juan"},
    {"key":"America\/Argentina\/San Luis","value":"(UTC -03:00) America\/Argentina\/San Luis"},
    {"key":"America\/Argentina\/Tucuman","value":"(UTC -03:00) America\/Argentina\/Tucuman"},
    {"key":"America\/Argentina\/Ushuaia","value":"(UTC -03:00) America\/Argentina\/Ushuaia"},
    {"key":"America\/Bahia","value":"(UTC -03:00) America\/Bahia"},
    {"key":"America\/Belem","value":"(UTC -03:00) America\/Belem"},
    {"key":"America\/Cayenne","value":"(UTC -03:00) America\/Cayenne"},
    {"key":"America\/Fortaleza","value":"(UTC -03:00) America\/Fortaleza"},
    {"key":"America\/Glace Bay","value":"(UTC -03:00) America\/Glace Bay"},
    {"key":"America\/Goose Bay","value":"(UTC -03:00) America\/Goose Bay"},
    {"key":"America\/Halifax","value":"(UTC -03:00) America\/Halifax"},
    {"key":"America\/Maceio","value":"(UTC -03:00) America\/Maceio"},
    {"key":"America\/Moncton","value":"(UTC -03:00) America\/Moncton"},
    {"key":"America\/Montevideo","value":"(UTC -03:00) America\/Montevideo"},
    {"key":"America\/Paramaribo","value":"(UTC -03:00) America\/Paramaribo"},
    {"key":"America\/Punta Arenas","value":"(UTC -03:00) America\/Punta Arenas"},
    {"key":"America\/Recife","value":"(UTC -03:00) America\/Recife"},
    {"key":"America\/Santarem","value":"(UTC -03:00) America\/Santarem"},
    {"key":"America\/Sao Paulo","value":"(UTC -03:00) America\/Sao Paulo"},
    {"key":"America\/Thule","value":"(UTC -03:00) America\/Thule"},
    {"key":"Antarctica\/Palmer","value":"(UTC -03:00) Antarctica\/Palmer"},
    {"key":"Antarctica\/Rothera","value":"(UTC -03:00) Antarctica\/Rothera"},
    {"key":"Atlantic\/Bermuda","value":"(UTC -03:00) Atlantic\/Bermuda"},
    {"key":"Atlantic\/Stanley","value":"(UTC -03:00) Atlantic\/Stanley"},
    {"key":"America\/St Johns","value":"(UTC -02:30) America\/St Johns"},
    {"key":"America\/Miquelon","value":"(UTC -02:00) America\/Miquelon"},
    {"key":"America\/Noronha","value":"(UTC -02:00) America\/Noronha"},
    {"key":"America\/Nuuk","value":"(UTC -02:00) America\/Nuuk"},
    {"key":"Atlantic\/South Georgia","value":"(UTC -02:00) Atlantic\/South Georgia"},
    {"key":"Atlantic\/Cape Verde","value":"(UTC -01:00) Atlantic\/Cape Verde"},
    {"key":"Africa\/Abidjan","value":"(UTC +00:00) Africa\/Abidjan"},
    {"key":"Africa\/Accra","value":"(UTC +00:00) Africa\/Accra"},
    {"key":"Africa\/Bamako","value":"(UTC +00:00) Africa\/Bamako"},
    {"key":"Africa\/Banjul","value":"(UTC +00:00) Africa\/Banjul"},
    {"key":"Africa\/Bissau","value":"(UTC +00:00) Africa\/Bissau"},
    {"key":"Africa\/Conakry","value":"(UTC +00:00) Africa\/Conakry"},
    {"key":"Africa\/Dakar","value":"(UTC +00:00) Africa\/Dakar"},
    {"key":"Africa\/Freetown","value":"(UTC +00:00) Africa\/Freetown"},
    {"key":"Africa\/Lome","value":"(UTC +00:00) Africa\/Lome"},
    {"key":"Africa\/Monrovia","value":"(UTC +00:00) Africa\/Monrovia"},
    {"key":"Africa\/Nouakchott","value":"(UTC +00:00) Africa\/Nouakchott"},
    {"key":"Africa\/Ouagadougou","value":"(UTC +00:00) Africa\/Ouagadougou"},
    {"key":"Africa\/Sao Tome","value":"(UTC +00:00) Africa\/Sao Tome"},
    {"key":"America\/Danmarkshavn","value":"(UTC +00:00) America\/Danmarkshavn"},
    {"key":"America\/Scoresbysund","value":"(UTC +00:00) America\/Scoresbysund"},
    {"key":"Atlantic\/Azores","value":"(UTC +00:00) Atlantic\/Azores"},
    {"key":"Atlantic\/Reykjavik","value":"(UTC +00:00) Atlantic\/Reykjavik"},
    {"key":"Atlantic\/St Helena","value":"(UTC +00:00) Atlantic\/St Helena"},
    {"key":"UTC","value":"(UTC +00:00) UTC"},
    {"key":"Africa\/Algiers","value":"(UTC +01:00) Africa\/Algiers"},
    {"key":"Africa\/Bangui","value":"(UTC +01:00) Africa\/Bangui"},
    {"key":"Africa\/Brazzaville","value":"(UTC +01:00) Africa\/Brazzaville"},
    {"key":"Africa\/Casablanca","value":"(UTC +01:00) Africa\/Casablanca"},
    {"key":"Africa\/Douala","value":"(UTC +01:00) Africa\/Douala"},
    {"key":"Africa\/El Aaiun","value":"(UTC +01:00) Africa\/El Aaiun"},
    {"key":"Africa\/Kinshasa","value":"(UTC +01:00) Africa\/Kinshasa"},
    {"key":"Africa\/Lagos","value":"(UTC +01:00) Africa\/Lagos"},
    {"key":"Africa\/Libreville","value":"(UTC +01:00) Africa\/Libreville"},
    {"key":"Africa\/Luanda","value":"(UTC +01:00) Africa\/Luanda"},
    {"key":"Africa\/Malabo","value":"(UTC +01:00) Africa\/Malabo"},
    {"key":"Africa\/Ndjamena","value":"(UTC +01:00) Africa\/Ndjamena"},
    {"key":"Africa\/Niamey","value":"(UTC +01:00) Africa\/Niamey"},
    {"key":"Africa\/Porto-Novo","value":"(UTC +01:00) Africa\/Porto-Novo"},
    {"key":"Africa\/Tunis","value":"(UTC +01:00) Africa\/Tunis"},
    {"key":"Atlantic\/Canary","value":"(UTC +01:00) Atlantic\/Canary"},
    {"key":"Atlantic\/Faroe","value":"(UTC +01:00) Atlantic\/Faroe"},
    {"key":"Atlantic\/Madeira","value":"(UTC +01:00) Atlantic\/Madeira"},
    {"key":"Europe\/Dublin","value":"(UTC +01:00) Europe\/Dublin"},
    {"key":"Europe\/Guernsey","value":"(UTC +01:00) Europe\/Guernsey"},
    {"key":"Europe\/Isle of Man","value":"(UTC +01:00) Europe\/Isle of Man"},
    {"key":"Europe\/Jersey","value":"(UTC +01:00) Europe\/Jersey"},
    {"key":"Europe\/Lisbon","value":"(UTC +01:00) Europe\/Lisbon"},
    {"key":"Europe\/London","value":"(UTC +01:00) Europe\/London"},
    {"key":"Africa\/Blantyre","value":"(UTC +02:00) Africa\/Blantyre"},
    {"key":"Africa\/Bujumbura","value":"(UTC +02:00) Africa\/Bujumbura"},
    {"key":"Africa\/Cairo","value":"(UTC +02:00) Africa\/Cairo"},
    {"key":"Africa\/Ceuta","value":"(UTC +02:00) Africa\/Ceuta"},
    {"key":"Africa\/Gaborone","value":"(UTC +02:00) Africa\/Gaborone"},
    {"key":"Africa\/Harare","value":"(UTC +02:00) Africa\/Harare"},
    {"key":"Africa\/Johannesburg","value":"(UTC +02:00) Africa\/Johannesburg"},
    {"key":"Africa\/Khartoum","value":"(UTC +02:00) Africa\/Khartoum"},
    {"key":"Africa\/Kigali","value":"(UTC +02:00) Africa\/Kigali"},
    {"key":"Africa\/Lubumbashi","value":"(UTC +02:00) Africa\/Lubumbashi"},
    {"key":"Africa\/Lusaka","value":"(UTC +02:00) Africa\/Lusaka"},
    {"key":"Africa\/Maputo","value":"(UTC +02:00) Africa\/Maputo"},
    {"key":"Africa\/Maseru","value":"(UTC +02:00) Africa\/Maseru"},
    {"key":"Africa\/Mbabane","value":"(UTC +02:00) Africa\/Mbabane"},
    {"key":"Africa\/Tripoli","value":"(UTC +02:00) Africa\/Tripoli"},
    {"key":"Africa\/Windhoek","value":"(UTC +02:00) Africa\/Windhoek"},
    {"key":"Antarctica\/Troll","value":"(UTC +02:00) Antarctica\/Troll"},
    {"key":"Arctic\/Longyearbyen","value":"(UTC +02:00) Arctic\/Longyearbyen"},
    {"key":"Europe\/Amsterdam","value":"(UTC +02:00) Europe\/Amsterdam"},
    {"key":"Europe\/Andorra","value":"(UTC +02:00) Europe\/Andorra"},
    {"key":"Europe\/Belgrade","value":"(UTC +02:00) Europe\/Belgrade"},
    {"key":"Europe\/Berlin","value":"(UTC +02:00) Europe\/Berlin"},
    {"key":"Europe\/Bratislava","value":"(UTC +02:00) Europe\/Bratislava"},
    {"key":"Europe\/Brussels","value":"(UTC +02:00) Europe\/Brussels"},
    {"key":"Europe\/Budapest","value":"(UTC +02:00) Europe\/Budapest"},
    {"key":"Europe\/Busingen","value":"(UTC +02:00) Europe\/Busingen"},
    {"key":"Europe\/Copenhagen","value":"(UTC +02:00) Europe\/Copenhagen"},
    {"key":"Europe\/Gibraltar","value":"(UTC +02:00) Europe\/Gibraltar"},
    {"key":"Europe\/Kaliningrad","value":"(UTC +02:00) Europe\/Kaliningrad"},
    {"key":"Europe\/Ljubljana","value":"(UTC +02:00) Europe\/Ljubljana"},
    {"key":"Europe\/Luxembourg","value":"(UTC +02:00) Europe\/Luxembourg"},
    {"key":"Europe\/Madrid","value":"(UTC +02:00) Europe\/Madrid"},
    {"key":"Europe\/Malta","value":"(UTC +02:00) Europe\/Malta"},
    {"key":"Europe\/Monaco","value":"(UTC +02:00) Europe\/Monaco"},
    {"key":"Europe\/Oslo","value":"(UTC +02:00) Europe\/Oslo"},
    {"key":"Europe\/Paris","value":"(UTC +02:00) Europe\/Paris"},
    {"key":"Europe\/Podgorica","value":"(UTC +02:00) Europe\/Podgorica"},
    {"key":"Europe\/Prague","value":"(UTC +02:00) Europe\/Prague"},
    {"key":"Europe\/Rome","value":"(UTC +02:00) Europe\/Rome"},
    {"key":"Europe\/San Marino","value":"(UTC +02:00) Europe\/San Marino"},
    {"key":"Europe\/Sarajevo","value":"(UTC +02:00) Europe\/Sarajevo"},
    {"key":"Europe\/Skopje","value":"(UTC +02:00) Europe\/Skopje"},
    {"key":"Europe\/Stockholm","value":"(UTC +02:00) Europe\/Stockholm"},
    {"key":"Europe\/Tirane","value":"(UTC +02:00) Europe\/Tirane"},
    {"key":"Europe\/Vaduz","value":"(UTC +02:00) Europe\/Vaduz"},
    {"key":"Europe\/Vatican","value":"(UTC +02:00) Europe\/Vatican"},
    {"key":"Europe\/Vienna","value":"(UTC +02:00) Europe\/Vienna"},
    {"key":"Europe\/Warsaw","value":"(UTC +02:00) Europe\/Warsaw"},
    {"key":"Europe\/Zagreb","value":"(UTC +02:00) Europe\/Zagreb"},
    {"key":"Europe\/Zurich","value":"(UTC +02:00) Europe\/Zurich"},
    {"key":"Africa\/Addis Ababa","value":"(UTC +03:00) Africa\/Addis Ababa"},
    {"key":"Africa\/Asmara","value":"(UTC +03:00) Africa\/Asmara"},
    {"key":"Africa\/Dar es Salaam","value":"(UTC +03:00) Africa\/Dar es Salaam"},
    {"key":"Africa\/Djibouti","value":"(UTC +03:00) Africa\/Djibouti"},
    {"key":"Africa\/Juba","value":"(UTC +03:00) Africa\/Juba"},
    {"key":"Africa\/Kampala","value":"(UTC +03:00) Africa\/Kampala"},
    {"key":"Africa\/Mogadishu","value":"(UTC +03:00) Africa\/Mogadishu"},
    {"key":"Africa\/Nairobi","value":"(UTC +03:00) Africa\/Nairobi"},
    {"key":"Antarctica\/Syowa","value":"(UTC +03:00) Antarctica\/Syowa"},
    {"key":"Asia\/Aden","value":"(UTC +03:00) Asia\/Aden"},
    {"key":"Asia\/Amman","value":"(UTC +03:00) Asia\/Amman"},
    {"key":"Asia\/Baghdad","value":"(UTC +03:00) Asia\/Baghdad"},
    {"key":"Asia\/Bahrain","value":"(UTC +03:00) Asia\/Bahrain"},
    {"key":"Asia\/Beirut","value":"(UTC +03:00) Asia\/Beirut"},
    {"key":"Asia\/Damascus","value":"(UTC +03:00) Asia\/Damascus"},
    {"key":"Asia\/Famagusta","value":"(UTC +03:00) Asia\/Famagusta"},
    {"key":"Asia\/Gaza","value":"(UTC +03:00) Asia\/Gaza"},
    {"key":"Asia\/Hebron","value":"(UTC +03:00) Asia\/Hebron"},
    {"key":"Asia\/Jerusalem","value":"(UTC +03:00) Asia\/Jerusalem"},
    {"key":"Asia\/Kuwait","value":"(UTC +03:00) Asia\/Kuwait"},
    {"key":"Asia\/Nicosia","value":"(UTC +03:00) Asia\/Nicosia"},
    {"key":"Asia\/Qatar","value":"(UTC +03:00) Asia\/Qatar"},
    {"key":"Asia\/Riyadh","value":"(UTC +03:00) Asia\/Riyadh"},
    {"key":"Europe\/Athens","value":"(UTC +03:00) Europe\/Athens"},
    {"key":"Europe\/Bucharest","value":"(UTC +03:00) Europe\/Bucharest"},
    {"key":"Europe\/Chisinau","value":"(UTC +03:00) Europe\/Chisinau"},
    {"key":"Europe\/Helsinki","value":"(UTC +03:00) Europe\/Helsinki"},
    {"key":"Europe\/Istanbul","value":"(UTC +03:00) Europe\/Istanbul"},
    {"key":"Europe\/Kiev","value":"(UTC +03:00) Europe\/Kiev"},
    {"key":"Europe\/Kirov","value":"(UTC +03:00) Europe\/Kirov"},
    {"key":"Europe\/Mariehamn","value":"(UTC +03:00) Europe\/Mariehamn"},
    {"key":"Europe\/Minsk","value":"(UTC +03:00) Europe\/Minsk"},
    {"key":"Europe\/Moscow","value":"(UTC +03:00) Europe\/Moscow"},
    {"key":"Europe\/Riga","value":"(UTC +03:00) Europe\/Riga"},
    {"key":"Europe\/Simferopol","value":"(UTC +03:00) Europe\/Simferopol"},
    {"key":"Europe\/Sofia","value":"(UTC +03:00) Europe\/Sofia"},
    {"key":"Europe\/Tallinn","value":"(UTC +03:00) Europe\/Tallinn"},
    {"key":"Europe\/Uzhgorod","value":"(UTC +03:00) Europe\/Uzhgorod"},
    {"key":"Europe\/Vilnius","value":"(UTC +03:00) Europe\/Vilnius"},
    {"key":"Europe\/Zaporozhye","value":"(UTC +03:00) Europe\/Zaporozhye"},
    {"key":"Indian\/Antananarivo","value":"(UTC +03:00) Indian\/Antananarivo"},
    {"key":"Indian\/Comoro","value":"(UTC +03:00) Indian\/Comoro"},
    {"key":"Indian\/Mayotte","value":"(UTC +03:00) Indian\/Mayotte"},
    {"key":"Asia\/Baku","value":"(UTC +04:00) Asia\/Baku"},
    {"key":"Asia\/Dubai","value":"(UTC +04:00) Asia\/Dubai"},
    {"key":"Asia\/Muscat","value":"(UTC +04:00) Asia\/Muscat"},
    {"key":"Asia\/Tbilisi","value":"(UTC +04:00) Asia\/Tbilisi"},
    {"key":"Asia\/Yerevan","value":"(UTC +04:00) Asia\/Yerevan"},
    {"key":"Europe\/Astrakhan","value":"(UTC +04:00) Europe\/Astrakhan"},
    {"key":"Europe\/Samara","value":"(UTC +04:00) Europe\/Samara"},
    {"key":"Europe\/Saratov","value":"(UTC +04:00) Europe\/Saratov"},
    {"key":"Europe\/Ulyanovsk","value":"(UTC +04:00) Europe\/Ulyanovsk"},
    {"key":"Europe\/Volgograd","value":"(UTC +04:00) Europe\/Volgograd"},
    {"key":"Indian\/Mahe","value":"(UTC +04:00) Indian\/Mahe"},
    {"key":"Indian\/Mauritius","value":"(UTC +04:00) Indian\/Mauritius"},
    {"key":"Indian\/Reunion","value":"(UTC +04:00) Indian\/Reunion"},
    {"key":"Asia\/Kabul","value":"(UTC +04:30) Asia\/Kabul"},
    {"key":"Asia\/Tehran","value":"(UTC +04:30) Asia\/Tehran"},
    {"key":"Antarctica\/Mawson","value":"(UTC +05:00) Antarctica\/Mawson"},
    {"key":"Asia\/Aqtau","value":"(UTC +05:00) Asia\/Aqtau"},
    {"key":"Asia\/Aqtobe","value":"(UTC +05:00) Asia\/Aqtobe"},
    {"key":"Asia\/Ashgabat","value":"(UTC +05:00) Asia\/Ashgabat"},
    {"key":"Asia\/Atyrau","value":"(UTC +05:00) Asia\/Atyrau"},
    {"key":"Asia\/Dushanbe","value":"(UTC +05:00) Asia\/Dushanbe"},
    {"key":"Asia\/Karachi","value":"(UTC +05:00) Asia\/Karachi"},
    {"key":"Asia\/Oral","value":"(UTC +05:00) Asia\/Oral"},
    {"key":"Asia\/Qyzylorda","value":"(UTC +05:00) Asia\/Qyzylorda"},
    {"key":"Asia\/Samarkand","value":"(UTC +05:00) Asia\/Samarkand"},
    {"key":"Asia\/Tashkent","value":"(UTC +05:00) Asia\/Tashkent"},
    {"key":"Asia\/Yekaterinburg","value":"(UTC +05:00) Asia\/Yekaterinburg"},
    {"key":"Indian\/Kerguelen","value":"(UTC +05:00) Indian\/Kerguelen"},
    {"key":"Indian\/Maldives","value":"(UTC +05:00) Indian\/Maldives"},
    {"key":"Asia\/Colombo","value":"(UTC +05:30) Asia\/Colombo"},
    {"key":"Asia\/Kolkata","value":"(UTC +05:30) Asia\/Kolkata"},
    {"key":"Asia\/Kathmandu","value":"(UTC +05:45) Asia\/Kathmandu"},
    {"key":"Antarctica\/Vostok","value":"(UTC +06:00) Antarctica\/Vostok"},
    {"key":"Asia\/Almaty","value":"(UTC +06:00) Asia\/Almaty"},
    {"key":"Asia\/Bishkek","value":"(UTC +06:00) Asia\/Bishkek"},
    {"key":"Asia\/Dhaka","value":"(UTC +06:00) Asia\/Dhaka"},
    {"key":"Asia\/Omsk","value":"(UTC +06:00) Asia\/Omsk"},
    {"key":"Asia\/Qostanay","value":"(UTC +06:00) Asia\/Qostanay"},
    {"key":"Asia\/Thimphu","value":"(UTC +06:00) Asia\/Thimphu"},
    {"key":"Asia\/Urumqi","value":"(UTC +06:00) Asia\/Urumqi"},
    {"key":"Indian\/Chagos","value":"(UTC +06:00) Indian\/Chagos"},
    {"key":"Asia\/Yangon","value":"(UTC +06:30) Asia\/Yangon"},
    {"key":"Indian\/Cocos","value":"(UTC +06:30) Indian\/Cocos"},
    {"key":"Antarctica\/Davis","value":"(UTC +07:00) Antarctica\/Davis"},
    {"key":"Asia\/Bangkok","value":"(UTC +07:00) Asia\/Bangkok"},
    {"key":"Asia\/Barnaul","value":"(UTC +07:00) Asia\/Barnaul"},
    {"key":"Asia\/Ho Chi Minh","value":"(UTC +07:00) Asia\/Ho Chi Minh"},
    {"key":"Asia\/Hovd","value":"(UTC +07:00) Asia\/Hovd"},
    {"key":"Asia\/Jakarta","value":"(UTC +07:00) Asia\/Jakarta"},
    {"key":"Asia\/Krasnoyarsk","value":"(UTC +07:00) Asia\/Krasnoyarsk"},
    {"key":"Asia\/Novokuznetsk","value":"(UTC +07:00) Asia\/Novokuznetsk"},
    {"key":"Asia\/Novosibirsk","value":"(UTC +07:00) Asia\/Novosibirsk"},
    {"key":"Asia\/Phnom Penh","value":"(UTC +07:00) Asia\/Phnom Penh"},
    {"key":"Asia\/Pontianak","value":"(UTC +07:00) Asia\/Pontianak"},
    {"key":"Asia\/Tomsk","value":"(UTC +07:00) Asia\/Tomsk"},
    {"key":"Asia\/Vientiane","value":"(UTC +07:00) Asia\/Vientiane"},
    {"key":"Indian\/Christmas","value":"(UTC +07:00) Indian\/Christmas"},
    {"key":"Antarctica\/Casey","value":"(UTC +08:00) Antarctica\/Casey"},
    {"key":"Asia\/Brunei","value":"(UTC +08:00) Asia\/Brunei"},
    {"key":"Asia\/Choibalsan","value":"(UTC +08:00) Asia\/Choibalsan"},
    {"key":"Asia\/Hong Kong","value":"(UTC +08:00) Asia\/Hong Kong"},
    {"key":"Asia\/Irkutsk","value":"(UTC +08:00) Asia\/Irkutsk"},
    {"key":"Asia\/Kuala Lumpur","value":"(UTC +08:00) Asia\/Kuala Lumpur"},
    {"key":"Asia\/Kuching","value":"(UTC +08:00) Asia\/Kuching"},
    {"key":"Asia\/Macau","value":"(UTC +08:00) Asia\/Macau"},
    {"key":"Asia\/Makassar","value":"(UTC +08:00) Asia\/Makassar"},
    {"key":"Asia\/Manila","value":"(UTC +08:00) Asia\/Manila"},
    {"key":"Asia\/Shanghai","value":"(UTC +08:00) Asia\/Shanghai"},
    {"key":"Asia\/Singapore","value":"(UTC +08:00) Asia\/Singapore"},
    {"key":"Asia\/Taipei","value":"(UTC +08:00) Asia\/Taipei"},
    {"key":"Asia\/Ulaanbaatar","value":"(UTC +08:00) Asia\/Ulaanbaatar"},
    {"key":"Australia\/Perth","value":"(UTC +08:00) Australia\/Perth"},
    {"key":"Australia\/Eucla","value":"(UTC +08:45) Australia\/Eucla"},
    {"key":"Asia\/Chita","value":"(UTC +09:00) Asia\/Chita"},
    {"key":"Asia\/Dili","value":"(UTC +09:00) Asia\/Dili"},
    {"key":"Asia\/Jayapura","value":"(UTC +09:00) Asia\/Jayapura"},
    {"key":"Asia\/Khandyga","value":"(UTC +09:00) Asia\/Khandyga"},
    {"key":"Asia\/Pyongyang","value":"(UTC +09:00) Asia\/Pyongyang"},
    {"key":"Asia\/Seoul","value":"(UTC +09:00) Asia\/Seoul"},
    {"key":"Asia\/Tokyo","value":"(UTC +09:00) Asia\/Tokyo"},
    {"key":"Asia\/Yakutsk","value":"(UTC +09:00) Asia\/Yakutsk"},
    {"key":"Pacific\/Palau","value":"(UTC +09:00) Pacific\/Palau"},
    {"key":"Australia\/Adelaide","value":"(UTC +09:30) Australia\/Adelaide"},
    {"key":"Australia\/Broken Hill","value":"(UTC +09:30) Australia\/Broken Hill"},
    {"key":"Australia\/Darwin","value":"(UTC +09:30) Australia\/Darwin"},
    {"key":"Antarctica\/DumontDUrville","value":"(UTC +10:00) Antarctica\/DumontDUrville"},
    {"key":"Asia\/Ust-Nera","value":"(UTC +10:00) Asia\/Ust-Nera"},
    {"key":"Asia\/Vladivostok","value":"(UTC +10:00) Asia\/Vladivostok"},
    {"key":"Australia\/Brisbane","value":"(UTC +10:00) Australia\/Brisbane"},
    {"key":"Australia\/Currie","value":"(UTC +10:00) Australia\/Currie"},
    {"key":"Australia\/Hobart","value":"(UTC +10:00) Australia\/Hobart"},
    {"key":"Australia\/Lindeman","value":"(UTC +10:00) Australia\/Lindeman"},
    {"key":"Australia\/Melbourne","value":"(UTC +10:00) Australia\/Melbourne"},
    {"key":"Australia\/Sydney","value":"(UTC +10:00) Australia\/Sydney"},
    {"key":"Pacific\/Chuuk","value":"(UTC +10:00) Pacific\/Chuuk"},
    {"key":"Pacific\/Guam","value":"(UTC +10:00) Pacific\/Guam"},
    {"key":"Pacific\/Port Moresby","value":"(UTC +10:00) Pacific\/Port Moresby"},
    {"key":"Pacific\/Saipan","value":"(UTC +10:00) Pacific\/Saipan"},
    {"key":"Australia\/Lord Howe","value":"(UTC +10:30) Australia\/Lord Howe"},
    {"key":"Antarctica\/Macquarie","value":"(UTC +11:00) Antarctica\/Macquarie"},
    {"key":"Asia\/Magadan","value":"(UTC +11:00) Asia\/Magadan"},
    {"key":"Asia\/Sakhalin","value":"(UTC +11:00) Asia\/Sakhalin"},
    {"key":"Asia\/Srednekolymsk","value":"(UTC +11:00) Asia\/Srednekolymsk"},
    {"key":"Pacific\/Bougainville","value":"(UTC +11:00) Pacific\/Bougainville"},
    {"key":"Pacific\/Efate","value":"(UTC +11:00) Pacific\/Efate"},
    {"key":"Pacific\/Guadalcanal","value":"(UTC +11:00) Pacific\/Guadalcanal"},
    {"key":"Pacific\/Kosrae","value":"(UTC +11:00) Pacific\/Kosrae"},
    {"key":"Pacific\/Norfolk","value":"(UTC +11:00) Pacific\/Norfolk"},
    {"key":"Pacific\/Noumea","value":"(UTC +11:00) Pacific\/Noumea"},
    {"key":"Pacific\/Pohnpei","value":"(UTC +11:00) Pacific\/Pohnpei"},
    {"key":"Antarctica\/McMurdo","value":"(UTC +12:00) Antarctica\/McMurdo"},
    {"key":"Asia\/Anadyr","value":"(UTC +12:00) Asia\/Anadyr"},
    {"key":"Asia\/Kamchatka","value":"(UTC +12:00) Asia\/Kamchatka"},
    {"key":"Pacific\/Auckland","value":"(UTC +12:00) Pacific\/Auckland"},
    {"key":"Pacific\/Fiji","value":"(UTC +12:00) Pacific\/Fiji"},
    {"key":"Pacific\/Funafuti","value":"(UTC +12:00) Pacific\/Funafuti"},
    {"key":"Pacific\/Kwajalein","value":"(UTC +12:00) Pacific\/Kwajalein"},
    {"key":"Pacific\/Majuro","value":"(UTC +12:00) Pacific\/Majuro"},
    {"key":"Pacific\/Nauru","value":"(UTC +12:00) Pacific\/Nauru"},
    {"key":"Pacific\/Tarawa","value":"(UTC +12:00) Pacific\/Tarawa"},
    {"key":"Pacific\/Wake","value":"(UTC +12:00) Pacific\/Wake"},
    {"key":"Pacific\/Wallis","value":"(UTC +12:00) Pacific\/Wallis"},
    {"key":"Pacific\/Chatham","value":"(UTC +12:45) Pacific\/Chatham"},
    {"key":"Pacific\/Apia","value":"(UTC +13:00) Pacific\/Apia"},
    {"key":"Pacific\/Enderbury","value":"(UTC +13:00) Pacific\/Enderbury"},
    {"key":"Pacific\/Fakaofo","value":"(UTC +13:00) Pacific\/Fakaofo"},
    {"key":"Pacific\/Tongatapu","value":"(UTC +13:00) Pacific\/Tongatapu"},
    {"key":"Pacific\/Kiritimati","value":"(UTC +14:00) Pacific\/Kiritimati"}
    ];
}
