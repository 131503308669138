import React, {useContext, useState} from 'react';
import {withRouter} from "react-router-dom";
import {ProfileContext} from "../../contexts/ProfileContext";
import AppHOC from "../HOC/App";

const Dashboard = ({history}) => {

    const context = useContext(ProfileContext);
    history.push("/collections");
    return (
       <>
           HELLO {context.value.email}
       </>
    );
}

export default withRouter (AppHOC(Dashboard));
