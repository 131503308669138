import {useContext, useEffect, useState} from "react";
import {getUtil} from "../api/util";


export const usePaymentMethods = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const res = await getUtil('payment_methods');
            await setData(res)
        };
        getData();

    }, []);




    return {
        data
    }
};
