import {useContext, useEffect, useState} from "react";
import {getIntegrations} from "../api/util";
import {getPlan, getPlans, savePlan, updatePlan} from "../api/plan";
import {error, success} from "../utils/func";
import {useHistory} from "react-router-dom";
import {forEach} from "ramda";


export const useIntegrations = (integrationType) => {

    let INITIAL_STATE = [];
    if(integrationType==="CRM"){
        INITIAL_STATE = {
            manuel:"Manuel",
            checkout:"Checkout"
        }
    }
    const [gateways,setGateways] = useState(INITIAL_STATE);

    useEffect(()=>{
        const getProviders = async () => {
            const res = await getIntegrations(integrationType);
            setGateways({...gateways,...res});
            console.log(gateways);
            console.log(res);
        };

        getProviders();
    },[]);

    return {
        gateways
    }
};

export const usePlan = () => {
    const [data, setData] = useState([]);
    const [form, setForm] = useState({
        id:null,
        free_trial:"none",
        type:'recurring',
        setup_fee_price:0,
        currency:'TRY',
        price:0,
        billing_form:true,
        email_templates: {
            new_subscription:true,
            subscription_change:true,
            subscription_expired:true,
            subscription_cancelled:true,
            new_invoice:true,
            payment_confirmation:true,
        },
        charge_sign_up_tolarence:0,
        prorate:"0",
        product_type:"digital",
        sources: []

    });
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [filters, setFilters] = useState({});
    const [validation, setValidation] = useState([]);
    const [submit, setSubmit] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const getData = async () => {
            const res = await getPlans({
                page: page,
                filters: filters,
            });
            await setData(res.data)
            await setTotalPage(res.meta.last_page)

        };
        getData()
    }, [page,filters]);

    useEffect(()=>{
        const getPlanData = async () => {
            const res = await getPlan(form.id);
            const newS = {};
            res.sources.map((val)=>{
               newS[val] = true;
            });


            await setForm({...form,...res,sources:newS})
        };
        if(form.id>0){
            getPlanData()
        }
    },[form.id]);

    useEffect(()=>{
        setPage(1);
    },[filters]);

    useEffect(()=>{
        const savePlanReq = async () => {
            try {
                let cleanForm = {...form,sources:Object.keys(form.sources)};
                if (form.id > 0) {
                    const res = await updatePlan(form.id, cleanForm);
                } else {
                    const res = await savePlan(cleanForm);
                }
                success();
                history.push("/settings/plans");
            }catch (e) {
                error(e);
            }
        };

        if(submit){
            setSubmit(false);
            savePlanReq();

        }

    },[submit]);




    return {
        data,
        form,
        validation,
        totalPage,
        page,
        onSubmit:() => {
            setSubmit(true);
        },
        onChangePagination : (e) => {
            setPage(e);
        },
        onChangeFilters: (e) => {
            setFilters({...filters,...e})
        },
        onDataChange: (data) => {
            setForm({...form,...data});
        }
    }
};



