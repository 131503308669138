import React, {useContext, useEffect, useState} from 'react';
import Input from "../../components/Input";
import AuthHOC from "../HOC/Auth";
import {withRouter} from "react-router-dom";
import {useLogin} from "../../hooks/useAuth";
import i18next from "i18next";
import {DomainContext} from "../../contexts/DomainContext";
import {ProfileContext} from "../../contexts/ProfileContext";
import config from "../../config";

const Login = ({history}) => {
    const domainContext = useContext(DomainContext);


    const { onEmailChange,onPasswordChange,onRememberChange,onSubmit } = useLogin();



    return (
        <div className="auth">

            <div className="auth__main" style={{backgroundImage: 'url("/assets/img/bg-login-mobile.jpg")'}}>
                <div className="auth__wrap">
                    <div className="auth__preview"><img className="auth__pic" src="/assets/img/logo-white.svg"
                                                        width={48} alt="Logo"/></div>
                    <div className="auth__title title title_xl">{i18next.t("Welcome to payment portal",{
                        company:domainContext.value?.name
                    })}</div>

                </div>
            </div>
            <div className="auth__container">
                <div className="auth__inner">

                    {domainContext.value?.logo ? <img src={domainContext.value.logo} /> : null} <br />
                    <div className="auth__head">
                        <div className="auth__title title title_xl">
                            <p style={{fontSize:'30px'}}>{i18next.t("Welcome to payment portal",{
                                company:domainContext.value?.name
                            })}</p>
                        </div>
                        {
                            window.location.host===config.admin_url ?
                                <div className="tag yellow" style={{width:"100%"}}>{i18next.t("Only administrators can login from this screen.")}</div>
                                : null
                        }

                    </div>

                    <div className="field auth__field">
                        <div className="field__label">{i18next.t("Email")}</div>
                        <div className="field__wrap">
                            <Input onChange={(val)=>{
                                onEmailChange(val)
                            }}
                            />
                            <div className="field__icon"><i className="la la-envelope "/></div>
                        </div>
                    </div>
                    <div className="field auth__field">
                        <div className="field__label">{i18next.t("Password")}</div>
                        <div className="field__wrap">
                            <Input
                                onChange={(val)=>{
                                    onPasswordChange(val)
                                }}

                                type="password"
                            />
                            <div className="field__icon"><i className="la la-lock "/></div>
                        </div>
                    </div>
                    <div className="auth__flex"><label className="switch auth__switch"><input className="switch__input"
                                                                                              type="checkbox"
                                                                                              value={true}
                                                                                              onChange={(e) => {
                                                                                                  onRememberChange(e)
                                                                                              }}/><span
                        className="switch__content">{i18next.t("Remember me")}</span></label>
                        <a className="auth__link" onClick={()=>{
                            history.push("/auth/forgot-password");
                        }}>{i18next.t("Recover password")}</a></div>

                    <div className="auth__btns">
                        <button className="btn auth__btn" onClick={onSubmit}>{i18next.t("Sign In")}</button>
                        {domainContext.value?.name === null ?<button className="btn btn_light auth__btn" onClick={()=>{
                            history.push("/auth/register");
                        }}>{i18next.t("Sign Up")}</button>: null}
                    </div>

                </div>
            </div>
            <div className="auth__bg" style={{backgroundImage: 'url("'+(domainContext.value?.sidebar ? domainContext.value?.sidebar : (domainContext.value?.sidebar===null ? "/assets/img/bg-login-sign-in.jpg" : ''))+'")'}}/>
        </div>

    );
}

export default withRouter (AuthHOC(Login));
