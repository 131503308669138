import React, {useContext, useEffect, useRef, useState} from 'react';
import {withRouter} from "react-router-dom";
import {SidebarChild,SideBarBottom} from "./sidebarChild";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import Table from "../../components/Table/table";
import Head from "../../components/Table/head";
import Avatar from "react-avatar";
import {useAccounts} from "../../hooks/useAccounts";
import Pagination from "../../components/Table/pagination";
import getStatusColorByAccount, {
    capitalize,
    dateFormat,
    getStatusColorByCollection,
    moneyFormat,
    statusAccount,
    statusCollection, useQuery
} from "../../utils/func";
import {DropdownMenu} from "../../components/Button";
import {useCollections} from "../../hooks/useCollections";
import {ProfileContext} from "../../contexts/ProfileContext";
import i18next from "i18next";
import ReactTooltip from "react-tooltip";
import ActionMenu from "./action_menu";
import ProgressIndicator from "../../components/Theme/ProgressIndicator"
import {ConfirmButton} from "../../components/Button/confirm";
import {Trans} from "react-i18next";



const Collections = ({history,i18,match,location}) => {


    const context = useContext(ProfileContext);
    const [sortMenu,setSortMenu] = useState(false);
    const [actionOpen,setActionOpen] = useState(false);
    const [status,setStatus] = useState(null);
    const [activeBtn,setActiveBtn] = useState(null);
    const [search,setSearch] = useState(null);
    const [currentCollection,setCurrentCollection] = useState(-1);
    const { data , onChangePagination,totalPage,page , onChangeFilters , summary ,onChange,setRefresh,onChangeActivePeriod,activeMonth,chargeInvoice,chargeStatus,loading,onExport,onChangePage,actions,setChargeInvoice,
        setChargeStatus
    } = useCollections({
        onUpdate: () => {
            setActiveBtn(null);
        },
        onLoadData : () => {
            ReactTooltip.rebuild();
            setActiveBtn(null);
        }
    });

    const [menu,setMenu] = useState("charge_progress");

    useEffect(()=>{
        ReactTooltip.rebuild();
    },[activeBtn]);

    useEffect(()=>{
        const url = new URLSearchParams(location.search)
        let filters = [];
        url.forEach((value,key) => {
            if(key.startsWith("filter__")){
                filters = {...filters,[key.replace("filter__","")]:value};
            }
        })
        //onChangeFilters({});
    },[]);





    const sidebar = <SidebarChild history={history} menu={menu} setMenu={setMenu} onChangeFilters={onChangeFilters} onChangePage={onChangePage} data={summary} refresh={()=>{
        setActiveBtn(null);
    }} activeMonth={activeMonth}/>;
    const sidebarBottom = <SideBarBottom onChangeFilters={onChangeActivePeriod} activeMonth={activeMonth} />;



    const GetActions = ({item}) => {

        if(item.actions.length == 0){
            return (
                <>
                    {i18next.t("No Available Actions")}
                </>
            )
        }

        return (
            item.actions.map((a,i)=>{
                return (

                        <ConfirmButton onClick={async ()=>{
                            await onChange(item.id,{
                                status:a.toUpperCase(),
                            },setRefresh);
                        }} title={i18next.t("Are you sure?")} styles={"action action_stroke action_btn "+getStatusColorByCollection(a)}>
                           {i18next.t(capitalize(a,true))}
                        </ConfirmButton>
                );
            })

        )
    };
    let actionWidth = {};


    let tableProps = {};

    //alert(menu);
    if(menu==="charge_progress"){
        tableProps.noContentTitle = <Trans>You have no invoices currently being charged.</Trans>;
        tableProps.noContentDescription = <Trans>Please check back later or check out <span style={{textDecoration:"underline",cursor:"pointer"}} onClick={e=>{
            setMenu("scheduled");
            onChangeFilters({status:"scheduled",sort_key:"bill_date",order:"asc",try_count:"untried"});
            onChangePage(1);
        }}>Scheduled Invoices.</span></Trans>
    }else{
        tableProps.noContentTitle = <Trans>No invoices to show here.</Trans>
        tableProps.noContentDescription = <Trans>Please change your filters to check out other invoices.</Trans>
    }

    return (
        <Layout sidebar={sidebar} sidebarBottom={sidebarBottom} title={i18next.t("Invoices")} onSearch={onChangeFilters}>

            <div>
                {/* panel*/}
                <div className="panel js-panel">
                    <div className="panel__head panel__head_line">
                        <div className="panel__group btn-group text-right btn-group_tabs action_menu_group">

                            <ActionMenu activeMonth={activeMonth} setMenu={setMenu} activeMenu={menu} dataLength={data.length} actions={actions} summary={summary}/>
                            <button className="btn btn_light btn_icon" style={{float:"right"}} onClick={()=>{
                                onExport();
                            }}><i className="la la-file-excel "></i> {i18next.t("Export")}</button>
                        </div>
                        {/* btn group   */}
                        <div className="panel__group btn-group btn-group_tabs" hidden>
                            <button className={"action action_title "+(status===null ? 'active' : null)} onClick={()=>{
                                setStatus(null)
                                onChangeFilters({status:null})

                            }}>{i18next.t("All")}</button>
                            <button className={"action action_title "+(status==="OPEN" ? 'active' : null)} onClick={()=>{
                                setStatus("OPEN")
                                onChangeFilters({status:"OPEN"})
                            }}>Insufficient funds</button>
                            <button className={"action action_title "+(status==="INVITED" ? 'active' : null)} onClick={()=>{
                                setStatus("INVITED")
                                onChangeFilters({status:"INVITED"})
                            }}>Bank declined</button>
                            <button className={"action action_title "+(status==="INVITED_FAILED" ? 'active' : null)} onClick={()=>{
                                setStatus("INVITED_FAILED")
                                onChangeFilters({status:"INVITED_FAILED"})
                            }}>Fraud</button>
                            <button className={"action action_title "+(status==="CLOSED" ? 'active' : null)} onClick={()=>{
                                setStatus("CLOSED")
                                onChangeFilters({status:"CLOSED"})
                            }}>Others</button>
                        </div>
                        <div className="panel__group" hidden>
                            <div className={"sort js-sort "+(sortMenu ? 'open' : '')}>
                                <div className="sort__head js-sort-head" onClick={()=>{setSortMenu(!sortMenu)}}>
                                    <div className="sort__icon"><i className="la la-filter " /></div>
                                    <div className="sort__label">sort:</div>
                                    <div className="sort__selected js-sort-selected">A-Z</div>
                                    <div className="sort__arrow"><i className="la la-angle-down " /></div>
                                </div>
                                <div className="sort__dropdown js-sort-dropdown">
                                    <div className="sort__title">Sort projects by</div>
                                    <div className="sort__items">
                                        <div className="sort__item"><label className="switch sort__switch js-sort-switch"><input className="switch__input" type="radio" name="sort" defaultChecked="checked" /><span className="switch__content">A-Z</span></label></div>
                                        <div className="sort__item"><label className="switch sort__switch js-sort-switch"><input className="switch__input" type="radio" name="sort" /><span className="switch__content">Budget</span></label></div>
                                        <div className="sort__item"><label className="switch sort__switch js-sort-switch"><input className="switch__input" type="radio" name="sort" /><span className="switch__content">Assigned Tasks</span></label></div>
                                        <div className="sort__item"><label className="switch sort__switch js-sort-switch"><input className="switch__input" type="radio" name="sort" /><span className="switch__content">Progress</span></label></div>
                                        <div className="sort__item"><label className="switch sort__switch js-sort-switch"><input className="switch__input" type="radio" name="sort" /><span className="switch__content">Date Created</span></label></div>
                                    </div>
                                    <div className="sort__foot"><button className="sort__btn btn btn_light js-sort-apply" onClick={()=>{setSortMenu(false);}} >Apply</button></div>
                                </div>
                                <div className="sort__backdrop backdrop js-sort-backdrop" onClick={()=>{setSortMenu(false);}} />
                            </div>
                        </div>
                    </div>
                    <div className="panel__body">
                        <div className="panel__tab js-panel-tab" style={{display: 'block'}}>
                            {/* data*/}
                            <div className="data data_list">
                                <div className="data__container collection__table">
                                   <Table head={
                                           <>
                                               <Head extraClassName="data__cell_lg table_invoice_code" onClick={onChangeFilters} sortKey={"invoice_code"}>{i18next.t("Number")}</Head>
                                               <Head extraClassName="data__cell_xl table_invoice_amount">{i18next.t("Customer")}</Head>
                                               <Head extraClassName="data__cell_md table_invoice_amount" onClick={onChangeFilters} sortKey={"amount"}>{i18next.t("Amount")}</Head>
                                               <Head extraClassName="data__cell_md table_invoice_status">{i18next.t("Status")}</Head>
                                               <Head extraClassName={"data__cell_sm text-center"}>{i18next.t("Actions")}</Head>
                                           </>
                                       }
                                   loading={loading}
                                          {...tableProps}
                                   >
                                       {

                                           data.map((v,i)=>{


                                               return (
                                                   <div key={i} className="data__item" onMouseEnter={()=>{
                                                       setCurrentCollection(i);
                                                       console.log("hover");
                                                   }} onMouseLeave={()=>{
                                                       if(currentCollection===i){
                                                           setCurrentCollection(-1);
                                                       }
                                                   }}>
                                                       <div className={"data__row "+(activeBtn===i ? "action_menu_button_list" : null)}>
                                                           {activeBtn === i ?
                                                               <div
                                                                   className="data__cell data__cell_lg action_btn_list">
                                                                   <div className={"flex_result_text "+(chargeInvoice===v.id ? null : "hidden")}>
                                                                       {chargeStatus}
                                                                   </div>
                                                                   <div className={(chargeInvoice===v.id ? "blur_container" : null)+" float-div"}>
                                                                        <GetActions item={v}/>
                                                                   </div>
                                                               </div>
                                                               :
                                                               <>
                                                                   <div className="data__cell data__cell_lg table_invoice_code">
                                                                       <div className="data__main white_space">
                                                                           <div className="data__preview blue"><i
                                                                               className="la la-calculator "/></div>
                                                                           <div className="data__wrap">
                                                                               <div className="data__content">
                                                                                   {dateFormat(v.invoice_date)}
                                                                               </div>
                                                                               <div
                                                                                   className="data__label">{v.invoice_code}</div>
                                                                           </div>
                                                                       </div>
                                                                       <div style={{width:"28%",maxHeight:'30px',minHeight:'30px',position:"absolute"}}>
                                                                           <ProgressIndicator key={i} active={currentCollection} that={i} values={v.progressIndicator} />
                                                                       </div>
                                                                   </div>

                                                                   <div className="data__cell data__cell_xl table_invoice_account">
                                                                       <div className="data__main">
                                                                           <div className="data__preview bg-none">
                                                                               <Avatar name={v.account.full_name}
                                                                                       maxInitials={2} round={true}
                                                                                       size={50}/></div>
                                                                           <div className="data__wrap text_wrap_email">

                                                                               <div
                                                                                   className="data__content">{v.account.full_name}
                                                                               </div>
                                                                               <div
                                                                                   className="data__label">{v.account.contact_email}</div>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                                   <div className="data__cell data__cell_md table_invoice_amount">
                                                                       <div className="data__content">
                                                                           <strong>{moneyFormat(v.amount, v.currency)}</strong>
                                                                       </div>
                                                                   </div>
                                                                   <div className="data__cell data__cell_md table_invoice_status">
                                                                       <div className="tag-group c-p"  >
                                                                           {statusCollection(v.status,v.last_message)}
                                                                       </div>
                                                                   </div>
                                                               </>
                                                           }

                                                           <div className="data__cell data__cell_sm action_btn_row">
                                                                   <DropdownMenu currentActive={activeBtn} active={i} onClick={()=>{
                                                                       if(activeBtn!=i){
                                                                           setActiveBtn(i);
                                                                       }
                                                                       else{
                                                                           setActiveBtn(null);
                                                                           setChargeInvoice(null);
                                                                           setChargeStatus((<><i className="la la-spinner la-spin" /> Please wait, collection started..</>));
                                                                       }

                                                                   }}>
                                                                   </DropdownMenu>
                                                           </div>
                                                       </div>
                                                   </div>
                                               );
                                           })
                                       }
                                   </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel__foot">
                        <Pagination totalPage={totalPage}  activePage={page} onChange={onChangePagination}/>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(Collections));
