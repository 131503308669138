import {get, put , post} from "../utils/http";

export const getAccounts = async (filters) => {
    try {
        return await get('/accounts',filters);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getAccountsExportUrl = async (filters) => {
    try {
        return await get('/accounts/export',filters);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getAccountsSummary = async (filters) => {
    try {
        return await get('/accounts/summary');
    }catch (e) {
        throw e.response.data.error;
    }
}

export const putAccount = async (id,data) => {
    try {
        return await put('/accounts/'+id,data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const createAccount = async (data) => {
    try {
        return await post('/accounts',data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getAccount = async (id) => {
    try {
        return await get('/accounts/'+id);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const sendInvitation = async (state) => {
    try {
        return await post('/accounts/notification/invitation',{
            status: state
        });
    }catch (e) {
        throw e.response.data.error;
    }
}

export const sendPaymentMethodNotify = async () => {
    try {
        return await post('/accounts/notification/payment_method');
    }catch (e) {
        throw e.response.data.error;
    }
}
