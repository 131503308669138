import {useContext, useEffect, useState} from "react";
import * as R from 'ramda';
import {error, success, sumObject} from "../utils/func";
import {defaultCreditCard, deleteCreditCard, getCreditCard, getCreditCards, saveCreditCard} from "../api/creditCard";
import {useHistory} from "react-router-dom";
import {ProfileContext} from "../contexts/ProfileContext";

export const useCreditCard = () => {
    const [data, setData] = useState([]);
    const [update, setUpdate] = useState({
        last_digit: "****"
    });
    const [validation,setValidation] = useState({});
    const [submit, setSubmit] = useState(false);
    const [item, setItem] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const history = useHistory();
    const context = useContext(ProfileContext);

    useEffect(() => {
        const getData = async () => {
            const res = await getCreditCard(item);
            setUpdate({
                readonly:true,
                holdername:res.name,
                number:"**** **** **** "+res.last_digit,
                ...res

            });
        };
        if(item!=0 && item!=undefined) {
            getData();

        }
        return () => {

        };
    }, [item]);

    useEffect(() => {

        const getData = async () => {
           const res = await getCreditCards();
           setData(res.data);
        };

        getData();


        return () => {

        };
    }, [submit,refresh]);



    useEffect(()=>{
        const saveData = async () => {

            if(submit===true){
                try{
                    const res = await saveCreditCard(update);
                    success("");
                    setRefresh(true);
                    setUpdate([]);
                    context.dispatch();
                    history.push("/payment-methods/");
                }catch (e) {
                    console.log("error");
                    console.log(e);
                    if(e.group === "validation"){
                        let validate = {};

                        for (let error in e.data){
                            validate = {...validate,[error] : e.data[error][0]};
                        }

                        setValidation(validate)
                    }else{
                        error(e.message);
                    }
                }

            }

            setSubmit(false);

        };

        saveData();

    },[submit]);

    return {
        data,
        update,
        validation,
        setItem,
        onChange: (obj) => {
            setUpdate({...update,...obj})
        },
        onSubmit: () => {
            setSubmit(true);
        },
        onDelete: (id) => {
            const deleteCard = async () => {
                try {
                    const res = await deleteCreditCard(id);
                    success("");
                    context.dispatch();
                    history.push("/payment-methods");
                }catch (e) {
                    error(e.message);
                }
            };

            deleteCard();
        },
        setDefault: (id) => {
            const setDefaultCard = async () => {
                try {
                    const res = await defaultCreditCard(id);
                    success("");
                    setItem(id);
                    setRefresh(true);
                }catch (e) {
                    error(e.message);
                }
            };

            setDefaultCard();
        }
    }
};
