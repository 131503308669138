import React from "react";
import SearchLoading from "../Theme/SearchLoading";
import i18next from "i18next";

const Table = ({children,head,loading,minimal=false,className="",noContentTitle=i18next.t("No content added."),noContentDescription=i18next.t("Maybe you can try adding content?")}) => {


    if(children.length === 0 && loading){
        return (<div className={"empty"}>
            <div className="empty__container">
                <div className="empty__preview"><img className="empty__pic" src="/assets/img/pic-7.png" alt="Empty" /></div>
                <div className="empty__title title title_lg">
                    <p>{noContentTitle}</p>
                </div>
                <div className="empty__text">{noContentDescription}</div>
            </div>
        </div>);
    }

    if(children.length === 0 ){
        return (
            <>

            </>);
    }


    return (
        <>
            <div className="data__head">
                <div className="data__row">
                    {head}
                </div>
            </div>
            <div className={"data__body "+(minimal ? 'inline-table' : "") + " "+className}>
                {children}
            </div>
        </>
    );
};

export default Table;
