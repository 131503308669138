import React from "react";
const ProgressIndicator = ({that,active,values}) => {

    return (
        <div className={"ProgressIndicator"} style={{display:(active===that ? 'flex' : 'none')}}>
            {
                values.slice(Math.max(values.length - 7, 0)).map((item)=>{
                    let style = '';
                    if(item.status=="failed"){
                        style = 'active bg-red-raw';
                    }else if(item.status=="success"){
                        style = 'active bg-blue-raw';
                    }
                    return (
                        <div data-tip={item.message} className={"progress__value "+(style)} style={{width:'100%'}} />
                    );
                })
            }

        </div>
    );
};

export default ProgressIndicator;
