import React, {useEffect, useState} from "react";
import {useAccounts} from "../../hooks/useAccounts";
import SideBarChildItem from "../../components/Theme/SideBarChildItem";
import i18next from "i18next";

const SidebarChild = ({history,data,onChangeFilters,onChangePage=f=>f,menu="active",setMenu=f=>f}) => {
    const path = history.location.pathname;
    useEffect(()=>{
        onChangeFilters({status:"OPEN"});
    },[]);
    return (
        <div className="sidebar__section">
            {/* widget*/}
            <div className="widget">
                <div className="widget__head">
                    <div className="widget__title title title_sm">{i18next.t("Accounts Overview")}</div>
                    <div className="widget__text">{i18next.t("Your employees and clients details")}</div>
                </div>
                <div className="widget__body">
                    {/* updates*/}
                    <div className="overview sidebar_child">
                        <SideBarChildItem active={menu==="active"} onClick={()=>{
                            setMenu("active");
                            onChangeFilters({status:"OPEN"});
                            onChangePage(1);
                            history.push("/accounts")
                        }}  description={data.active} title={i18next.t("Active")} iconColor="green" icon={"la la-user-check"} />
                        <SideBarChildItem active={menu==="invited"} onClick={()=>{
                            setMenu("invited");
                            onChangeFilters({status:"INVITED"});
                            onChangePage(1);
                            history.push("/accounts")
                        }}  description={data.invited} title={i18next.t("Invited")} iconColor="yellow" icon={"la la-user-clock"} />
                        <SideBarChildItem active={menu==="invited_failed"} onClick={()=>{
                            setMenu("invited_failed");
                            onChangeFilters({status:"INVITED_FAILED"});
                            onChangePage(1);
                            history.push("/accounts")
                        }}  description={data.invited_failed} title={i18next.t("Invited Failed")} iconColor="red" icon={"la la-user-slash"} />
                        <SideBarChildItem active={menu==="draft"} onClick={()=>{
                            setMenu("draft");
                            onChangeFilters({status:"DRAFT"});
                            onChangePage(1);
                            history.push("/accounts")
                        }}  description={data.draft} title={i18next.t("Draft")} iconColor="yellow" icon={"la la-user-edit"} />
                        <SideBarChildItem active={menu==="closed"} onClick={()=>{
                            setMenu("closed");
                            onChangeFilters({status:"CLOSED"});
                            onChangePage(1);
                            history.push("/accounts")
                        }}  description={data.closed} title={i18next.t("Closed")} iconColor="gray" icon={"la la-user-lock"} />
                        <SideBarChildItem active={menu==="all"} onClick={()=>{
                            setMenu("all");
                            onChangeFilters({status:null});
                            onChangePage(1);
                            history.push("/accounts")
                        }} description={data.all} title={i18next.t("All")} iconColor="blue" icon={"la la-users"} />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SidebarChild;
