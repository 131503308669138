import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import config from '../../../config';
import Back from "../../layouts/back";
import {usePaymentMethodEdit} from "../../../hooks/usePaymentMethodEdit";
import {ap} from "ramda";
import i18next from "i18next";
import {capitalize} from "../../../utils/func";
const PaymentMethodDetail = ({history,match}) => {

    const sidebar = <SidebarChild history={history}/>;

    const fields = config.payment_providers[match.params.key];
    const {onApiKeyChange,onPrivateKeyChange,onProviderChange,onSubmit,apiKey,privateKey} = usePaymentMethodEdit();
    const hooks = {
        "onApiKeyChange" : onApiKeyChange,
        "onPrivateKeyChange": onPrivateKeyChange
    };
    const inputs = {
        "apiKey" : apiKey,
        "privateKey" : privateKey
    };
    useEffect(()=>{
        onProviderChange(match.params.key);
    });

    return (
        <Layout sidebar={sidebar} title={i18next.t("Payment Method") +" > "+capitalize(match.params.key)}>
            <div className="panel">
                <Back onClick={()=>{history.goBack()}} />
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">
                            {fields.map((val,i)=>{
                                return (
                                    <Field
                                        key={i}
                                        value={inputs[val.key]}
                                        label={val.label}
                                        description={val.description}
                                        onChange={(e)=>{
                                            (hooks[val.hook])(e)
                                        }}
                                    />
                                );
                            })}

                            <button className="settings__btn btn" onClick={()=>{
                                onSubmit();
                            }}>{i18next.t("Save")}</button>

                        </div>

                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(PaymentMethodDetail));
