import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import SidebarChild from "./sidebarChild";
import Field from "../../components/Input/field";
import Select from "../../components/Select";
import {useSettings} from "../../hooks/useSettings";

const SettingsIndex = ({history}) => {

    const sidebar = <SidebarChild history={history}/>;

    return (
        <Layout sidebar={sidebar} title={"Collections"}>
            <div className="help">
                <div className="help__head">
                    <form className="help__form" action>
                        <div className="help__title title title_xl">Hello, how can we help?</div>
                        <div className="help__text color-gray">Type your question or search keyword</div>
                        <div className="field help__field">
                            <div className="field__wrap"><input className="field__input" type="search" placeholder="Start typing…" />
                                <div className="field__icon"><i className="la la-search " /></div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="help__body">
                    <div className="accordion js-accordion">
                        <div className="accordion__item">
                            <div className="accordion__head js-accordion-head">
                                <div className="accordion__title title">What is the Prepaid Mastercard?</div>
                                <div className="accordion__arrow"><i className="la la-angle-down " /></div>
                            </div>
                            <div className="accordion__body js-accordion-body" style={{display:"block"}}>
                                <p>A good logo works in the simplest form. It is a memorable representation of your brand and inspires confidence in your customers. It should be fresh and original — without visual cliches or amateur effects. A logo is well-designed when it looks as good on a business card as it does on a web page or a billboard. To be functional, a good logo must reduce well to simple black &amp; white or grayscale for use on faxes or in newspaper ads. The best logos are elegantly simple.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(SettingsIndex));
