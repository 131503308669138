import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import {SideBarBottom, SidebarChild} from "./sidebarChild";
import Field from "../../components/Input/field";
import Select from "../../components/Select";
import {useSettings} from "../../hooks/useSettings";
import {useProfile} from "../../hooks/useProfile";
import {ProfileContext} from "../../contexts/ProfileContext";
import i18next from "i18next";
import timezones from "../../utils/timezones";
import config from '../../config';
const ProfileDetail = ({history}) => {

    const sidebar = <SidebarChild history={history}/>;
    const {data,onDataChange,onSave} = useProfile();
    const context = useContext(ProfileContext);
    const languages = config.languages

    const timezone = timezones();

    return (
        <Layout sidebar={sidebar} sidebarBottom={<SideBarBottom history={history}/>} title={i18next.t("Update Information")}>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">

                            {context.value.isAdmin ?
                                <>
                                    <div className="form__row">
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Field onChange={(e)=>{
                                                    onDataChange({...data,profile:{...data.profile,company:e.target.value}});
                                                }} value={data?.profile.company} label={i18next.t("Company Name")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Select onChange={(e)=>{
                                                    onDataChange({...data,profile:{...data.profile,company_lang:e.target.value}});
                                                }} label={i18next.t("Default Language")} selected={data?.profile.company_lang} options={languages} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Select onChange={(e)=>{
                                                    onDataChange({...data,profile:{...data.profile,timezone:e.target.value}});
                                                }} label={i18next.t("Default Timezone")} selected={data?.profile.timezone} options={timezone} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null }
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">

                                        <Field onChange={(e)=>{
                                            onDataChange({...data,profile:{...data.profile,name:e.target.value}});
                                        }} readonly={!context.value.isAdmin} value={data?.profile.name} label={i18next.t("Name Surname")} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field onChange={(e)=>{
                                            onDataChange({...data,profile:{...data.profile,email:e.target.value}});
                                        }} readonly={!context.value.isAdmin} value={data?.profile.email} label={i18next.t("Email")} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Select onChange={(e)=>{
                                            onDataChange({...data,profile:{...data.profile,lang:e.target.value}});
                                        }} label={i18next.t("Preferred Language")} selected={data?.profile.lang} options={languages} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="settings__foot">
                            <div className="settings__btns">
                                <button className="settings__btn btn" onClick={()=>{
                                    onSave("profileEdit")
                                }}>{i18next.t("Update")}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(ProfileDetail));
