import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import {SideBarBottom, SidebarChild} from "./sidebarChild";
import Field from "../../components/Input/field";
import Select from "../../components/Select";
import {useSettings} from "../../hooks/useSettings";
import {useProfile} from "../../hooks/useProfile";
import i18next from "i18next";

const ChangePassword = ({history}) => {

    const sidebar = <SidebarChild history={history}/>;
    const {data,onDataChange,onSave,validation} = useProfile();

    return (
        <Layout sidebar={sidebar} sidebarBottom={<SideBarBottom history={history}/>} title={i18next.t("Change Password")}>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">

                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field onChange={(e)=>{
                                            onDataChange({...data,password:{...data.password,current_password:e.target.value}});
                                        }} errors={validation.current_password} value={data?.password.current_password} type={"password"} label={i18next.t("Current Password")} description={i18next.t("Current Password description")} />
                                    </div>
                                </div>
                            </div>

                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field onChange={(e)=>{
                                            onDataChange({...data,password:{...data.password,password:e.target.value}});
                                        }} errors={validation.password} value={data?.password.password} type={"password"} label={i18next.t("New Password")} description={i18next.t("New Password description")} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field onChange={(e)=>{
                                            onDataChange({...data,password:{...data.password,password_confirmation:e.target.value}});
                                        }} value={data?.password.password_confirmation} type={"password"}  label={i18next.t("Password Confirmation")} description={i18next.t("Password Confirmation description")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="settings__foot">
                            <div className="settings__btns">
                                <button className="settings__btn btn" onClick={()=>{
                                    onSave("changePassword")
                                }}>{i18next.t("Update")}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(ChangePassword));
