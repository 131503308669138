import {ProfileContext, ProfileProvider} from "../../contexts/ProfileContext";
import React, {useContext, useEffect, useState} from "react";
import Sidebar from "./sidebar";
import AppHOC from "../HOC/App";
import {withRouter} from "react-router-dom";
import Avatar from "react-avatar";
import i18next from "i18next";
import * as R from 'ramda';
import {debounce} from "../../utils/func";
import ReactGA from "react-ga";




const Layout = ({children,sidebar=f=>f,title='Dashboard',sidebarBottom=f=>f,onSearch=null,pageClass=""}) => {

    const context = useContext(ProfileContext);
    const [openQuickMenu,setOpenQuickMenu] = useState("");
    const [openMenu,setOpenMenu] = useState(false);
    const [searchInput,setSearchInput] = useState("");
    ReactGA.ga('send', 'pageview', window.location.pathname + window.location.search);
    const closeSearchBox = () => {
        window["search_text"]=null;
        onSearch({search:null});
        setSearchInput("");
    };

    return (
            <div className={"page "+pageClass}>
                {/* sidebar*/}
                <div className="sidebar js-sidebar">
                    <Sidebar openMenu={openMenu} fullName={context.value.name}/>
                    <div className={"sidebar__container js-sidebar-container "+(openMenu ? 'toggled' : '')}>
                        <div className="sidebar__head">
                            <div className="sidebar__preview">

                                <Avatar name={context.value.show_name} textSizeRatio={2}  maxInitials={1} size={40}/>
                            </div>
                            <div className="sidebar__wrap">
                                <div className="sidebar__title">{context.value.show_name}</div>
                                <div className="sidebar__text">{context.value.email}</div>
                            </div><button className="sidebar__action sidebar__action_toggle action js-sidebar-toggle"><i className="la la-angle-left " /></button>
                        </div>
                        <div className="sidebar__inner">
                            {sidebar}
                            <div className="sidebar__section">
                                {sidebarBottom}

                            </div>
                        </div>
                    </div>
                </div>{/* container*/}
                <div className={"container js-container "+(openMenu ? 'extendeds' : '')}>
                    <div className="container__head">
                        <div className="container__title title title_md">{title}</div>{/* search*/}
                        <div className={"container__search search js-search "+searchInput+" "+(onSearch===null ? 'hidden' : '')}><button className="search__action action js-search-open" onClick={()=>{
                            setSearchInput("open");

                        }}><i className="la la-search " /></button>
                            <div className="search__dropdown js-search-dropdown">

                                <div className="search__field"><input className="search__input js-search-input" type="text" ref={input => input && input.focus()} onChange={(e)=>{
                                    window["search_text"] = e.target.value;
                                    debounce(()=>{
                                        onSearch({search:window["search_text"]})
                                    },500)()
                                }} placeholder={i18next.t("Search...")} />
                                    <div className="search__icon c-p" style={{right:'35px'}} onClick={()=>{

                                        closeSearchBox();

                                    }}><i className="la la-close " /></div>
                                    <div className="search__icon"><i className="la la-search " /></div>
                                </div>
                                <div className="search__results js-search-results"><a className="search__result" href="#">
                                    <div className="search__cell">
                                        <div className="search__preview color-red"><i className="lab la-dribbble " /></div>
                                    </div>
                                    <div className="search__cell">
                                        <div className="search__title">Dribble Redesign</div>
                                        <div className="search__text">Webdesign</div>
                                    </div>
                                    <div className="search__cell mobile-hide">
                                        {/* tag*/}
                                        <div className="search__tag tag gray">24 Feb 2019</div>
                                    </div>
                                </a><a className="search__result" href="#">
                                    <div className="search__cell">
                                        <div className="search__preview color-green"><i className="lab la-evernote " /></div>
                                    </div>
                                    <div className="search__cell">
                                        <div className="search__title">New HTML Theme</div>
                                        <div className="search__text">Theme</div>
                                    </div>
                                    <div className="search__cell mobile-hide">
                                        {/* tag*/}
                                        <div className="search__tag tag gray">20 May 2019</div>
                                    </div>
                                </a><a className="search__result" href="#">
                                    <div className="search__cell">
                                        <div className="search__preview"><img className="search__pic" src="/assets/img/user-1.jpg" alt="User" /></div>
                                    </div>
                                    <div className="search__cell">
                                        <div className="search__title">Bradley Wilkins</div>
                                        <div className="search__text">b.wilkins@gmail.com</div>
                                    </div>
                                    <div className="search__cell mobile-hide">
                                        {/* tag*/}
                                        <div className="search__tag tag gray">Designer</div>
                                    </div>
                                </a></div>
                            </div>

                        </div>{/* new*/}
                        <div className={"hidden container__new new js-new "+(openQuickMenu ? 'open' : '')}><button className="new__action action js-new-open" onClick={()=>{setOpenQuickMenu(!openQuickMenu)}}><i className="la la-plus-circle " /></button>
                            <div className="new__dropdown js-new-dropdown"><a className="new__item" href="#">
                                <div className="new__icon"><i className="la la-toolbox " /></div>
                                <div className="new__title">New Project</div>
                            </a><a className="new__item" href="#">
                                <div className="new__icon"><i className="la la-clipboard-check " /></div>
                                <div className="new__title">New Task</div>
                            </a><a className="new__item" href="#">
                                <div className="new__icon"><i className="la la-user-lock " /></div>
                                <div className="new__title">New Contact</div>
                            </a><a className="new__item" href="#">
                                <div className="new__icon"><i className="la la-calendar-week " /></div>
                                <div className="new__title">New Event</div>
                            </a><a className="new__item" href="#">
                                <div className="new__icon"><i className="la la-cube " /></div>
                                <div className="new__title">New Product</div>
                            </a><a className="new__item" href="#">
                                <div className="new__icon"><i className="la la-calculator " /></div>
                                <div className="new__title">New Invoice</div>
                            </a></div>
                            <div className="new__backdrop backdrop js-new-backdrop aa"  onClick={()=>{setOpenQuickMenu(!openQuickMenu)}} />
                        </div>
                    </div>
                    <div className="container__body">
                        {children}
                    </div>
                </div>
            </div>
    )
};

export default Layout;
