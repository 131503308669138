import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import config from '../../../config';
import Back from "../../layouts/back";

import {useSettings} from "../../../hooks/useSettings";
import i18next from "i18next";
import Select from "../../../components/Select";
import {usePlan} from "../../../hooks/usePlan";
import Checkbox from "../../../components/Checkbox";

const activePassive = [
    {"key":true,"value":"Aktif"},
    {"key":false,"value":"Pasif"},
];

const Nexudus = ({data,onChange}) => {
    const val = {email:null,password:null,...data};
    return (
        <>
            <Field
                value={val.email}
                label={i18next.t("Email")}
                description={i18next.t("Nexudus api email")}
                onChange={(e)=>{
                    onChange({...val,email:e.target.value});
                }}
            />
            <Field
                value={val.password}
                label={i18next.t("Password")}
                description={i18next.t("Nexudus api password")}
                type="password"
                onChange={(e)=>{
                    onChange({...val,password:e.target.value});
                }}
            />
            <div className="mb-30">
                <Checkbox selected={val?.sync_open_account} label={i18next.t("Sync active customer invoices only.")} onClick={(e)=>{
                    onChange({...val,sync_open_account:e.target.checked});
                }} />
            </div>
        </>
    );
};

const Kiva = ({data,onChange}) => {
    const val = {api_id:null,api_key:null,app_secret:null,...data};
    return (
        <>
            <Field
                value={val.api_id}
                label={i18next.t("Api ID")}
                description={i18next.t("Kiva api id")}
                onChange={(e)=>{
                    onChange({...val,api_id:e.target.value});
                }}
            />
            <Field
                value={val.api_key}
                label={i18next.t("Api Key")}
                description={i18next.t("Kiva api key")}
                onChange={(e)=>{
                    onChange({...val,api_key:e.target.value});
                }}
            />
            <Field
                value={val.app_secret}
                label={i18next.t("App Secret")}
                description={i18next.t("Kiva app secret")}
                onChange={(e)=>{
                    onChange({...val,app_secret:e.target.value});
                }}
            />
            <div className="mb-30">
                <Checkbox selected={val?.sync_open_account} label={i18next.t("Sync active customer invoices only.")} onClick={(e)=>{
                    onChange({...val,sync_open_account:e.target.checked});
                }} />
            </div>

        </>
    );
};

const Vedubox = ({data,onChange,plans}) => {
    const val = {status:null,token:null,login_url:null,...data};
    return (
        <>
            <Select onChange={(e)=>{
                onChange({...val,status:e.target.value});
            }} label={i18next.t("Status")} selected={val.status} options={activePassive} />
            <Field
                value={val.token}
                label={i18next.t("Token")}
                onChange={(e)=>{
                    onChange({...val,token:e.target.value});
                }}
            />
            <Field
                value={val.login_url}
                label={i18next.t("Giriş Adresi")}
                onChange={(e)=>{
                    onChange({...val,login_url:e.target.value});
                }}
            />
            {plans && plans.map((item)=>{
                return (
                    <>
                        <Field
                            value={val?.["plan_"+item.id]}
                            label={item.title+" Target ID"}
                            onChange={(e)=>{
                                onChange({...val,["plan_"+item.id]:e.target.value});
                            }}
                        />

                    </>
                )
            })}
        </>
    );
};

const IntegrationDetail = ({history,match}) => {

    const sidebar = <SidebarChild history={history}/>;

    const {data,onGroupChange,onSubmit,onDataChange,onGoBackChange} = useSettings();
    const {data:plans} = usePlan();

    console.log(plans);
    useEffect(()=>{
        onGroupChange("crm_"+match.params.key);
        onGoBackChange(true);

    });


    const Provider = () => {
        if(match.params.key==="nexudus")
            return (<Nexudus data={data} onChange={onDataChange} />);
        if(match.params.key==="kiva")
            return (<Kiva data={data} onChange={onDataChange}/>);
        if(match.params.key==="vedubox")
            return (<Vedubox data={data} onChange={onDataChange} plans={plans} />);
    }

    return (
        <Layout sidebar={sidebar} title={i18next.t("Integration") +" > "+ match.params.key}>
            <div className="panel">
                <Back onClick={()=>{history.goBack()}} />
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">

                            <Provider />

                            <button className="settings__btn btn" onClick={()=>{
                                onSubmit();
                            }}>{i18next.t("Save")}</button>

                        </div>

                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(IntegrationDetail));
