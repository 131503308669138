import {ProfileContext, ProfileProvider} from "../../contexts/ProfileContext";
import React, {useContext, useEffect, useState} from "react";
import Sidebar from "./sidebar";
import AppHOC from "../HOC/App";
import {Link, withRouter} from "react-router-dom";
import Avatar from "react-avatar";
import i18next from "i18next";
import * as R from 'ramda';
import {debounce} from "../../utils/func";
import ReactGA, {OutboundLink} from "react-ga";
import pages from "../Checkout/pages";
import {DomainContext} from "../../contexts/DomainContext";




const Blank = ({children,title=null}) => {


    const [showPopup,setShowPopup] = useState(false);
    const domain = useContext(DomainContext);
    console.log(domain);
    const [page,setPage] = useState({
        title:null,
        content:null
    });

    useEffect(()=>{
        if(page.title==null){
            document.body.style.overflow='auto';
            setShowPopup(false);
        }else{
            document.body.style.overflow='hidden';
            setShowPopup(true);
        }
    },[page]);

    return (
        <div className="page">
            <img src={domain?.value?.logo} style={{
                maxWidth: "290px",
                "margin": "0 auto",
                "padding": "27px 0px",
                "cursor":"pointer"
            }} />
            <div className={" js-container "}>
                <div className="">
                    <div className="container__title title title_md">{title}</div>{/* search*/}
                </div>
                <div className="container__body">
                    {children}

                    <div className="layout__row">
                    <div className="layout__panel footer">
                        <div className={"link "+(domain?.value.id!=21 ? 'hidden' : '')}> {/* @Todo -> todo burası sayfalar panelden eklenmeye başladığında aktif edilecek, şuanlık geçici*/}
                            <a href="" onClick={(e)=>{
                                e.preventDefault();
                                setPage({
                                    title:"Hakkımızda",
                                    content:pages.hakkimizda
                                })
                            }}>Hakkımızda</a> |
                            <a href="" onClick={(e)=>{
                                e.preventDefault();
                                setPage({
                                    title:"İletişim Bilgileri",
                                    content:pages.iletisim
                                })
                            }}>İletişim Bilgileri</a> |
                            <a href="" onClick={(e)=>{
                                e.preventDefault();
                                setPage({
                                    title:"EFT ve Havale Bilgileri",
                                    content:pages.eft
                                })
                            }}>EFT ve Havale Bilgileri</a> |
                            <a href="" onClick={(e)=>{
                                e.preventDefault();
                                setPage({
                                    title:"Canlı Yayın Kullanıcı Lisans Anlaşması",
                                    content:pages.canli_yayin
                                })
                            }}>Canlı Yayın Kullanıcı Lisans Anlaşması</a>
                        </div>
                        <div className={"link powered_by"}>
                            Powered by <OutboundLink to={"https://subcharger.com"} className={"margin-none black_text"} target={"_blank"}>Subcharger</OutboundLink>
                        </div>
                    </div>

                </div>


                </div>
            </div>

            <div className={"modal modal_xs js-modal "+(showPopup ? 'visible' : '')}>
                <div className="modal__container js-modal-container">
                    <div className="modal__head"><button className="modal__action modal__action_close action js-modal-close"><i className="la la-angle-left " /></button>
                        <div className="modal__wrap">
                            <div className="modal__title">{page.title}</div>
                        </div><button className="modal__action action" style={{
                            "background": "white",
                            borderColor: "#F0F0F3"
                        }} onClick={(e)=>{
                            setPage({
                                title:null
                            });
                        }}><i className="la la-close " /></button>
                    </div>
                    <div className="modal__body" style={{
                        "overflow": "auto",
                        "overflowX": "hidden",
                        "height": "calc(60vh)",
                    }} dangerouslySetInnerHTML={{__html:page.content}}>
                        {/* invoice*/}

                    </div>
                </div>
            </div>

        </div>
    )
};

export default Blank;
