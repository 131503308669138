import {get, put, post} from "../utils/http";

export const getCollections = async (filters) => {
    try {
        return await get('/collections',filters);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getCollectionsExportUrl = async (filters) => {
    try {
        return await get('/collections/export',filters);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const putCollection = async (id,data) => {
    try {
        return await put('/collections/'+id,data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getCollectionSummary = async (filters) => {
    try {

        return await get('/collections/summary',filters);
    }catch (e) {
        throw e.response;
    }
};


export const chargeCollection = async (id) => {
    try {
        return await post('/collections/charge/'+id);
    }catch (e) {
        throw e.response;
    }
};

export const startCycleCollection = async () => {
    try {

        return await post('/collections/start-cycle');
    }catch (e) {
        throw e.response.data.error;
    }
};
