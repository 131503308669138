import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import config from '../../../config';
import Back from "../../layouts/back";
import Delete from "../../layouts/delete";
import {useUser} from "../../../hooks/useUsers";
import i18next from "i18next";



const UserForm = ({history,match,edit}) => {

    const sidebar = <SidebarChild history={history}/>;
    const context = useContext(ProfileContext);
    const {data,onSubmit,onDataChange,validation,onLoadChange,onDelete,actions} = useUser();

    useEffect(()=>{
        onDataChange({id:match.params.id});
        onLoadChange();

    },[]);

    const SendPassword = () => {
        return (
            <div className="tag blue c-p" style={{float:"right"}}  onClick={()=>{
                actions.resetPassword(data.email)
            }}>{i18next.t("Send Reset Password Email")}</div>

        );

    };

    return (
        <Layout sidebar={sidebar} title={i18next.t(edit ? "Update User" : "New User")}>
            <div className="panel">
                {
                    edit ?  <Back onClick={()=>{history.goBack()}} del={context.value.id!=data.id ? onDelete : null}  /> : <Back onClick={()=>{history.goBack()}}  />
                }

                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field  label={i18next.t("Full name")} value={data.name} type="text" onChange={(e)=>{
                                            onDataChange({
                                                name: e.target.value
                                            })
                                        }} errors={validation.name} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field  label={i18next.t("Email")} value={data.email} type="email" onChange={(e)=>{
                                            onDataChange({
                                                email: e.target.value
                                            })
                                        }} errors={validation.email} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field  label={i18next.t("Password")} value={data.password} type="password" onChange={(e)=>{
                                            onDataChange({
                                                password: e.target.value
                                            })
                                        }} errors={validation.password} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Field  label={i18next.t("Password Confirmation")} value={data.password_confirmation} type="password"  onChange={(e)=>{
                                            onDataChange({
                                                password_confirmation: e.target.value
                                            })
                                        }} errors={validation.rePassword} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="settings__foot">
                            <div className="settings__btns" style={{display:"block"}}>
                                <button className="settings__btn btn" onClick={()=>{
                                    onSubmit();
                                }}>{i18next.t(edit ? 'Update' : 'Save')}</button>
                                {edit ? <SendPassword /> : null}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(UserForm));
