import React, {useEffect, useState} from "react";
import {useAccounts} from "../../hooks/useAccounts";
import SideBarChildItem from "../../components/Theme/SideBarChildItem";
import i18next from "i18next";

const SidebarChild = ({history,data,onChangeFilters,onChangePage=f=>f,menu="active",setMenu=f=>f}) => {
    const path = history.location.pathname;
    useEffect(()=>{
        onChangeFilters({status:"ACTIVE"});
    },[]);
    return (
        <div className="sidebar__section">
            {/* widget*/}
            <div className="widget">
                <div className="widget__head">
                    <div className="widget__title title title_sm">{i18next.t("Subscription Overview")}</div>
                    <div className="widget__text">{i18next.t("Your subscriptions details")}</div>
                </div>
                <div className="widget__body">
                    {/* updates*/}
                    <div className="overview sidebar_child">
                        <SideBarChildItem active={menu==="active"} onClick={()=>{
                            setMenu("active");
                            onChangeFilters({status:"ACTIVE"});
                            onChangePage(1);
                            history.push("/subscriptions")
                        }}  description={data.active} title={i18next.t("Active")} iconColor="green" icon={"la la-user-check"} />
                        <SideBarChildItem active={menu==="invited"} onClick={()=>{
                            setMenu("invited");
                            onChangeFilters({status:"PAUSED"});
                            onChangePage(1);
                            history.push("/subscriptions")
                        }}  description={data.paused} title={i18next.t("Paused")} iconColor="yellow" icon={"la la-user-clock"} />
                        <SideBarChildItem active={menu==="invited_failed"} onClick={()=>{
                            setMenu("invited_failed");
                            onChangeFilters({status:"CANCELED"});
                            onChangePage(1);
                            history.push("/subscriptions")
                        }}  description={data.canceled} title={i18next.t("Canceled")} iconColor="red" icon={"la la-user-slash"} />
                        <SideBarChildItem active={menu==="all"} onClick={()=>{
                            setMenu("all");
                            onChangeFilters({status:null});
                            onChangePage(1);
                            history.push("/subscriptions")
                        }} description={data.all} title={i18next.t("All")} iconColor="blue" icon={"la la-users"} />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SidebarChild;
