import React, {createRef, useContext, useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import AppHOC from "../HOC/App";
import Blank from "../layouts/blank";
import sample from './sample';
import Back from "../layouts/back";
import Field from "../../components/Input/field";
import i18next from "i18next";
import Select from "../../components/Select";
import * as moment from "moment";
import Success from "./success";
import pages from "./pages";
import {debounce, error, moneyFormat, scrollToTop} from "../../utils/func";
import * as yup from 'yup';
import {post} from "../../utils/http";
import {ValidationError} from "yup";
import {useCheckout} from "../../hooks/useCheckout";
import {DomainContext} from "../../contexts/DomainContext";
import config from "../../config";

const Buy = ({history,i18,match}) => {

    const domain = useContext(DomainContext);
    const {plans,settings,isExistsSubscription,onChangeForm} = useCheckout();
    const productId = match.params.product_id;

    let product = plans.find(i=>i.uuid===productId);
    product = {...product,...settings?.plans?.["plan_"+product?.id]};
    if(!product){
        product = {
            title:null
        }
    }


    const [invoiceType,setInvoiceType] = useState("individual");
    const [submit,setSubmit] = useState(false);
    const [threedsPopup,setThreedsPopup] = useState(false);
    const [threedsContent,setThreedsContent] = useState(null);
    const [form,setForm] = useState({
        invoiceType:"individual",
        firstname:null,
        lastname:null,
        submit:false,

        // test data
        //cc_holdername:"muhammet us",
        //cc_number : 5400010000000004,
        //cc_exp_month:"01",
        //cc_exp_year:22,
        //cc_cvc:201
    });


    const [validation,setValidation] = useState({

    });

    const invoiceTypes = [
        {"key":"individual","value":"Bireysel"},
        {"key":"corporate","value":"Kurumsal"}
    ];


    useEffect(()=>{
        if(submit){
            let validSchema = {
                firstname: yup.string().required(),
                lastname: yup.string().required(),
                city: yup.string().required(),
                district: yup.string().required(),
                email: yup.string().required().email(),
                address: yup.string().required(),
                cc_holdername: yup.string().required(),
                cc_number: yup.string().required(),
                cc_exp_month: yup.string().required(),
                cc_exp_year: yup.string().required(),
                cc_cvc: yup.string().required(),
            };


            if(invoiceType=="individual"){
                validSchema = {
                    ...validSchema,
                    idNumber: yup.string().required(),
                }
            }else{
                validSchema = {
                    ...validSchema,
                    companyName: yup.string().required(),
                    taxOffice: yup.string().required(),
                    taxNumber: yup.string().required(),
                }
            }

            if(settings?.phone_input){
                validSchema = {
                    ...validSchema,
                    phone: yup.string().required(),
                }
            }

            let schema = yup.object().shape(validSchema);


            const isValidInput = async () => {
                try {
                    setValidation([])
                    const valid = await schema.validateSync(
                        form,
                        { abortEarly: false }
                    );
                    setForm({...form,submit:true});
                    setThreedsPopup(true);
                    setThreedsContent("Lütfen bekleyiniz..");

                    const res = await post('/checkout/payment',{
                        ...form,
                        plan_id:product.uuid,
                        holdername:form.cc_holdername,
                        number:form.cc_number,
                        exp_month:form.cc_exp_month,
                        exp_year:form.cc_exp_year,
                        cvc:form.cc_cvc
                    });
                    console.log(res);
                    setThreedsContent(res);






                } catch (err) {

                    if(err instanceof ValidationError){
                      let errors = [];
                        err.inner.map((item)=>{
                            errors = {...errors,[item.path]:"Lütfen geçerli bir değer giriniz."};
                       })
                        console.log(errors);
                        setValidation(errors)
                        setSubmit(false);
                        scrollToTop(400);
                    }
                }
            };

            isValidInput();
        }
    },[submit]);

    let months = [
        {"key":"01","value":"01"},
        {"key":"02","value":"02"},
        {"key":"03","value":"03"},
        {"key":"04","value":"04"},
        {"key":"05","value":"05"},
        {"key":"06","value":"06"},
        {"key":"07","value":"07"},
        {"key":"08","value":"08"},
        {"key":"09","value":"09"},
        {"key":"10","value":"10"},
        {"key":"11","value":"11"},
        {"key":"12","value":"12"},
    ];

    let years = [];
    for(let x = 0; x<= 10; x++){
        let year = moment().add(x,'year').format('YY');
        years.push({"key":year,"value":year})
    }

    useEffect(() => {
        const handler = event => {

            const data = JSON.parse(event.data);

            if(data.status){
                history.push("/checkout/success/"+data.checkoutId)
            }else{
                console.log("hata var hata!");
                setThreedsPopup(false);
                setSubmit(false);
                error(data.message);

            }
        }

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
    }, [])




    return (
        <Blank>

                <div className="layout">
                <div className="layout__row">
                    <div className="layout__panel layout__panel_x2 panel">
                        <div className="panel__head">
                            <div className="panel__group">
                                <Link to="/checkout" style={{color:"black",paddingBottom:'20px',display:'block'}}><i className="la la-angle-left " /> Geri</Link>
                                <div className="panel__title title">Fatura Bilgileri</div>
                            </div>
                        </div>
                        <div className="panel__body panel__body_bg">
                            <div className="invoice_address_body">
                                <div className="form__row">
                                    <div className="form__col" style={{flex:"100%"}}>
                                        <div className="field form__field">
                                            <Select onChange={(e)=>{
                                                setInvoiceType(e.target.value);
                                                setForm({...form,invoiceType:e.target.value});
                                            }} defaultValue={"Seçiniz"}  label={i18next.t("Fatura Tipi")} selected={form?.invoiceType} options={invoiceTypes} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Field onChange={(e)=>{
                                                setForm({...form,firstname:e.target.value});
                                            }} label="İsim" errors={validation?.firstname} value={form?.firstname} type="text" />
                                        </div>
                                    </div>
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Field onChange={(e)=>{
                                                setForm({...form,lastname:e.target.value});
                                            }} label="Soyisim" errors={validation?.lastname}  value={form?.lastname} type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Field onChange={(e)=>{
                                                window["email_text"] = e.target.value;
                                                setForm({...form,email:e.target.value});
                                                debounce(()=>{
                                                    onChangeForm({
                                                        email:window["email_text"],
                                                        plan: productId
                                                    });
                                                },500)();


                                            }} label="Email" errors={validation?.email} value={form?.email} type="text" />
                                            {
                                                isExistsSubscription ?
                                                    <div className="tag blue">
                                                        {i18next.t("It looks like you already have a pending invitation for account sign up. If you are not signing up for another plan, please check your email and sign up via invitation link.")}
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>
                                    {settings?.phone_input ?
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Field onChange={(e)=>{
                                                setForm({...form,phone:e.target.value});
                                            }} label="Telefon" errors={validation?.phone} value={form?.phone} type="text" />

                                        </div>
                                    </div>
                                        : null}
                                </div>
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Field onChange={(e)=>{
                                                setForm({...form,city:e.target.value});
                                            }} label="İl" errors={validation?.city} value={form?.city} type="text" />
                                        </div>
                                    </div>
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Field onChange={(e)=>{
                                                setForm({...form,district:e.target.value});
                                            }} label="İlçe" errors={validation?.district} value={form?.district} type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__col" style={{flex:"100%"}}>

                                            <Field className={"field__input"} onChange={(e)=>{
                                                setForm({...form,address:e.target.value});
                                            }} label="Adres" errors={validation?.address} value={form?.address} type="text" />

                                    </div>
                                </div>

                                {
                                    invoiceType=="individual" ?
                                        <div className="form__row">
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Field onChange={(e)=>{
                                                        setForm({...form,idNumber:e.target.value});
                                                    }} label="TC kimlik Numarası" errors={validation?.idNumber} value={form?.idNumber} type="text" />
                                                </div>
                                            </div>
                                        </div> :
                                        <>
                                            <div className="form__row">
                                                <div className="form__col" style={{flex:"100%"}}>
                                                    <div className="field form__field">
                                                        <Field onChange={(e)=>{
                                                            setForm({...form,companyName:e.target.value});
                                                        }} label="Şirket Adı" errors={validation?.companyName} value={form?.companyName} type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Field onChange={(e)=>{
                                                            setForm({...form,taxOffice:e.target.value});
                                                        }} label="Vergi Dairesi" errors={validation?.taxOffice} value={form?.taxOffice} type="text" />
                                                    </div>
                                                </div>
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Field onChange={(e)=>{
                                                            setForm({...form,taxNumber:e.target.value});
                                                        }} label="Vergi Numarası" errors={validation?.taxNumber} value={form?.taxNumber} type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }


                            </div>
                            <div className="credit_card_form">
                                <div className="panel__head">
                                    <div className="panel__group">
                                        <div className="panel__title title">Kredi Kartı Bilgileri</div>
                                    </div>
                                </div>
                                <div className="panel__body">
                                    <div className="credit_card_form">
                                        <div className="form__row">
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Field onChange={(e)=>{
                                                        setForm({...form,cc_holdername:e.target.value});
                                                    }}  value={form?.cc_holdername} errors={validation?.cc_holdername} label={"Kart üzerindeki ad"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__row">
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Field onChange={(e)=>{
                                                        setForm({...form,cc_number:e.target.value});
                                                    }} value={form?.cc_number} errors={validation?.cc_number} label={"Kart numaranız"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__row">
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Select defaultValue={"Seçiniz"} onChange={(e)=>{
                                                        setForm({...form,cc_exp_month:e.target.value});
                                                    }} selected={form?.cc_exp_month} errors={validation?.cc_exp_month} label="Ay" options={months} />
                                                </div>
                                            </div>
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Select defaultValue={"Seçiniz"}  onChange={(e)=>{
                                                        setForm({...form,cc_exp_year:e.target.value});
                                                    }} selected={form?.cc_exp_year} errors={validation?.cc_exp_year} label="Yıl" options={years} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__row">
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Field onChange={(e)=>{
                                                        setForm({...form,cc_cvc:e.target.value});
                                                    }} value={form?.cc_cvc} errors={validation?.cc_cvc} label={"Cvc"} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"form_row hidden"}>
                                            <div className={"form_col"}>
                                                <div style={{width:"100%",display:"block",margin:"10px 0px"}}>
                                                    <label className="switch auth__switch" style={{
                                                        "width": "fit-content",
                                                        "float": "left"
                                                    }}><input className="switch__input"  type="checkbox"  value={true} onChange={(e) => {
                                                    }}/><span className="switch__content"/></label>
                                                    <span style={{
                                                        fontSize: "14px",
                                                        color: "#8181A5",
                                                        marginLeft: "11px"
                                                    }}>Mesafeli Satış Sözleşmesini okudum, kabul ediyorum.</span>
                                                </div>

                                            </div>
                                        </div>

                                        {settings?.contracts && settings?.contracts.map((item)=>{
                                            return (
                                                <>
                                                    <h4>{item.title}</h4>
                                                    <div className={"contract"} dangerouslySetInnerHTML={{__html:item.description}} />
                                                </>
                                            )
                                        })}


                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className="layout__row">
                            <div className="layout__panel layout__panel_x1">
                                <button className="btn" onClick={()=>{
                                    setSubmit(true);
                                }} style={{margin:"10px auto",width:"100%"}}>Satın almayı tamamla</button>
                            </div>
                        </div>
                    </div>

                    <div className="layout__panel panel" style={{height:"min-content"}}>
                        <div className="panel__head panel__head_line">
                            <div className="panel__group">
                                <div className="panel__title title">Sepetiniz</div>
                            </div>
                            <div className="panel__group action-group">
                                <Link to={"/checkout"} className="action"><i className="la la-trash "></i></Link>
                            </div>
                        </div>
                        <div className="panel__body panel__body_bg">
                            <div className="data data_grid">
                                <div className="data__container">

                            <div className="data__body">
                                <div className="data__item" style={{maxHeight:'initial'}}>
                                    <div className="data__row">
                                        <div className="data__cell">
                                            <div className="data__main">
                                                <div className="data__preview data__preview_large" style={{marginTop:'-13px'}}><img width={200} src={config.storage_url+product.image_url}/></div>
                                                <div className="data__wrap">
                                                    <div className="data__content"><strong>{product.title}</strong></div>
                                                    <div className="data__label" dangerouslySetInnerHTML={{__html: product.description}}/>
                                                    <span style={{
                                                        fontSize: "14x",
                                                        textDecoration: "line-through",
                                                        display:"block",
                                                        color:"#a59d9d",
                                                        marginTop:"20px"
                                                    }}>{moneyFormat(product.sale_price,'TRY')}</span><strong style={{fontSize:"24px"}}>{moneyFormat(product.price,'TRY')}</strong>
                                                    {domain.value.public_settings?.basket_price==="tax_included" ? <div className="data__label">(KDV Dahil)</div> : null}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={"modal modal_sm js-modal "+(threedsPopup ? 'visible' : '')}>
                <div className="modal__container js-modal-container">
                    <div className="modal__head">
                        <div className="modal__wrap">
                            <div className="modal__title">3DS Ödeme Sayfası</div>
                        </div><button className="modal__action action" style={{
                            "background": "white",
                            borderColor: "#F0F0F3"
                        }} onClick={(e)=>{
                            setThreedsPopup(false);
                            setForm({...form,submit:false})
                            setSubmit(false);
                        }}><i className="la la-close " /></button>
                    </div>
                    <div className="modal__body" style={{
                        "overflow": "auto",
                        "overflowX": "hidden",
                        "height": "calc(50vh)",
                        maxWidth: "calc(100vh)",
                    }}>
                        <iframe style={{width:'100%',height: "100%"}} srcDoc={`${threedsContent}`} />
                    </div>
                </div>
            </div>
        </Blank>
    );
}

export default withRouter (AppHOC(Buy));
