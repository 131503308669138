import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import Select from "../../../components/Select";
import {useSettings} from "../../../hooks/useSettings";
import Input from "../../../components/Input";
import i18next from "i18next";
import {chargeTypes} from "../../../utils/func";

const SettingsCollections = ({history}) => {


    const sidebar = <SidebarChild history={history}/>;
    const numbers = [
        {"key":1,"value":"1"},
        {"key":2,"value":"2"},
        {"key":3,"value":"3"},
        {"key":4,"value":"4"},
        {"key":5,"value":"5"},
        {"key":6,"value":"6"},
        {"key":7,"value":"7"},
        {"key":8,"value":"8"},
    ];

    const times = [
        {"key":"hour","value":i18next.t("Hour")},
        {"key":"day","value":i18next.t("Day")},
    ];


    const trueFalse = [
        {"key":"CollectDay","value":i18next.t("Collect Day")},
        {"key":"CollectNow","value":i18next.t("Collect Now")}
    ];

    const {data,onDataChange,onSubmit,onGroupChange} = useSettings();
    const value = {
        collect_day:null,
        retry_charge_attempt_interval: null,
        retry_charge_attempt_time: null,
        maximum_retry_collection: null,
        collect_once_payment_period: null,
        maximum_amount_payment_period: null,
        ...data
    };
    useEffect(()=>{
        onGroupChange("collections");
    });





    return (
        <Layout sidebar={sidebar} title={i18next.t("Collections")}>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">
                                            <div className="form__row">
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Select onChange={(e)=>{
                                                            onDataChange({...data,collection_policy:e.target.value});
                                                        }} label={i18next.t("Collection Policy")} selected={value.collection_policy} options={chargeTypes} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form__row">
                                                {value?.collection_policy==="BatchCharge" ?
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Select onChange={(e)=>{
                                                            onDataChange({...data,collect_day:e.target.value});
                                                        }} label={i18next.t("Collect day of each month")} selected={value.collect_day} description={i18next.t("Collect day of each month description")} options={numbers.slice(0,5)} />
                                                    </div>
                                                </div> : null }
                                                {value?.collection_policy==="BeforeCharge" ?
                                                    <div className="form__col">
                                                        <div className="field form__field">
                                                            <Select child={ (
                                                                <Input onChange={(e)=>{
                                                                    onDataChange({collect_day:e.target.value});
                                                                }} value={value?.collect_day} type={"number"} style={{width:'20%',float:'left'}}  />
                                                            )
                                                            }
                                                                    style={{width:'80%'}}
                                                                    label={i18next.t("How many days before the invoice will be collected?")} disabled={true} selected={"day"} options={times} />
                                                        </div>
                                                    </div> : null }
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Select child={ (
                                                                <Input onChange={(e)=>{
                                                                    onDataChange({...data,retry_charge_attempt_interval:e.target.value});
                                                                }} value={value.retry_charge_attempt_interval} type={"number"} style={{width:'20%',float:'left'}} description={i18next.t("Retry charge attempt after description")} />
                                                            )
                                                        }
                                                            style={{width:'80%'}}
                                                            onChange={(e)=>{
                                                            onDataChange({...data,retry_charge_attempt_time:e.target.value});
                                                        }} label={i18next.t("Retry charge attempt after")} selected={value.retry_charge_attempt_time} description={i18next.t("Retry charge attempt after description")} options={times} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Select onChange={(e)=>{
                                                            onDataChange({...data,maximum_retry_collection:e.target.value});
                                                        }} label={i18next.t("Maximum retry for a collection")} selected={value.maximum_retry_collection} description={i18next.t("Collect day of each month description")} options={numbers.slice(0,4)} />
                                                    </div>
                                                </div>
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Select onChange={(e)=>{
                                                            onDataChange({...data,collect_once_payment_period:e.target.value});
                                                        }} label={i18next.t("Collect only once in a payment period")} selected={value.collect_once_payment_period} description={i18next.t("Collect only once in a payment period description")} options={trueFalse} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Field onChange={(e)=>{
                                                            onDataChange({...data,maximum_amount_payment_period:e.target.value});
                                                        }} label={i18next.t("Maximum amount to collect in a payment period")} value={value.maximum_amount_payment_period} type="number" description={i18next.t("Maximum amount to collect in a payment period")} />
                                                    </div>
                                                </div>
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Field onChange={(e)=>{
                                                            onDataChange({...data,charge_time:e.target.value});
                                                        }} label={i18next.t("What time should the collections start?")} value={value.charge_time} type="time" description={i18next.t("What time should the collections start? description")} />
                                                    </div>
                                                </div>
                                            </div>

                        </div>
                        <div className="settings__foot">
                            <div className="settings__btns">
                                <button className="settings__btn btn" onClick={()=>{
                                    onSubmit();
                                }}>{i18next.t("Update")}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(SettingsCollections));
