import React, {useContext, useEffect, useState} from "react";
import i18next from "i18next";
import {useAccounts} from "../../hooks/useAccounts";
import {ConfirmButton} from "../../components/Button/confirm";
import {ProfileContext} from "../../contexts/ProfileContext";
import {call} from "ramda";

const ActionMenu = ({activeMenu,actions,summary,dataLength,activeMonth,setMenu=f=>f}) => {

    const context = useContext(ProfileContext);

    if(dataLength === 0){
        return (
            <>
            </>
        );
    }


    if( activeMenu === "unpaid" && context.value.isAdmin && summary.next_charge_cycle===null && context.value?.company?.current_collection_cycle===activeMonth && context.value?.company?.collection_policy=="BatchCharge"){
        return (
            <>
                <ConfirmButton hideSubmit={summary.available_failed_invoices <= 0} onClick={()=>{
                    actions.startCycle()
                    setMenu("scheduled");
                }} title={i18next.t("Are you sure?")} message={
                    <>
                        <table className="sweet_table" >
                            <tbody>
                            <tr>
                                <td>{i18next.t('Collectible invoices')}</td>
                                <td>{summary.available_failed_invoices}</td>
                            </tr>
                            <tr>
                                <td>{i18next.t('Uncollectible invoices')}</td>
                                <td>{summary.not_available_failed_invoices}</td>
                            </tr>

                            </tbody>
                        </table>
                    </>
                }>
                    <i className="la la-hourglass "></i> {i18next.t("Start Cycle")}
                </ConfirmButton>
            </>
        );
    }



    return (
        <>
        </>
        );
}

export default ActionMenu;
