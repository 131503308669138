import {get, put , post} from "../utils/http";

export const savePlan = async (data) => {
    try {
        return await post('/plans',data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const updatePlan = async (id,data) => {
    try {
        return await put('/plans/'+id,data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getPlans = async (filters) => {
    try {
        return await get('/plans',filters);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getPlan = async (id) => {
    try {
        return await get('/plans/'+id);
    }catch (e) {
        throw e.response.data.error;
    }
}




