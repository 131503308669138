import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import Table from "../../../components/Table/table";
import Head from "../../../components/Table/head";
import Avatar from "react-avatar";
import getStatusColorByAccount, {dateFormat, statusAccount, statusShow} from "../../../utils/func";
import {DropdownMenu} from "../../../components/Button";
import Pagination from "../../../components/Table/pagination";
import {useUsers} from "../../../hooks/useUsers";
import i18next from "i18next";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import {useEmailTemplate} from "../../../hooks/useEmailTemplate";
import config from "../../../config";

const EmailTemplates = ({history,location}) => {

    const context = useContext(ProfileContext);
    const sidebar = <SidebarChild history={history}/>;
    const { data , onChangePagination,totalPage,page , onChangeFilters} = useEmailTemplate();
    const [activeFilterTab, setActiveFilterTab] = useState("all");
    useEffect(()=>{
        onChangeFilters({});
    },[location]);

    return (
       <>
           <Layout sidebar={sidebar} title={i18next.t("Email Templates")}>
               <div>
                   {/* panel*/}
                   <div className="panel js-panel">
                       <div className="panel__head panel__head_line">
                           <div className="panel__group btn-group btn-group_tabs">
                               {
                                   [
                                       {key:"all",value:i18next.t("All")},
                                       {key:"transaction",value:i18next.t("Transaction")},
                                       {key:"webhook",value:i18next.t("Webhook")},
                                       {key:"integration",value:i18next.t("Integration")},
                                       {key:"account",value:i18next.t("Müşteriler")},
                                   ].map((item, index) => {
                                       const number = index + 1;
                                       return (
                                           <button key={"filter_tab" + number}
                                                   className={"btn btn_light btn_icon js-panel-btn " + (activeFilterTab === item.key || (activeFilterTab === item.key && index === 0) ? 'active' : '')}
                                                   onClick={() => {
                                                       setActiveFilterTab(item.key);
                                                       onChangeFilters({
                                                           group:item.key
                                                       });
                                                   }}>{item.value}</button>
                                       )
                                   })
                               }
                           </div>
                       </div>
                       <div className="panel__body">

                           <div className="panel__tab js-panel-tab" style={{display: 'block'}}>

                               {/* data*/}
                               <div className="data data_list">

                                   <div className="data__container">
                                       <Table head={
                                           <>
                                               <Head extraClassName="data__cell_md">{i18next.t("Template")}</Head>
                                               <Head extraClassName="data__cell_md">{i18next.t("Title")}</Head>
                                               <Head extraClassName="data__cell_md" onClick={onChangeFilters} sortKey={"email"}>{i18next.t("Status")}</Head>
                                               <Head extraClassName={"data__cell_xs text-right"}>{i18next.t("Actions")}</Head>
                                           </>
                                       }
                                       minimal={true}
                                       >
                                           {
                                               data.map((v,i)=>{
                                                   return (
                                                       <div className="data__item" key={i} >
                                                           <div className="data__row">
                                                               <div className="data__cell data__cell_md c-p"  onClick={()=>{
                                                                   history.push('/settings/email-template/'+v.id);
                                                               }}>
                                                                   <div className="data__main">
                                                                       <div className="data__wrap">
                                                                           <div className="data__content"><strong>{v.name}</strong></div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                               <div className="data__cell data__cell_md">
                                                                   <div className="data__content">{v.title}</div>
                                                               </div>
                                                               <div className="data__cell data__cell_md">
                                                                   <div className="data__content">
                                                                       {statusShow(v.is_active,v.is_active ? 'Active' : 'Passive')}
                                                                   </div>
                                                               </div>
                                                               <div className="data__cell data__cell_xs text-right">
                                                                   <div className="data__content">
                                                                       <div className="action action_stroke" onClick={()=>{
                                                                           history.push('/settings/email-template/'+v.id);
                                                                       }}>
                                                                           <div className={"c-p"}>
                                                                               <i className="la la-ellipsis-h "/>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   );
                                               })
                                           }
                                       </Table>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="panel__foot">
                           <Pagination totalPage={totalPage}  activePage={page} onChange={onChangePagination}/>
                       </div>
                   </div>
               </div>
           </Layout>
       </>
    );
}

export default withRouter (AppHOC(EmailTemplates));
