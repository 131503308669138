import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import SidebarChild from "./sidebarChild";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import Table from "../../components/Table/table";
import Head from "../../components/Table/head";
import Avatar from "react-avatar";
import {useAccounts} from "../../hooks/useAccounts";
import Pagination from "../../components/Table/pagination";
import getStatusColorByAccount, {dateFormat, getStatusColorByCollection, statusAccount} from "../../utils/func";
import {DropdownMenu} from "../../components/Button";
import i18next from "i18next";
import * as R from 'ramda';
import ReactTooltip from "react-tooltip";
import ActionMenu from "./action_menu";
import ProgressIndicator from "../../components/Theme/ProgressIndicator";
import {ConfirmButton} from "../../components/Button/confirm";
import {Trans} from "react-i18next";
const Accounts = ({history,i18}) => {

    const [sortMenu,setSortMenu] = useState(false);
    const [actionOpen,setActionOpen] = useState(false);
    const [status,setStatus] = useState(null);
    const [activeBtn,setActiveBtn] = useState(null);
    const [menu,setMenu] = useState("active");
    const [currentAccount,setCurrentAccount] = useState(-1);

    const { data , onChangePagination,totalPage,page , onChangeFilters , summary ,onChange,setRefresh , loading , onExport,onChangePage , actions} = useAccounts({
        onLoadData: () => {
            ReactTooltip.rebuild();
            setActiveBtn(null);
        }
    });

    useEffect(()=>{
        ReactTooltip.rebuild();
    },[activeBtn]);
    const sidebar = <SidebarChild history={history} menu={menu} setMenu={setMenu} onChangePage={onChangePage}  onChangeFilters={onChangeFilters} data={summary}/>;

    const GetActions = ({item}) => {
        if(item.actions.length == 0){
            return (
                <>
                    {i18next.t("No Available Actions")}
                </>
            )
        }
        return (
            item.actions.map((a,i)=>{
                let title = i18next.exists("message." + a + "_title") ? <Trans i18nKey={"message." + a + '_title'}/> : i18next.t("Are you sure?");
                let message = i18next.exists("message." + a) ? <Trans i18nKey={"message." + a}/> : null;
                return (
                    <ConfirmButton onClick={async ()=>{
                        await onChange(item.id,{
                            status:a.toUpperCase(),
                        },setRefresh)
                        setActiveBtn(null);
                    }} title={title} styles={"action action_stroke action_btn "+getStatusColorByCollection(a)} message={message}>
                        {i18next.t(a.toUpperCase())}
                    </ConfirmButton>
                );
            })

        )
    };


    return (
        <Layout sidebar={sidebar} title={i18next.t("Accounts")} onSearch={onChangeFilters}>

            <div>
                {/* panel*/}
                <div className="panel js-panel">
                    <div className="panel__head panel__head_line">
                        <div className="panel__group btn-group text-right btn-group_tabs action_menu_group">
                            <button className="btn btn_light btn_icon " onClick={()=>{
                                history.push("/account/create");
                            }}><i className="la la-pencil-alt "></i> {i18next.t("New Account")}</button>


                            <button className="btn btn_light btn_icon" onClick={()=>{
                                onExport();
                            }}><i className="la la-file-excel "></i> {i18next.t("Export")}</button>
                            <ActionMenu activeMenu={menu} actions={actions} dataLength={data.length}  summary={summary} />
                        </div>

                        {/* btn group*/}
                        <div className="panel__group btn-group btn-group_tabs" hidden>
                            <button className={"btn btn_light btn_icon js-panel-btn "+(status===null ? 'active' : null)} onClick={()=>{
                                setStatus(null)
                                onChangeFilters({status:null})


                            }}>{i18next.t("All")}</button>
                            <button className={"btn btn_light btn_icon js-panel-btn "+(status==="OPEN" ? 'active' : null)} onClick={()=>{
                                setStatus("OPEN")
                                onChangeFilters({status:"OPEN"})

                            }}>{i18next.t("Active")}</button>
                            <button className={"btn btn_light btn_icon js-panel-btn "+(status==="INVITED" ? 'active' : null)} onClick={()=>{
                                setStatus("INVITED")
                                onChangeFilters({status:"INVITED"})

                            }}>{i18next.t("Invited")}</button>
                            <button className={"btn btn_light btn_icon js-panel-btn "+(status==="INVITED_FAILED" ? 'active' : null)} onClick={()=>{
                                setStatus("INVITED_FAILED")
                                onChangeFilters({status:"INVITED_FAILED"})

                            }}>{i18next.t("Invited Failed")}</button>
                            <button className={"btn btn_light btn_icon js-panel-btn "+(status==="CLOSED" ? 'active' : null)} onClick={()=>{
                                setStatus("CLOSED")
                                onChangeFilters({status:"CLOSED"})

                            }}>{i18next.t("Closed")}</button>
                        </div>

                        <div className="panel__group" hidden>
                            <div className={"sort js-sort "+(sortMenu ? 'open' : '')}>
                                <div className="sort__head js-sort-head" onClick={()=>{setSortMenu(!sortMenu)}}>
                                    <div className="sort__icon"><i className="la la-filter " /></div>
                                    <div className="sort__label">sort:</div>
                                    <div className="sort__selected js-sort-selected">A-Z</div>
                                    <div className="sort__arrow"><i className="la la-angle-down " /></div>
                                </div>
                                <div className="sort__dropdown js-sort-dropdown">
                                    <div className="sort__title">Sort projects by</div>
                                    <div className="sort__items">
                                        <div className="sort__item"><label className="switch sort__switch js-sort-switch"><input className="switch__input" type="radio" name="sort" defaultChecked="checked" /><span className="switch__content">A-Z</span></label></div>
                                        <div className="sort__item"><label className="switch sort__switch js-sort-switch"><input className="switch__input" type="radio" name="sort" /><span className="switch__content">Budget</span></label></div>
                                        <div className="sort__item"><label className="switch sort__switch js-sort-switch"><input className="switch__input" type="radio" name="sort" /><span className="switch__content">Assigned Tasks</span></label></div>
                                        <div className="sort__item"><label className="switch sort__switch js-sort-switch"><input className="switch__input" type="radio" name="sort" /><span className="switch__content">Progress</span></label></div>
                                        <div className="sort__item"><label className="switch sort__switch js-sort-switch"><input className="switch__input" type="radio" name="sort" /><span className="switch__content">Date Created</span></label></div>
                                    </div>
                                    <div className="sort__foot"><button className="sort__btn btn btn_light js-sort-apply" onClick={()=>{setSortMenu(false);}} >Apply</button></div>
                                </div>
                                <div className="sort__backdrop backdrop js-sort-backdrop" onClick={()=>{setSortMenu(false);}} />
                            </div>
                        </div>
                    </div>
                    <div className="panel__body">
                        <div className="panel__tab js-panel-tab" style={{display: 'block'}}>
                            {/* data*/}
                            <div className="data data_list">
                                <div className="data__container account_table_list">
                                   <Table head={
                                           <>
                                               <Head extraClassName="data__cell_xl" onClick={onChangeFilters} sortKey={"company"}>{i18next.t("Name")}</Head>
                                               <Head extraClassName="data__cell_md" onClick={onChangeFilters} sortKey={"contact_email"}>{i18next.t("Email")}</Head>
                                               <Head extraClassName="data__cell_md">{i18next.t("Status")}</Head>
                                               <Head extraClassName={"data__cell_sm text-center"}>{i18next.t("Actions")}</Head>
                                           </>
                                       }
                                   loading={loading}>
                                       {
                                           data.map((v,i)=>{
                                               return (
                                                   <>
                                                   <div className="data__item" key={i} onMouseEnter={()=>{
                                                       setCurrentAccount(i);
                                                       console.log("hover");
                                                   }} onMouseLeave={()=>{
                                                       if(currentAccount===i){
                                                           setCurrentAccount(-1);
                                                       }
                                                   }}>
                                                       <div className={"data__row "+(activeBtn===i ? "action_menu_button_list" : null)+""}>
                                                           {activeBtn === i ?
                                                               <div
                                                                   className="data__cell data__cell_lg action_btn_list float-div">
                                                                   <GetActions item={v}/>
                                                               </div>
                                                               :
                                                               <>
                                                                   <div className="data__cell data__cell_xl c-p" onClick={()=>{
                                                                       history.push('/account/detail/'+v.id);
                                                                   }}>
                                                                       <div className="data__main">
                                                                           <div className="data__avatar">
                                                                               <Avatar name={v.full_name}
                                                                                       maxInitials={2} round={true}
                                                                                       size={50}/>
                                                                           </div>
                                                                           <div className="data__wrap text_wrap_email">
                                                                               <div className="data__content">
                                                                                   <strong>{v.full_name}</strong></div>
                                                                               <div
                                                                                   className="data__label">{v.company}</div>
                                                                           </div>

                                                                       </div>
                                                                       <div style={{width:"28%",maxHeight:'30px',minHeight:'30px',position:"absolute"}}>
                                                                            <ProgressIndicator active={currentAccount} that={i} values={v.progressIndicator} />
                                                                       </div>
                                                                   </div>
                                                                   <div className="data__cell data__cell_md">
                                                                       <div className="data__wrap text_wrap_email">
                                                                           <div
                                                                               className="data__content">{v.contact_email}</div>
                                                                           <div className="data__label">{R.isNil(v.contact_phone_number) ? "-" : v.contact_phone_number}</div>
                                                                       </div>
                                                                   </div>

                                                                   <div className="data__cell data__cell_md">
                                                                       <div className="data__content">
                                                                           {statusAccount(v.status,dateFormat(v.user?.last_seen,"DD MMMM YYYY HH:mm"))}
                                                                       </div>
                                                                   </div>

                                                               </>
                                                           }


                                                           <div className="data__cell data__cell_sm text-center action_btn_row">
                                                               <div className="data__content">
                                                                   <DropdownMenu currentActive={activeBtn} active={i} onClick={()=>{
                                                                       if(activeBtn!=i)
                                                                           setActiveBtn(i);
                                                                       else
                                                                           setActiveBtn(null);
                                                                   }}>
                                                                   </DropdownMenu>
                                                               </div>
                                                           </div>

                                                       </div>


                                                   </div>
                                               </>
                                               );
                                           })
                                       }
                                   </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel__foot">
                        <Pagination totalPage={totalPage}  activePage={page} onChange={onChangePagination}/>
                    </div>
                </div>
            </div>


        </Layout>
    );
}

export default withRouter (AppHOC(Accounts));
