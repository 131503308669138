import {get, put} from "../utils/http";

export const getSettings = async (group) => {
    try {
        return await get('/settings/'+group);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const putSettings = async (group,data) => {
    try {
        return await put('/settings/'+group,data);
    }catch (e) {
        throw e.response.data.error;
    }
}