import {useContext, useEffect, useState} from "react";
import {getUtil, postUtil} from "../api/util";


export const usePublicPayment = () => {
    const [minAmount, setMinAmount] = useState(0);
    const [token, setToken] = useState(null);
    const [tokenDetail, setTokenDetail] = useState(0);

    useEffect(() => {
        const getData = async () => {
            const res = await getUtil('min-payment-amount');
            await setMinAmount(res.amount)
        };
        getData();

    }, []);

    useEffect(()=>{
        const getData = async () => {
            try{
                const res = await postUtil('temporary-token',{
                    token:token,
                    type:"credit_card_add_link"
                });
                await setTokenDetail(res)
            }catch (e) {
                setTokenDetail(false);
            }

        };
        if(token!=null){
            getData();
        }

    },[token]);



    return {
        minAmount,
        tokenDetail,
        onChangeToken: (v) => {
            setToken(v);
        }
    }
};
