import React from "react";
import * as R from 'ramda';

const SideBarChildItem = ({title='',description='',child=null,icon='',iconColor='blue',onClick=f=>f,active=false}) => {
  return (
      <div className={"overview__item " +(active ? 'active' : '')} onClick={()=>{
          onClick()
      }}>
          <div className="overview__row">
              <div className="overview__col">
                  <div className="overview__value">{title}</div>
                  {!R.empty(description) ? <div className="overview__label">{description}</div> : ''}
                  {child}
              </div>
              <div className="overview__col">
                  <div className={"overview__preview "+iconColor}>
                      {
                          !R.empty(icon) ? <i className={icon}/> : ''
                      }
                  </div>
              </div>
          </div>
      </div>
  ) ;
};

export default SideBarChildItem;
