import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import config from '../../../config';
import Back from "../../layouts/back";
import Select from "../../../components/Select";
import {useSettings} from "../../../hooks/useSettings";
import i18next from "i18next";
import Input from "../../../components/Input";


const General = ({data,onChange}) => {
    const val = {e_invoice:null,...data};
    const eInvoiceProvider = [
        {"key":false,"value":i18next.t("Don't Use")},
        {"key":"Parasut","value":"Parasut"}
    ];

    const invoiceSendDelay = [
        {"key":"now","value":i18next.t("Instantly")},
        {"key":"later","value":i18next.t("Later")}
    ];

    const times = [
        {"key":"hour","value":i18next.t("Hour")},
        {"key":"day","value":i18next.t("Day")},
    ];
    return (
        <>
            <div className="form__row">
                <div className="form__col">
                    <div className="field form__field">
                        <Select onChange={(e)=>{
                            onChange({...val,e_invoice:e.target.value});
                        }} label={i18next.t("Use e invoice")} selected={val.e_invoice} description={i18next.t("Use e invoice description")} options={eInvoiceProvider} />
                    </div>
                </div>
            </div>

            <div className="form__row">
                <div className="form__col">
                    <div className="field form__field">
                        <Select onChange={(e)=>{
                            onChange({...val,e_invoice_scheduling:e.target.value});
                        }} label={i18next.t("Bill Scheduling")} selected={val.e_invoice_scheduling} options={invoiceSendDelay} />
                    </div>
                </div>
            </div>

            {val.e_invoice_scheduling=="later" ?
            <div className="form__row">
                <div className="form__col">
                    <div className="field form__field">
                        <Select child={ (
                            <Input onChange={(e)=>{
                                onChange({...val,e_invoice_delay:e.target.value});
                            }} value={val.e_invoice_delay} type={"number"} style={{width:'20%',float:'left'}}  />
                        )
                        }
                                style={{width:'80%'}}
                                label={i18next.t("How many days after the invoice has been paid?")} disabled={true} selected={"day"} options={times} />
                    </div>
                </div>
            </div> : null}
        </>
    );
};

const Parasut = ({data,onChange}) => {
    const val = {email:null,password:null,client_id:null,company_id:null,cash_account_id:null,...data};
    return (
        <>
            <Field
                value={val.email}
                label={i18next.t("Email")}
                onChange={(e)=>{
                    onChange({...val,email:e.target.value});
                }}
            />
            <Field
                value={val.password}
                label={i18next.t("Password")}
                onChange={(e)=>{
                    onChange({...val,password:e.target.value});
                }}
            />
            <Field
                value={val.client_id}
                label={i18next.t("Client ID")}
                onChange={(e)=>{
                    onChange({...val,client_id:e.target.value});
                }}
            />
            <Field
                value={val.company_id}
                label={i18next.t("Company ID")}
                onChange={(e)=>{
                    onChange({...val,company_id:e.target.value});
                }}
            />
            <Field
                value={val.cash_account_id}
                label={i18next.t("Cash Account ID")}
                onChange={(e)=>{
                    onChange({...val,cash_account_id:e.target.value});
                }}
            />
        </>
    );
};

const InvoiceDetail = ({history,match}) => {

    const sidebar = <SidebarChild history={history}/>;

    const {data,onGroupChange,onSubmit,onDataChange,onGoBackChange} = useSettings();

    useEffect(()=>{
        onGroupChange("invoice_"+match.params.key);
        onGoBackChange(true);
    });


    const Provider = () => {
        if(match.params.key==="general")
            return <General data={data} onChange={onDataChange} />
        if(match.params.key==="parasut")
            return <Parasut data={data} onChange={onDataChange} />
    }

    return (
        <Layout sidebar={sidebar} title={"Invoice > "+match.params.key}>
            <div className="panel">
                <Back onClick={()=>{history.goBack()}} />
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">

                            <Provider />

                            <button className="settings__btn btn" onClick={()=>{
                                onSubmit();
                            }}>{i18next.t("Save")}</button>

                        </div>

                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(InvoiceDetail));
