import {get, post} from "../utils/http";
import {has} from "ramda";

export const forgotPassword = async (data) => {
    try {
        return await post('/auth/forgot-password', data);
    }catch (e) {
        throw e.response.data.error.message;
    }
}

export const login = async (data) => {
    try {
        return await post('/auth', data);
    }catch (e) {
        throw e.response.data.error.message;
    }
}

export const register = async (data) => {
    try {
        return await post('/auth/register', data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const resetPassword = async (data) => {
    try {
        return await post('/auth/reset-password', data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getInviteDetail = async (hash) => {
    try {
        return await post('/auth/invite',{hash:hash});
    }catch (e) {
        throw e.response.data.error;
    }
}

export const registerUser = async (data) => {
    try {
        return await post('/auth/register/user',data);
    }catch (e) {
        throw e.response.data.error;
    }
}
