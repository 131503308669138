import React from "react";
import Delete from "./delete";
import * as R from 'ramda';
import AppHOC from "../HOC/App";
import i18next from "i18next";
const Back = ({onClick, del=null,right=null}) => {
    return (
        <div className="panel__head">
            <button className="panel__back back" onClick={onClick}><i className="la la-angle-left " />{i18next.t("Back")}</button>
            {R.is(Function,del) ? <Delete onClick={del} /> : null}
            {right}
        </div>
    );
}

export default AppHOC( Back )
