import {get, post, put} from "../utils/http";

export const getPlans = async () => {
    try {
        return await get('/checkout/plans');
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getSubscriptionByEmail = async (data) => {
    try {
        return await post('/checkout/check-exists-subscription',data);
    }catch (e) {
        throw e.response.data.error;
    }
}
