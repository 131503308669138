import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import {SideBarBottom, SidebarChild} from "./sidebarChild";
import Field from "../../components/Input/field";
import Select from "../../components/Select";
import {useSettings} from "../../hooks/useSettings";
import {useProfile} from "../../hooks/useProfile";
import {useNotification} from "../../hooks/useUsers";
import {not} from "ramda";
import i18next from "i18next";

const NotificationsSetting = ({history}) => {

    const sidebar = <SidebarChild history={history}/>;
    const {notifications,onSubmit,onChange} = useNotification();
    const value = {
        send_daily_reports:null,
        send_unpaid_reports:null,
        send_paid_reports:null,
        ...notifications
    };

    const trueFalse = [
        {"key":true,"value":"True"},
        {"key":false,"value":"False"}
    ];

    return (
        <Layout sidebar={sidebar} sidebarBottom={<SideBarBottom history={history}/>} title={i18next.t("Notification settings")}>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">


                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Select onChange={(e)=>{
                                            onChange({send_daily_reports:e.target.value});
                                        }} label={i18next.t("Send Daily Report")} selected={value.send_daily_reports} description={i18next.t("Send Daily Report Description")} options={trueFalse} />
                                    </div>
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Select onChange={(e)=>{
                                            onChange({send_unpaid_reports:e.target.value});
                                        }} label={i18next.t("Send Unpaid Report")} selected={value.send_unpaid_reports} description={i18next.t("Send Unpaid Report Description")} options={trueFalse} />
                                    </div>
                                </div>
                            </div>

                            <div className="form__row">
                                <div className="form__col">
                                    <div className="field form__field">
                                        <Select onChange={(e)=>{
                                            onChange({send_paid_reports:e.target.value});
                                        }} label={i18next.t("Send Paid Report")} selected={value.send_paid_reports} description={i18next.t("Send Paid Report Description")} options={trueFalse} />
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="settings__foot">
                            <div className="settings__btns">
                                <button className="settings__btn btn" onClick={()=>{
                                    onSubmit()
                                }}>{i18next.t("Update")}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(NotificationsSetting));
