import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import Select from "../../../components/Select";
import {useSettings} from "../../../hooks/useSettings";
import Input from "../../../components/Input";
import i18next from "i18next";
import Back from "../../layouts/back";

const SettingsGeneral = ({history}) => {


    const sidebar = <SidebarChild history={history}/>;

    const {data,onDataChange,onSubmit,onGroupChange} = useSettings();
    const value = {
        payment_provider:null,
        ...data
    };

    const paymentProviders = [
        {"key":"Iyzico","value":"Iyzico"},
        {"key":"Stripe","value":"Stripe"},
    ];
    const times = [
        {"key":"hour","value":i18next.t("Hour")},
        {"key":"day","value":i18next.t("Day")},
    ];

    useEffect(()=>{
        onGroupChange("general");
    });

    return (
        <Layout sidebar={sidebar} title={i18next.t("General Settings")}>
            <div className="panel">
                <Back onClick={()=>{history.goBack()}} />
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">
                                            <div className="form__row">
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Select onChange={(e)=>{
                                                            onDataChange({...data,payment_provider:e.target.value});
                                                        }} label={i18next.t("Payment Provider")} selected={value.payment_provider} description={i18next.t("Payment Provider Description")} options={paymentProviders} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form__row">
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Select child={ (
                                                            <Input onChange={(e)=>{
                                                                onDataChange({...data,switch_billing_date:e.target.value});
                                                            }} value={value.switch_billing_date} type={"number"} style={{width:'20%',float:'left'}}  />
                                                        )
                                                        }
                                                                style={{width:'80%'}}
                                                                label={i18next.t("Switch to next billing period when billing date is due")} disabled={true} selected={"day"} options={times} />


                                                    </div>
                                                </div>
                                            </div>
                        </div>
                        <div className="settings__foot">
                            <div className="settings__btns">
                                <button className="settings__btn btn" onClick={()=>{
                                    onSubmit();
                                }}>{i18next.t("Update")}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(SettingsGeneral));
