import React, {useContext, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import i18next from "i18next";

const Invoice = ({history}) => {


    const sidebar = <SidebarChild history={history}/>;

    const provider = [
        {
            provider: "General",
            desc: "General",
            key: "general"
        },
        {
            provider: "Parasut",
            desc: "E-Invoice",
            key: "parasut"
        }
    ];
    return (
        <Layout sidebar={sidebar} title={i18next.t("Integrations")}>
            <div className="panel">
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">
                            <div className="data data_grid">
                                <div className="data__container">
                                    <div className="data__body">
                                        {provider.map((val,i)=>{
                                            return (
                                                <div key={i} className="data__item">
                                                    <div className="data__row">
                                                        <div className="data__cell">
                                                            <div className="data__main">
                                                                <div className="data__preview color-green"><i className="lab la-evernote " /></div>
                                                                <div className="data__wrap">
                                                                    <div className="data__content"><strong>{val.provider}</strong></div>
                                                                    <div className="data__label">{val.desc}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="data__cell">
                                                            <button className="settings__btn btn" onClick={()=>{
                                                                history.push("/settings/invoice/"+val.key)
                                                            }}>{i18next.t("Edit")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(Invoice));
