import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Avatar from "react-avatar";
import {checkUrl} from "../../utils/func";
import {ProfileContext} from "../../contexts/ProfileContext";
import i18next from "i18next";
import ReactTooltip from 'react-tooltip';
import {I18nextProvider} from "react-i18next";
import ReactGA from "react-ga";
import {DomainContext} from "../../contexts/DomainContext";

const Sidebar = ({openMenu,fullName}) => {
    const history = useHistory();
    const context = useContext(ProfileContext);
    const domain = useContext(DomainContext);
    const lock = context.value.requiredCreditCard;

    return (
        <div className={"sidebar__nav "}>
            <nav className={"nav js-nav "+(openMenu ? 'extended' : '')}>
                <a className="nav__link nav__link_head" href="#">
                    <div className="nav__preview"><img className="nav__pic" src="/assets/img/logo.png" alt="Logo" /></div>
                </a>
                <div className="nav__list">
                    {/* mobile primary menu*/}
                    <div className="nav__primary js-nav-primary">
                        <div className="nav__group">

                            <a className={"nav__link "+(history.location.pathname==='/collections' ? "active" : "")} onClick={()=>{
                                if(!lock)
                                    history.push("/collections")

                            }} data-tip={i18next.t(context.value.isAdmin ? "Collections" : "Payments")} title={i18next.t(context.value.isAdmin ? "Collections" : "Payments")}>

                                <div className="nav__preview"><i className="la la-images " /></div>
                                <div className="nav__title">{i18next.t(context.value.isAdmin ? "Collections" : "Payments")}
                                </div>
                            </a>

                            <a className={"nav__link " + checkUrl(history.location.pathname, '/payment-method')+ " "+(context.value.isAdmin ? "hidden" : null)}
                               onClick={() => {
                                   if(!lock)
                                    history.push("/payment-methods")

                                   ReactGA.pageview(window.location.pathname + window.location.search);
                               }} data-tip={i18next.t("Payment Methods")} title={i18next.t("Payment Methods")}>
                                <div className="nav__preview"><i className="la la-credit-card "/></div>
                                <div className="nav__title">{i18next.t("Payment Methods")}</div>
                            </a>
                            {/*

                            */}
                            <a className={"nav__link " + checkUrl(history.location.pathname, '/subscriptions')+ " "+(!context.value.isAdmin || !context.value.company.isSubscription ? "hidden" : null)}
                               onClick={() => {
                                   if(!lock)
                                       history.push("/subscriptions")

                                   ReactGA.pageview(window.location.pathname + window.location.search);
                               }} data-tip={i18next.t("Subscriptions")} title={i18next.t("Subscriptions")}>
                                <div className="nav__preview"><i className="la la-money-bill-wave"/></div>
                                <div className="nav__title">{i18next.t("Subscriptions")}</div>
                            </a>

                            <a className={"nav__link " + checkUrl(history.location.pathname, '/account')+ " "+(!context.value.isAdmin ? "hidden" : null)}
                               onClick={() => {
                                   if(!lock)
                                    history.push("/accounts")

                                   ReactGA.pageview(window.location.pathname + window.location.search);
                               }} data-tip={i18next.t("Accounts")} title={i18next.t("Accounts")}>
                                <div className="nav__preview"><i className="la la-user-lock "/></div>
                                <div className="nav__title">{i18next.t("Accounts")}</div>
                            </a>

                            <a className={"nav__link " + checkUrl(history.location.pathname, '/setting')+ " "+(!context.value.isAdmin ? "hidden" : null)}
                               onClick={() => {
                                   if(!lock)
                                    history.push("/settings/users")

                                   ReactGA.pageview(window.location.pathname + window.location.search);
                               }} data-tip={i18next.t("Settings")} title={i18next.t("Settings")}>
                                <div className="nav__preview"><i className="la la-cog "/></div>
                                <div className="nav__title">{i18next.t("Settings")}</div>
                            </a>

                            <a className="nav__link nav__link_menu js-nav-link-menu" href="#">
                                <div className="nav__preview"><i className="la la-bars "></i></div>
                            </a>

                            <a className="nav__link nav__link_user nav__bottom" onClick={()=>{
                                if(!lock)
                                    history.push('/profile/edit');

                                ReactGA.pageview(window.location.pathname + window.location.search);
                            }} data-tip={i18next.t("Profile")} title={fullName}>
                                <div className="nav__preview">
                                    <Avatar name={fullName} maxInitials={2} round={true} size={48}/>
                                    <div className="nav__online online" />
                                </div>
                                <div className="nav__title">{fullName}</div>
                            </a>

                        </div>
                    </div>


                </div>
            </nav>
            <ReactTooltip place="top" type="dark" effect="solid"/>
        </div>
    );
}

export default Sidebar;
