import React, {useContext, useEffect, useState} from 'react';
import Input from "../../components/Input";
import AuthHOC from "../HOC/Auth";
import {withRouter} from "react-router-dom";
import {useInvite, useRegister} from "../../hooks/useAuth";
import i18next from "i18next";
import {DomainContext} from "../../contexts/DomainContext";

const Invite = ({history,match}) => {
    const domainContext = useContext(DomainContext);
    const { data ,onChange ,onSubmit,onChangeHash ,validation} = useInvite();

    useEffect(()=>{
            onChangeHash(match.params.hash);
    },[]);

    return (
        <div className="auth">
            <div className="auth__main" style={{backgroundImage: 'url("/assets/img/bg-login-mobile.jpg")'}}>
                <div className="auth__wrap">
                    <div className="auth__preview"><img className="auth__pic" src="/assets/img/logo-white.svg" width={48} alt="Logo" /></div>
                    <div className="auth__title title title_xl">{i18next.t("Subcharger invite page title",{
                        merchant:domainContext.value.name
                    })}</div>
                </div>
            </div>
            <div className="auth__container">
                <div className="auth__inner">
                    {domainContext.value?.logo ? <img src={domainContext.value.logo} /> : null} <br />
                    <div className="auth__head">
                        <div className="auth__title title title_xl">
                            <p>{i18next.t("Subcharger invite page title",{
                                merchant:domainContext.value.name
                            })}</p>
                            <br />
                            <p>{data?.company?.name}</p>
                        </div>
                    </div>


                        <div className="field auth__field">
                            <div className="field__label">{i18next.t("Full name")}</div>
                            <div className="field__wrap">
                                <Input onChange={(val)=>{

                                }}
                                       placeholder={i18next.t("Full name placeholder")}
                                       readony={true}
                                       value={data?.full_name}

                                />
                                <div className="field__icon"><i className="la la-user-alt " /></div>
                            </div>
                        </div>
                        <div className="field auth__field">
                            <div className="field__label">{i18next.t("Email")}</div>
                            <div className="field__wrap">
                                <Input onChange={(val)=>{

                                }}
                                       placeholder={i18next.t("Email placeholder")}
                                       type="email"
                                       readony={true}
                                       value={data?.contact_email}

                                />
                                <div className="field__icon"><i className="la la-envelope " /></div>
                            </div>
                        </div>
                        <div className="field auth__field">
                            <div className="field__label">{i18next.t("Password")}</div>
                            <div className="field__wrap">
                                <Input onChange={(e)=>{
                                    onChange({password:e.target.value})
                                }}
                                       placeholder={i18next.t("Password placeholder")}
                                       type="password"
                                       errors={validation?.password}
                                />
                                <div className="field__icon"><i className="la la-lock " /></div>
                            </div>
                        </div>

                    <div className="field auth__field">
                        <div className="field__label">{i18next.t("Password Confirmation")}</div>
                        <div className="field__wrap">
                            <Input onChange={(e)=>{
                                onChange({password_confirmation:e.target.value})
                            }}
                                   placeholder={i18next.t("Password Confirmation placeholder")}
                                   type="password"
                            />
                            <div className="field__icon"><i className="la la-lock " /></div>
                        </div>
                    </div>
                        <div className="auth__flex"><label className="switch auth__switch"><input className="switch__input" type="radio" name="terms" defaultChecked /><span className="switch__content">{i18next.t("I agree with terms & conditions")}</span></label></div>
                        <div className="auth__btns">
                            <button className="btn auth__btn" onClick={onSubmit}>{i18next.t("Sign Up")}</button>
                            <button className="btn btn_light auth__btn" onClick={()=>{
                            history.push("/auth/login")
                        }}>{i18next.t("Sign In")}</button></div>
                </div>
            </div>
            <div className="auth__bg" style={{backgroundImage: 'url("'+(domainContext.value?.sidebar ? domainContext.value?.sidebar : (domainContext.value?.sidebar===null ? "/assets/img/bg-login-sign-in.jpg" : ''))+'")'}}/>
        </div>

    );
}

export default withRouter (AuthHOC(Invite));
