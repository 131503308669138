import React, {useContext} from "react";
import {checkUrl} from "../../utils/func";
import {ProfileContext} from "../../contexts/ProfileContext";
import i18next from "i18next";
const SidebarChild = ({history}) => {
    const path = history.location.pathname;
    const context = useContext(ProfileContext);
    return (
        <div className="sidebar__section">
            {/* widget*/}
            <div className="widget">
                <div className="widget__head">
                    <div className="widget__title title title_sm">{i18next.t("Profile")}</div>
                </div>
                <div className="widget__body">
                    {/* updates*/}
                    <div className="updates sidebar_child">
                        <div className={"updates__item "+checkUrl(path,'/profile/edit')} onClick={()=>{ history.push('/profile/edit') }}>
                            <div className="updates__icon"><i className="la la-user-friends " /></div>
                            <div className="updates__name">{i18next.t("Update Information")}</div>
                        </div>
                        <div className={"updates__item "+checkUrl(path,'/profile/change-password')} onClick={()=>{ history.push('/profile/change-password') }}>
                            <div className="updates__icon"><i className="la la-credit-card " /></div>
                            <div className="updates__name">{i18next.t("Change Password")}</div>
                        </div>
                        {context.value.isAdmin && false ?
                            <div className={"updates__item "+checkUrl(path,'/profile/payment-method')} onClick={()=>{ history.push('/profile/payment-method') }}>
                                <div className="updates__icon"><i className="la la-receipt" /></div>
                                <div className="updates__name">{i18next.t("Add Payment Method")}</div>
                            </div>
                            : null}
                        <div className={"updates__item "+checkUrl(path,'/profile/notification')} onClick={()=>{ history.push('/profile/notification') }}>
                            <div className="updates__icon"><i className="la la-cogs " /></div>
                            <div className="updates__name">{i18next.t("Notification settings")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const SideBarBottom = ({history}) => {
    return (
        <div className={"sidebarBottomBtn"}>
            <button className="action" onClick={()=>{
                history.push('/auth/logout')
            }}><i className="la la-power-off" /> {i18next.t("Logout")}</button>
        </div>
    )
};
export {
    SidebarChild,
    SideBarBottom
};
