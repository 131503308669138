import * as R from "ramda";
import config from '../config';
import React from "react";
import i18next from "../i18n";
import money from "currency-formatter";
import moment from "moment";
import {toast} from "react-toastify";
import indicator from "ordinal/indicator";
const cleanNullOneLevel = data => R.filter(i => i !== null, data);

export function capitalize ([first, ...rest], lowerRest = false)  {
    return first.toUpperCase() + (lowerRest ? rest.join('').toLowerCase() : rest.join(''));
}


export function cleanNull (data) {
    return R.compose(
        R.map(item => {
            if (R.is(Object, item)) {
                return cleanNullOneLevel(cleanNull(item));
            } else {
                return item;
            }
        }),
        cleanNullOneLevel
    )(data);
};


export function headersAction(){

    const token = localStorage.getItem(config.token_name) || false;

    if(token !== false){
        return {
            Authorization: "Bearer " + token
        }
    }

    return {}
};

export function convertSelect(){

}

export default function getStatusColorByAccount(status) {
    let color;
    switch (status.toLowerCase()) {
        case 'invited':
        case 'draft':
            color = "yellow";
            break;
        case 'open':
            color = "green";
            break;
        case 'invited_failed':
            color = "red";
            break;
        case 'closed':
            color = "gray";
            break;
        default:
            color = "blue";
    }

    return color;
}

export function getStatusColorBySubscription(status) {
    let color;
    switch (status.toLowerCase()) {
        case 'paused':
            color = "yellow";
            break;
        case 'active':
            color = "green";
            break;
        case 'canceled':
            color = "red";
            break;
        default:
            color = "blue";
    }

    return color;
}

export function statusSubscriptions(status,tooltip=null) {
    const color = getStatusColorBySubscription(status);
    return (
        <div className={"tag "+color}  data-tip={tooltip}>{i18next.t(status)}</div>
    );
}




export function statusAccount(status,tooltip=null) {
    const color = getStatusColorByAccount(status);
    return (
        <div className={"tag "+color}  data-tip={tooltip}>{i18next.t(status)}</div>
    );
}

export  function getStatusColorByCollection(status) {
    let color;
    switch (status.toLowerCase()) {
        case 'void':
        case 'pending':
        case 'past_due':
            color = "yellow";
            break;
        case 'paid':
        case 'processing':
            color = "green";
            break;

        case 'failed':
            color = "red";
            break;
        case 'refunded':
            color = "gray";
            break;
        default:
            color = "blue";
    }

    return color;
}

export function statusCollection(status,tooltip=null) {
    const color = getStatusColorByCollection(status);

    return (
        <div className={"tag "+color} data-tip={tooltip}>{i18next.t(capitalize(status,true))}</div>
    );
}

export  function getStatusColorByPlan(status) {
    let color;
    switch (status.toLowerCase()) {
        case "active":
            color = "green";
            break;
        case "passive":
            color = "gray";
            break;
        default:
            color = "blue";
    }

    return color;
}

export function statusPlan(status,tooltip=null) {
    const color = getStatusColorByPlan(status);

    return (
        <div className={"tag "+color} data-tip={tooltip}>{i18next.t(capitalize(status,true))}</div>
    );
}

/**
 * @return {number}
 */
export function TaxCalc(price,rate,include=false){

    if(include){
        return (price) / (1 + (rate/100));
    }

    return (price) * (1 + (rate/100));

}

export function badge(color,text){
    return (<div className={"tag "+color}>{text}</div>);
}


export function sumObject(obj){
    const total = Object.values(obj);
    return R.sum(total)
}

export function moneyFormat(amount,code){
    return money.format(amount,{code: code});
}

export function dateFormat(date,format="DD MMM YYYY"){

    if(date===undefined){
        return null;
    }
    date = moment(date).format(format);
    if(date.includes("Invalid")){
        return null;
    }

    return date;
}

export function checkUrl (url,search,active='active',deactive='') {
    return url.indexOf(search)>-1 ? active : deactive;
}

export function error(message){
    if(message===undefined) return null;
    toast.error('⛔️ '+message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function success(message=""){
    toast.success('✅️ '+i18next.t("Success") + message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
export function urlParams (location) {

    return new URLSearchParams(location);
};


export function redirectLogin(){
    const urlHash = window.location.href;
    const isInnerPage = !urlHash.includes('/auth/');
    if (isInnerPage) {
        //window.location = '/auth/login';
    }
}

export function isAuthPage(){
    const urlHash = window.location.href;
    return urlHash.includes('/auth/');
}


export function redirectCreditCard(){
    const urlHash = window.location.href;
    const isInnerPage = !urlHash.includes('/payment-method');
    if (isInnerPage) {
        window.location = '/payment-methods';
    }
}


export function debounce(f, ms) {

    let timer = null;

    return function (...args) {
        const onComplete = () => {
            f.apply(this, args);
            timer = null;
        }

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(onComplete, ms);
    };
}


export function scrollToTop (duration) {
    // cancel if already on top
    if (document.scrollingElement.scrollTop === 0) return;

    const cosParameter = document.scrollingElement.scrollTop / 2;
    let scrollCount = 0, oldTimestamp = null;

    function step (newTimestamp) {
        if (oldTimestamp !== null) {
            // if duration is 0 scrollCount will be Infinity
            scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
            if (scrollCount >= Math.PI) return document.scrollingElement.scrollTop = 0;
            document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
}


export function useQuery(lc) {
    return new URLSearchParams(lc.search);
}


export const chargeTypes = [
        {"key":"BatchCharge","value":i18next.t("Batch collect invoices at specified dates")},
        {"key":"InstantCharge","value":i18next.t("Immediately attempt to charge invoices as they come")},
        {"key":"BeforeCharge","value":i18next.t("Try to collect bills ahead of time")},
        {"key":"PlanCharge","value":i18next.t("Collect according to plan settings")}
    ];

export const productTypes = [
        {"key":"digital","value":i18next.t("Digital")},
        {"key":"physical","value":i18next.t("Physical")},
    ];

export function statusShow(status,status_text,tooltip=null) {
    const color = status ? "green" : "red";

    return (
        <div className={"tag "+color} data-tip={tooltip}>{i18next.t(capitalize(status_text,true))}</div>
    );
}

export function ord(number){
    if(i18next.language==="tr"){
        return "";
    }
    return indicator(number);
}
