
import React, {useContext, useEffect, useState} from "react";
import * as R from 'ramda';
import {error, success, sumObject} from "../utils/func";
import {
    getCollections,
    putCollection,
    getCollectionSummary,
    chargeCollection,
    getCollectionsExportUrl, startCycleCollection
} from "../api/collection";
import * as moment from "moment";
import {ProfileContext} from "../contexts/ProfileContext";
import {getAccountsExportUrl, sendPaymentMethodNotify} from "../api/account";
import {call} from "ramda";
import {useHistory} from "react-router-dom";

export const useCollections = ({onUpdate=f=>f,onLoadData=f=>f}) => {
    const context = useContext(ProfileContext);

    const history = useHistory();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [update, setUpdate] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [activeMonth, setActiveMonth] = useState(null);
    const [filters, setFilters] = useState({
        "period" : activeMonth,
        status:"progress",
        sort_key:"bill_date",
        order:"desc",
        try_count:"tried"
    });
    const [summaryLoading,setSummaryLoading] = useState(false);
    const [summary,setSummary] = useState({
        all : 0,
        scheduled : 0,
        unpaid: 0,
        paid: 0,
        refunded: 0,
        all_count : 0,
        scheduled_count : 0,
        unpaid_count: 0,
        paid_count: 0,
        refunded_count: 0,
        next_charge_cycle:null,
        available_failed_invoices:0,
        not_available_failed_invoices:0,
        progress:0,
        progress_count:0

    });

    const [chargeInvoice,setChargeInvoice] = useState(null);
    const [chargeStatus,setChargeStatus] = useState((<><i className="la la-spinner la-spin" /> Please wait, collection started..</>));
    const [exportFile, setExportFile] = useState(false);

    const refreshState = () => {
        setActiveMonth(activeMonth);
        setSummaryLoading(false);
        setFilters({...filters,fake:Math.random()});
        setChargeStatus(null);
        setChargeInvoice(null);
    };


    useEffect(()=>{
        if(context.value.isAdmin!=undefined){
            const period = context.value.activePeriod
                setActiveMonth(period);
            setTimeout(()=>{
             setSummaryLoading(false);
             setFilters({...filters,period:period});
            },100);

        }
        console.log("guncell line : 62");
    },[...Object.values(context)]);

    useEffect(()=>{
        if(chargeInvoice!=null){
            const charge = async () => {
                try{
                    const res = await chargeCollection(chargeInvoice);
                    setChargeStatus(<><i className="la la-thumbs-o-up" /> Charge successfull..</>);
                }catch (e) {

                    setChargeStatus(<><i className="la la-thumbs-o-down" /> {e.data.error.message}</>);
                }
            }

            charge();

        }
        console.log("guncell line : 80");
    },[chargeInvoice]);

    useEffect(()=>{
        setPage(1);
    },[filters]);

    useEffect((...args) => {
        const getData = async () => {
            const res = await getCollections({
                page: page,
                filters: filters,
            });

            await setData(res.data)
            await setTotalPage(res.meta.last_page)
            onLoadData();
            setLoading(true);
        };
        setLoading(false);

        if(activeMonth!=null){

            getData();
        }
        return () => {

        };
    }, [page,filters]);




    useEffect(()=>{

        const getData = async () => {
            const res = await getCollectionSummary({
                    "month": activeMonth
            });
            await setSummary({
                all : res.total,
                scheduled : res.pending,
                unpaid: res.failed,
                paid: res.paid,
                refunded: res.refund,
                progress: res.progress,
                all_count : res.total_count,
                scheduled_count : res.pending_count,
                progress_count : res.progress_count,
                unpaid_count: res.failed_count,
                paid_count: res.paid_count,
                refunded_count: res.refund_count,
                next_charge_cycle: res.next_charge_cycle,
                available_failed_invoices:res.available_failed_invoices,
                not_available_failed_invoices:res.not_available_failed_invoices,

            })
            await setSummaryLoading(true);
        };
        if( activeMonth !== null || !setSummaryLoading){
            getData();

        }


    },[activeMonth,summaryLoading]);

    useEffect(()=>{
        const updateData = async () => {
            if(R.prop('id',update)!==undefined){
                try{
                    const res = await putCollection(update.id,update.data);
                    update.callback()
                    onUpdate();
                    success("")
                }catch (e) {
                    error("ERROR");
                }
            }
        };
        updateData();
        console.log("guncell line : 153");
    },[update]);

    useEffect(()=>{

        if(exportFile){

            const getExportFile = async () => {
                const res = await getCollectionsExportUrl({
                    page: page,
                    filters: filters,
                });


                window.location.href = res.url;
            }
            getExportFile();
            setExportFile(false);
        }
        console.log("guncell line : 172");
    },[exportFile]);

    return {
        setChargeInvoice,
        setChargeStatus,
        data,
        totalPage,
        page,
        summary,
        activeMonth,
        loading,
        onChangeActivePeriod : (e) => {
            let date = activeMonth;

            if(e === "prev"){
                if(date=="all"){
                    date = moment().add(1,'month').date(1).format("YYYY-MM-DD")
                }else{
                    date = moment(activeMonth).subtract(1,'month').format("YYYY-MM-DD")
                }
            }else if(e === "next"){
                date = moment(activeMonth).add(1,'month').format("YYYY-MM-DD")
            }else if(e === "all"){
                date = "all";
            }

            if(date.indexOf("Invalid")>-1){
                date = moment(moment(context.value.activePeriod).format("YYYY-MM")).subtract(0,'month').format("YYYY-MM")
            }
            setActiveMonth(date);
            setSummaryLoading(false);
            setFilters({...filters,period: date})
        },
        onChangePagination : (e) => {
            setPage(e);
        },
        onChangeFilters: (e,removeItems=[]) => {

            const newFilters = R.omit(removeItems, filters);

            setFilters({...newFilters,...e})
        },
        onChangePage: (i) => {
          setPage(i)
        },
        onChange: (id,data,callback) => {
           if(data.status==="__MANUEL_PAY"){
               setChargeInvoice(id);
           }else{
                setUpdate({
                    id: id,
                    data: data,
                    callback:callback
                });
           }
        },
        setRefresh: () => {
           refreshState();
        },
        chargeInvoice,
        chargeStatus,
        onExport: () => {
            setExportFile(true);
        },
        actions : {
            startCycle: () => {


                const startCycles = async () => {
                    try{
                        const res = await startCycleCollection();
                        success();
                        const e = {status:"scheduled"};
                        setFilters({...filters,...e})
                        setSummaryLoading(false)
                    }catch (e) {
                        error(e.message)
                    }
                }

                startCycles();
            }
        }

    }
};
