import {del, get, post, put} from "../utils/http";

export const createUser = async (data) => {
    try {
        return await post('/users',data);
    }catch (e) {
        throw e.response.data.error;
    }
}


export const deleteUser = async (id) => {
    try {
        return await del('/users/'+id);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const updateUser = async (id,data) => {
    try {
        return await put('/users/'+id,data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getUsers = async (filters) => {
    try {
        return await get('/users',filters);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getUser = async (id) => {
    try {
        return await get('/users/'+id);
    }catch (e) {
        throw e.response.data.error;
    }
}


export const getNotificationSettings = async () => {
    try {
        return await get('/user/notifications');
    }catch (e) {
        throw e.response.data.error;
    }
}

export const postNotificationSettings = async (data) => {
    try {
        return await post('/user/notifications',data);
    }catch (e) {
        throw e.response.data.error;
    }
}
