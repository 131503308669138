import React, {useEffect, useState} from "react";
import ReactTooltip from "react-tooltip";

const DropdownMenu = ({openText=<i className="la la-ellipsis-h "/>,closeText=<i className="la la-times"/>,children,onClick,active,open,currentActive,closeMenu=f=>f}) => {

    return (
        <>
            {open ?
                <div className={"action_container"} style={{width:0}}>
                    <div className={"action_wrapper"} >
                        {children}
                    </div>
                </div>
            : ''}
            <div className="action action_stroke" onClick={()=>{
                    onClick();
                    ReactTooltip.rebuild();

            }}>
                <div className={"c-p"}>
                    {active!=currentActive ?
                        openText : closeText}
                </div>
            </div>
        </>
    )
}

export  {DropdownMenu};
