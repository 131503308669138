import React from 'react';
import * as R from 'ramda';
import Input from "./index";

export default function Image({title,onChange}) {
    return (
        <>
            <div className="control"><input className="control__file" onChange={onChange} type="file" />
                <div className="control__icon"><i className="la la-cloud-upload-alt "/></div>
                <div className="control__wrap">
                    <div className="control__title">{title}</div>
                </div>
            </div>
        </>
    );
}
