import React, {useContext, useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import AppHOC from "../HOC/App";
import Blank from "../layouts/blank";
import sample from './sample';
import {moneyFormat, TaxCalc} from "../../utils/func";
import {useCheckout} from "../../hooks/useCheckout";
import {DomainContext} from "../../contexts/DomainContext";
import config from '../../config';
const Checkout = ({history,i18}) => {

    const domain = useContext(DomainContext);
    const {plans,settings} = useCheckout();

    console.log(settings);

    return (
        <Blank>
            <div className="layout__row">

                <div className="layout__panel panel">
                    <div className="panel__head">
                        <div className="panel__group" style={{width:'100%',textAlign:'center'}}>
                            <div className="panel__title title">{settings.page_title}</div>
                        </div>
                    </div>
                    <div className="panel__body">
                        <div className="data data_grid">
                            <div class="data__container">
                                <div class="data__body" style={{paddingTop:'0px'}}>
                                    {
                                        plans.map((item)=>{

                                            console.log(settings.plans?.["plan_"+item.id]);
                                            return (
                                                <div className="data__item responsive_flexbox" key={item.id} style={{paddingTop:"0px",maxHeight:'initial'}}>
                                                    <div className="data__row">
                                                        <div className="data__cell">
                                                            <div className="data__main">
                                                                <div className="data__preview data__preview_large checkout_plan"><img onClick={()=>{
                                                                    history.push('/checkout/'+item.uuid);
                                                                }} className={"c-p"} width={200}
                                                                    src={config.storage_url+settings.plans?.["plan_"+item.id]?.image_url}/></div>
                                                                <div className="data__wrap">
                                                                    <div className="data__content"><strong>{settings.plans?.["plan_"+item.id]?.title}</strong></div>
                                                                    <div className="data__label" dangerouslySetInnerHTML={{__html: settings.plans?.["plan_"+item.id]?.description}}/>

                                                                    {settings.plans?.["plan_"+item.id]?.sale_price > 0 ?
                                                                    <span style={{
                                                                        "fontSize": "14x",
                                                                        "textDecoration": "line-through",
                                                                        display:"block",
                                                                        color:"#a59d9d",
                                                                        marginTop:"20px" //item.price
                                                                    }}>
                                                                        {moneyFormat(settings?.hide_tax ?  TaxCalc(settings.plans?.["plan_"+item.id]?.sale_price,settings?.tax_rate,true) : settings.plans?.["plan_"+item.id]?.sale_price,'TRY')}</span>
                                                                        : null}

                                                                    <strong style={{fontSize:"24px"}}>{moneyFormat(settings?.hide_tax ? TaxCalc(item.price,18,true) : item.price,'TRY')}</strong>
                                                                    {settings?.hide_tax ? <div className="data__label">(KDV Hariç)</div> : null}

                                                                </div>
                                                                <div className="data__foot">

                                                                    <Link className="btn" to={'/checkout/'+item.uuid} style={{margin:"10px auto"}}>Satın Al</Link>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </Blank>
    );
}

export default withRouter (AppHOC(Checkout));
