import React, {useContext} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import routes from "./routes";
import {ProfileContext, ProfileProvider} from "./contexts/ProfileContext";
import {DomainProvider} from "./contexts/DomainContext";
import ReactGA from "react-ga";
import { createBrowserHistory } from 'history';
import storage from "./utils/storage";
import config from "./config";
import {error} from "./utils/func";
import {toast} from "react-toastify";
import i18next from "i18next";
import "./assets/custom.sass"
const history = createBrowserHistory();

history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
const InternetConnection = () => {
    let toastMessage;
    window.addEventListener('offline', () => {
        toastMessage = toast.info('😱 '+i18next.t("Seems like you have no internet connection. Please check your connection."), {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            className:'full-width'
        });
    });
    window.addEventListener('online', () => {
        toast.dismiss(toastMessage);
    });

    return null;
};
  return (
        <Router history={history}>
            <Switch>
                <DomainProvider>
                    <ProfileProvider>
                            {routes.map((route, index) => {

                                /*if(storage.get(config.token_name,false)===false && !route.path.includes("auth") && !route.path.includes("checkout") ){
                                    return (<div key={index}>
                                    </div>);
                                }*/

                                return (<Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    children={<route.main/>}
                                />);

                            })}
                    <InternetConnection />
                    </ProfileProvider>
                </DomainProvider>
            </Switch>
        </Router>
  );
}

export default App;
