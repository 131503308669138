import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import Select from "../../../components/Select";
import {useSettings} from "../../../hooks/useSettings";
import Input from "../../../components/Input";
import i18next from "i18next";
import CodeMirror from '@uiw/react-codemirror';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/ttcn.css';
import Back from "../../layouts/back";
import * as R from 'ramda';

const TrackingCode = ({history}) => {


    const sidebar = <SidebarChild history={history}/>;

    const {data,onDataChange,onSubmit,onGroupChange} = useSettings();


    useEffect(()=>{
        onGroupChange("tracking_codes");
    });

    return (
        <Layout sidebar={sidebar} title={i18next.t("Tracking Codes")}>
            <div className="panel">
                <Back onClick={()=>{history.goBack()}} />
                <div className="settings">
                    <div className="settings__container">
                        <div className="settings__inner">
                                            <div className="form__row">
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <div className="field__label">{i18next.t("Google Analytics Tracking Id")}</div>
                                                        <div className="field__wrap">
                                                            <Input onChange={(e)=>{
                                                                onDataChange({...data,analytics_id:e.target.value});
                                                            }} value={data?.analytics_id}  />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form__row width_100 flex_100">
                                                <div className="form__col width_100 flex_100">
                                                    <div className="field form__field width_100">
                                                        <div className="field__label">{i18next.t("Success Page Codes")}</div>
                                                        <div className="field__wrap mt-20 width_100">
                                                            <CodeMirror
                                                                value={R.isEmpty(data?.success_page_scripts) || R.isNil(data?.success_page_scripts) ? "" : String(data?.success_page_scripts)}
                                                                options={{
                                                                    theme: 'ttcn',
                                                                    keyMap: 'sublime',
                                                                    mode: 'html',
                                                                    height:200
                                                                }}
                                                                height={400}
                                                                onChange={(e,code)=>{
                                                                    onDataChange({...data,success_page_scripts:(e.getValue())});


                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                        </div>
                        <div className="settings__foot">
                            <div className="settings__btns">
                                <button className="settings__btn btn" onClick={()=>{
                                    onSubmit();
                                }}>{i18next.t("Update")}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default withRouter (AppHOC(TrackingCode));
