import React, {useContext, useState} from "react";
import {dateFormat, moneyFormat} from "../../utils/func";
import * as moment from "moment";
import {ProfileContext} from "../../contexts/ProfileContext";
import i18next from "i18next";



const ChildItem = ({count,color='green',arrow='up',amount='$0',title,onClick=f=>f,active=false,subtext=''}) => {
    const context = useContext(ProfileContext);

    /*
        @Todo -> currency should change dynamically
    */
    return (
        <div className={"overview__item "+(active ? 'active' : '')} onClick={onClick}>
            <div className="overview__row">
                <div className="overview__col">
                    <div className="overview__value">{title}</div>
                </div>
                <div className="overview__col">
                    <div className="overview__value">{moneyFormat(amount, "TRY" )}<i className={"la la-arrow-"+arrow+" color-"+(arrow=="up" ? 'green' : 'red')} /></div>
                </div>
            </div>
            <div className="overview__label">{count} <span style={{float:"right"}}>{subtext}</span></div>
        </div>
    );
}

const SidebarChild = ({history,data,onChangeFilters,activeMonth,onChangePage=f=>f,menu,setMenu,refresh=f=>f}) => {
    const context = useContext(ProfileContext);
    const path = history.location.pathname;



    return (
        <>
        <div className="sidebar__section">
            {/* widget*/}
            <div className="widget">
                <div className="widget__head">
                    <div className="widget__title title title_sm">{activeMonth==="all" ?  i18next.t("All Time Collection Statement") : i18next.t("{{period}} Collections breakdown",{
                        period:moment(activeMonth).format('DD')=="01" ? moment(activeMonth).format('MMMM YYYY') : moment(activeMonth).format('MMMM') +"&"+ moment(activeMonth).add(1,'M').subtract(1,'d').format('MMMM YYYY')
                    })}</div>
                    <div className="widget__text">{
                        activeMonth==="all" ?
                        i18next.t("Invoices dated {{period}} are listed",{period:(moment().subtract(2,"year").format('DD.MM.YYYY')+" - "+moment().add(1,'month').endOf('month').format('DD.MM.YYYY'))})
                        :
                        i18next.t("Invoices dated {{period}} are listed",{period:(moment(activeMonth).format('DD.MM.YYYY')+" - "+moment(activeMonth).add(1,'M').subtract(1,'d').format('DD.MM.YYYY'))})
                    }. </div>
                </div>
                <div className="widget__body">
                    {/* updates*/}
                    <div className="overview sidebar_child">

                        <ChildItem amount={data.progress} count={data.progress_count+" "+i18next.t("invoice")} active={menu==="charge_progress"} onClick={()=>{
                            setMenu("charge_progress");
                            onChangeFilters({status:"progress",sort_key:"bill_date",order:"desc",try_count:"tried"});
                            onChangePage(1);
                            refresh();
                        }} title={i18next.t("Charge in Progress")} color="green" />
                        {context.value.isAdmin ?
                        <ChildItem subtext={dateFormat(data.next_charge_cycle,"DD MMM YYYY HH:mm")} amount={data.scheduled} count={data.scheduled_count+" "+i18next.t("invoice")} active={menu==="scheduled"} onClick={()=>{
                            setMenu("scheduled");
                            onChangeFilters({status:"scheduled",sort_key:"bill_date",order:"asc",try_count:"untried"});
                            onChangePage(1);
                            refresh();
                        }} title={i18next.t("Scheduled")} color="yellow"/> : null}
                        <ChildItem amount={data.paid} count={data.paid_count+" "+i18next.t("invoice")} active={menu==="paid"} onClick={()=>{
                            setMenu("paid");
                            onChangeFilters({status:"paid",sort_key:"bill_date",order:"desc"},["try_count"]);
                            onChangePage(1);
                            refresh();
                        }} title={i18next.t("Paid")} color="blue"/>
                        <ChildItem amount={data.unpaid} count={data.unpaid_count+" "+i18next.t("invoice")} active={menu === "unpaid"} onClick={() => {
                            setMenu("unpaid");
                            onChangeFilters({status: "failed",sort_key:"bill_date",order:"desc"},["try_count"]);
                            refresh();
                        }} title={i18next.t(context.value.isAdmin ? "Unpaid" : "Failed")} color="red" arrow="down"/>
                        <ChildItem amount={data.all} count={data.all_count+" "+i18next.t("invoice")} active={menu==="all"} onClick={()=>{
                            setMenu("all");
                            onChangeFilters({status:"all",sort_key:"bill_date",order:"desc"},["try_count"]);
                            onChangePage(1);
                            refresh();
                        }} title={i18next.t("All Invoices")} color="green"/>

                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

const SideBarBottom = ({onChangeFilters,activeMonth}) => {
    const context = useContext(ProfileContext);
    if(!context.value.isAdmin) return null;
    let nextBtn = moment().format("YYYY-MM-DD") >= activeMonth;
    console.log(activeMonth);
    let prevBtn = moment().subtract(2, 'month').format("YYYY-MM") !== activeMonth && activeMonth!=="all";

  return (
      <div className={"sidebarBottomBtn"}>

          <button className="action" onClick={()=>{
              onChangeFilters("prev")
          }}><i className="la la-angle-left " /> {i18next.t("Prev")}</button>

          {nextBtn ?
              activeMonth!="all" ? <button className="action" onClick={()=>{
              onChangeFilters("next")
          }}>{i18next.t("Next")} <i className="la la-angle-right " /></button> : null
              : activeMonth!=="all" ? <button className="action" onClick={()=>{
                  onChangeFilters("all")
              }}>{i18next.t("All Time")}</button> : null }
      </div>
  )
};

export {
    SidebarChild,
    SideBarBottom
};

