import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import * as R from "ramda";

import {useAccounts} from "../../hooks/useAccounts";
import Field from "../../components/Input/field";
import Back from "../layouts/back";
import Select from "../../components/Select";
import * as moment from "moment";
import {useCreditCard} from "../../hooks/useCreditCard";
import {type} from "ramda";
import i18next from "i18next";
import Blank from "../layouts/blank";
import * as yup from "yup";
import {post} from "../../utils/http";
import {ValidationError} from "yup";
import {error, moneyFormat, scrollToTop} from "../../utils/func";
import {usePublicPayment} from "../../hooks/usePublicPayment";





const SaveCreditCard = ({history,match}) => {


    const {minAmount,onChangeToken,tokenDetail} = usePublicPayment();


    const [form,setForm] = useState({});
    const [expireLink,setExpireLink] = useState(false);
    const [success,setSuccess] = useState(false);
    const [submit,setSubmit] = useState(false);
    const [threedsPopup,setThreedsPopup] = useState(false);
    const [threedsContent,setThreedsContent] = useState(null);

    const [validation,setValidation] = useState({});

    useEffect(()=>{
        onChangeToken(match.params.token);
    },[]);

    useEffect(()=>{
       if(tokenDetail===false){
           setExpireLink(true);
       }
    },[tokenDetail]);

    useEffect(() => {
        const handler = event => {

            const data = JSON.parse(event.data);

            if(data.status){
                setSuccess(true)
            }else{
                setSuccess(false);
                setThreedsPopup(false);
                setSubmit(false);
                error(data.message);
            }
        }

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
    }, [])

    useEffect(()=>{
        if(submit){
            let validSchema = {
                cc_holdername: yup.string().required(),
                cc_number: yup.string().required(),
                cc_exp_month: yup.string().required(),
                cc_exp_year: yup.string().required(),
                cc_cvc: yup.string().required(),
            };



            let schema = yup.object().shape(validSchema);


            const isValidInput = async () => {
                try {
                    setValidation([])
                    const valid = await schema.validateSync(
                        form,
                        { abortEarly: false }
                    );
                    setThreedsPopup(true);
                    setThreedsContent("Lütfen bekleyiniz..");

                    const res = await post('/public/cards/setup_3d',{
                        holdername:form.cc_holdername,
                        number:form.cc_number,
                        exp_month:form.cc_exp_month,
                        exp_year:form.cc_exp_year,
                        cvc:form.cc_cvc,
                        type:"save_payment_method",
                        user_token:match.params.token
                    });
                    setThreedsContent(res);



                } catch (err) {

                    if(err instanceof ValidationError){
                        let errors = [];
                        err.inner.map((item)=>{
                            errors = {...errors,[item.path]:"Lütfen geçerli bir değer giriniz."};
                        })
                        setValidation(errors)
                        setSubmit(false);
                        scrollToTop(400);
                    }
                }
            };

            isValidInput();
        }
    },[submit]);

    let months = [
        {"key":"01","value":"01"},
        {"key":"02","value":"02"},
        {"key":"03","value":"03"},
        {"key":"04","value":"04"},
        {"key":"05","value":"05"},
        {"key":"06","value":"06"},
        {"key":"07","value":"07"},
        {"key":"08","value":"08"},
        {"key":"09","value":"09"},
        {"key":"10","value":"10"},
        {"key":"11","value":"11"},
        {"key":"12","value":"12"},
    ];

    let years = [];
    for(let x = 0; x<= 10; x++){
        let year = moment().add(x,'year').format('YY');
        years.push({"key":year,"value":year})
    }

    if(expireLink){
        return (
            <Blank>
                <div className="layout">
                    <div className="layout__row">
                        <div className="layout__panel panel" style={{textAlign:"center"}}>
                            <div className="red alert-message"> <h2>Link süresi doldu, lütfen yeni link talep ediniz.</h2></div>

                        </div>
                    </div>
                </div>
            </Blank>
        );
    }

    return (
        <Blank>
            {success ?
                <>
                    <div className="layout">
                        <div className="layout__row">
                            <div className="layout__panel panel" style={{textAlign:"center"}}>
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 455.431 455.431" style={{enableBackground: 'new 0 0 455.431 455.431',width:"200px"}} xmlSpace="preserve">
                                    <path style={{fill: '#8DC640'}} d="M405.493,412.764c-69.689,56.889-287.289,56.889-355.556,0c-69.689-56.889-62.578-300.089,0-364.089
	s292.978-64,355.556,0S475.182,355.876,405.493,412.764z" />
                                    <g style={{opacity: '0.2'}}>
                                        <path style={{fill: '#FFFFFF'}} d="M229.138,313.209c-62.578,49.778-132.267,75.378-197.689,76.8
		c-48.356-82.489-38.4-283.022,18.489-341.333c51.2-52.622,211.911-62.578,304.356-29.867
		C377.049,112.676,330.116,232.142,229.138,313.209z" />
                                    </g>
                                    <path style={{fill: '#FFFFFF'}} d="M195.004,354.453c-9.956,0-19.911-4.267-25.6-12.8l-79.644-102.4
	c-11.378-14.222-8.533-34.133,5.689-45.511s34.133-8.533,45.511,5.689l54.044,69.689l119.467-155.022
	c11.378-14.222,31.289-17.067,45.511-5.689s17.067,31.289,5.689,45.511L220.604,341.653
	C213.493,348.764,204.96,354.453,195.004,354.453z" />
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                                <p style={{fontSize:"20px",color:"#5C5C5C"}}>Kredi Kartınız Başarıyla Eklenmiştir.</p>
                            </div>
                        </div>
                    </div>
                </>
            :
                <>
                    <div className="layout__row">
                    <div className="layout__panel panel">
                        <div className="panel__head">
                            <div className="panel__group" style={{width:'100%',textAlign:'center'}}>
                                <div className="panel__title title">{i18next.t("Add New Credit Card")}</div>
                            </div>
                        </div>
                    <div className="panel__body">
                        <div className="data data_grid">
                            <div className="data__container">
                                <div className="data__item responsive_flexbox mw-500 mb-40" style={{maxHeight:'initial'}}>

                                <div className="form__row">
                                    <div className="form__col width_100 flex_100">
                                        <div className="field form__field ">
                                            <Field onChange={(e)=>{
                                                setForm({...form,cc_holdername:e.target.value});
                                            }} errors={validation?.cc_holdername}  value={form?.cc_holdername} label={i18next.t("Card Name")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__col width_100 flex_100">
                                        <div className="field form__field">
                                            <Field onChange={(e)=>{
                                                setForm({...form,cc_number:e.target.value});
                                            }} errors={validation?.cc_number}  value={form?.cc_number} label={i18next.t("Card Number")} />
                                        </div>
                                    </div>
                                </div>
                                    <>
                                        <div className="form__row">
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Select onChange={(e)=>{
                                                        setForm({...form,cc_exp_month:e.target.value});
                                                    }} errors={validation?.cc_exp_month} selected={form?.cc_exp_month} label={i18next.t("Exp. Month")} options={months} />
                                                </div>
                                            </div>
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Select onChange={(e)=>{
                                                        setForm({...form,cc_exp_year:e.target.value});
                                                    }} errors={validation?.cc_exp_year} selected={form?.cc_exp_year} label={i18next.t("Exp. Year")} options={years} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__row">
                                            <div className="form__col width_100 flex_100">
                                                <div className="field form__field">
                                                    <Field onChange={(e)=>{
                                                        setForm({...form,cc_cvc:e.target.value});
                                                    }} errors={validation?.cc_cvc} value={form?.cc_cvc} label={i18next.t("Cvv")} />
                                                </div>
                                            </div>
                                        </div>



                                        {
                                            minAmount > 0 ?

                                        <div className="form__row">
                                            <div className="form__col width_100 flex_100">
                                                <div className="field form__field">
                                                    <div className="field__label text-right">{i18next.t("Çekilecek Tutar")}</div>
                                                    <h3 className="title title_md text-right mt-10">{moneyFormat(minAmount,"TRY")}</h3>
                                                    <div className="blue alert-message">Bu tutar kart saklama işleminden sonra iade edilecektir.</div>
                                                </div>
                                            </div>
                                        </div>
                                                : null }
                                    </>

                                    <button className="settings__btn btn width_100" onClick={()=>{
                                        setSubmit(true);
                                    }}>{i18next.t("Save")}</button>

                            </div>
                            </div>

                        </div>

                    </div>
                </div>
                </div>
                    <div className={"modal modal_sm js-modal "+(threedsPopup ? 'visible' : '')}>
                    <div className="modal__container js-modal-container">
                        <div className="modal__head">
                            <div className="modal__wrap">
                                <div className="modal__title">3DS Ödeme Sayfası</div>
                            </div><button className="modal__action action" style={{
                            "background": "white",
                            borderColor: "#F0F0F3"
                        }} onClick={(e)=>{
                            setThreedsPopup(false);
                            setForm({...form,submit:false})
                            setSubmit(false);
                        }}><i className="la la-close " /></button>
                        </div>
                        <div className="modal__body" style={{
                            "overflow": "auto",
                            "overflowX": "hidden",
                            "height": "calc(50vh)",
                            maxWidth: "calc(100vh)",
                        }}>
                            <iframe style={{width:'100%',height: "100%"}} srcDoc={`${threedsContent}`} />
                        </div>
                    </div>
                </div>
                </>}
        </Blank>
    );
}


export default withRouter (AppHOC(SaveCreditCard));
