import {useContext, useEffect, useState} from "react";
import {ProfileContext} from "../contexts/ProfileContext";
import {useHistory,useParams} from "react-router-dom";
import {forgotPassword, getInviteDetail, login, register, registerUser, resetPassword} from "../api/auth";
import storage from "../utils/storage";
import config from "../config.json";
import {error, success, urlParams} from "../utils/func";
import {get} from "../utils/http";
import {has} from "ramda";
import i18next from "i18next";

export const useLogin = () => {
    const context = useContext(ProfileContext);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [remember, setRemember] = useState(false);
    const [submit, setSubmit] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const apiLogin = async () => {
            if (submit) {
                try {
                    const response = await login({
                        email: email,
                        password: password,
                        remember_token: remember
                    });
                    storage.set(config.token_name, response.token);
                    storage.set("language", response.user.lang);
                    context.dispatch();
                    i18next.changeLanguage(storage.get("language"));
                    window.location = config.home_url;


                } catch (e) {
                    setSubmit(false);
                    error(e);
                }
            }
        }
        apiLogin();
        return () => {

        };
    }, [submit]);

    return {
        onEmailChange: (e) => {
            setEmail(e.target.value);
        },
        onPasswordChange: (e) => {
            setPassword(e.target.value);
        },
        onRememberChange: (e) => {
            setRemember(e.target.checked);
        },
        onSubmit: () => {
            setSubmit(true);
        }
    }
};


export const useForgotPassword = () => {
    const [email, setEmail] = useState(null);
    const [submit, setSubmit] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const apiLogin = async () => {
            if (submit) {
                try {
                    await forgotPassword({
                        email: email
                    });
                    success("");
                    history.push('/auth/login');
                } catch (e) {
                    setSubmit(false);
                    error(e);
                }
            }
        }
        apiLogin();
        return () => {

        };
    }, [submit]);

    return {
        onEmailChange: (e) => {
            setEmail(e.target.value);
        },
        onSubmit: () => {
            setSubmit(true);
        }
    }
};


export const useRegister = ({defaultEmail=null}) => {
    const [name, setName] = useState(null);
    const [company, setCompany] = useState(null);
    const [email, setEmail] = useState(defaultEmail);
    const [password, setPassword] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [validation,setValidation] = useState({
        name: null,
        company: null,
        email: null,
        password: null
    });
    const history = useHistory();
    const context = useContext(ProfileContext);

    useEffect(() => {
        const apiLogin = async () => {
            if (submit) {
                try {
                    const response = await register({
                        name: name,
                        company: company,
                        email: email,
                        password: password
                    });

                    storage.set(config.token_name, response.authToken);
                    storage.set("language", response.lang);
                    context.dispatch();
                    history.push(config.home_url);
                    history.push('/');

                } catch (e) {

                    setSubmit(false);
                    if(e.group === "validation"){
                        let validate = {};

                        for (let error in e.data){
                            validate = {...validate,[error] : e.data[error][0]};
                        }

                        setValidation(validate)
                    }else{
                        error(e.message);
                    }


                }
            }
        }
        apiLogin();
        return () => {

        };
    }, [submit]);

    return {
        validation,
        email,
        onNameChange: (e) => {
            setName(e.target.value);
            setValidation({...validation,name:null});
        },
        onCompanyChange: (e) => {
            setCompany(e.target.value);
            setValidation({...validation,company:null});
        },
        onEmailChange: (e) => {
            setEmail(e.target.value);
            setValidation({...validation,email:null});
        },
        onPasswordChange: (e) => {
            setPassword(e.target.value);
            setValidation({...validation,password:null});
        },
        onSubmit: () => {
            setSubmit(true);
        }
    }
};


export const useResetPassword = () => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [rePassword, setRePassword] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [validation,setValidation] = useState({
        password: null,
        rePassword: null,
    });
    const [token, setToken] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const apiLogin = async () => {
            if (submit) {
                try{
                    await resetPassword({
                        token: token,
                        email: email,
                        password:password,
                        password_confirmation:rePassword,
                    });
                    success("");
                    history.push('/auth/login')
                } catch (e) {
                    setSubmit(false);
                    if(e.group === "validation"){
                        let validate = {};

                        for (let error in e.data){
                            validate = {...validate,[error] : e.data[error][0]};
                        }

                        setValidation(validate)
                    }else{
                        error(e.message);
                    }
                }
            }
        };




        apiLogin();
        return () => {

        };
    }, [submit,token]);

    return {
        validation,
        onPasswordChange: (e) => {
            setPassword(e.target.value);
        },
        onRePasswordChange: (e) => {
            setRePassword(e.target.value);
        },
        onSubmit: () => {
            setSubmit(true);
        },
        setEmail: (e) => {
            setEmail(e);
        },
        setToken: (e) => {
            setToken(e);
        }
    }
};


export const useInvite = () => {

    const [hash,setHash] = useState(null);
    const [data,setData] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [validation, setValidation] = useState([]);
    const history = useHistory();
    const location = urlParams(window.location.href);
    const context = useContext(ProfileContext);
    useEffect(()=> {
        const getData = async () => {
            try {
                const res = await getInviteDetail(hash);
                setData(res);

            }catch (e) {
                error(e.message);
                //history.push("/auth/login");
            }
        };


        if(hash!=null){
            getData();
        }


    },[hash]);


    useEffect(()=>{

        if(submit){
            const registerData = async () => {
                try {
                    const response = await registerUser({
                        hash:hash,
                        account_id:data.id,
                        company_id: data.companies.id,
                        email:data.contact_email,
                        password:data.password,
                        password_confirmation: data.password_confirmation,
                        name: data.full_name
                    });
                    setData(response);
                    storage.set(config.token_name, response.authToken);
                    storage.set("language", config.locale);
                    context.dispatch();
                    history.push(config.home_url);
                    history.push('/');
                }catch (e) {

                    console.log(data);
                    console.log(e);
                    if(e.group === "validation"){
                        let validate = {};

                        for (let error in e.data){
                            validate = {...validate,[error] : e.data[error][0]};
                        }

                        setValidation(validate)
                    }else{
                        error(e.message);
                        //history.push("/auth/login");
                    }

                }



            };
            registerData();
            setSubmit(false);

        }

    },[submit]);

    return {
        data,
        validation,
        onChangeHash: (e) =>{
            setHash(e)
        },
        onChange: (e) => {
            setData({...data,...e});
        },
        onSubmit:() => {
            setSubmit(true);
        }
    }

};
