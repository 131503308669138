
import {useEffect, useState} from "react";
import {getPlans, getSubscriptionByEmail} from "../api/checkout";
import {getSettings} from "../api/setting";

export const useCheckout = () => {
    const [plans, setPlans] = useState([]);
    const [settings, setSettings] = useState({});
    const [form, setForm] = useState({});
    const [isExistsSubscription, setIsExistsSubscription] = useState(false);

    useEffect(()=>{

        const getPlansList = async () => {
            const res = await getPlans();
            setPlans(res);
        };

        const getSettingReq = async () => {
            const res = await getSettings("checkout");
            setSettings(res);
        };

        getSettingReq();
        getPlansList();

    },[]);

    useEffect(()=>{
        const getCheckEmail = async () => {

            const res = await getSubscriptionByEmail(form);
            if(res?.exists){
                setIsExistsSubscription(true);
            }else{
                setIsExistsSubscription(false);
            }
        }
        if(form?.plan !== undefined){
            getCheckEmail();
        }


    },[form]);

    return {
        plans,
        settings,
        isExistsSubscription,
        onChangeForm: (data)=>{
            setForm(data);
        }
    }
};
