import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";

import Back from "../../layouts/back";

import i18next from "i18next";
import Select from "../../../components/Select";
import {useIntegrations, usePlan} from "../../../hooks/usePlan";
import Input from "../../../components/Input";
import Radio from "../../../components/Radio";
import Checkbox from "../../../components/Checkbox";
import {chargeTypes, ord, productTypes} from "../../../utils/func";
import ordinal, {indicator} from "ordinal";



const PlanForm = ({history,match,edit}) => {

    const sidebar = <SidebarChild history={history}/>;
    const context = useContext(ProfileContext);
    const {form,onDataChange,validation,onSubmit} = usePlan();
    const {gateways} = useIntegrations("CRM");

    useEffect(()=>{
        if(edit){
            onDataChange({id:match.params.id});
        }

    },[edit]);

    useEffect(()=>{
        console.log(form);
    },[form])

    const periods = [
        {"key":"1_week","value":i18next.t("Weekly")},
        {"key":"1_month","value":i18next.t("Monthly")},
        {"key":"3_month","value":i18next.t("{{number}} Months",{number:3})},
        {"key":"6_month","value":i18next.t("{{number}} Months",{number:6})},
        {"key":"1_year","value":i18next.t("Yearly")},
        {"key":"customize","value":i18next.t("Customize")},
    ];

    const activeOptions = [
        {"key":"1","value":i18next.t("Active")},
        {"key":"0","value":i18next.t("Passive")},

    ];

    const freePeriods = [
        {"key":"none","value":i18next.t("No Trial")},
        {"key":"7_day","value":i18next.t("{{number}} Day",{number:7})},
        {"key":"14_day","value":i18next.t("{{number}} Day",{number:14})},
        {"key":"customize","value":i18next.t("Customize")},
    ];

    const times = [
        {"key":"day","value":i18next.t("Day")},
        {"key":"week","value":i18next.t("Week")},
        {"key":"month","value":i18next.t("Month")},
        {"key":"year","value":i18next.t("Yearly")},
    ];
    //console.log(gateways);


    const numbers = [
        {"key":1,"value":"1"},
        {"key":2,"value":"2"},
        {"key":3,"value":"3"},
        {"key":4,"value":"4"},
        {"key":5,"value":"5"},
        {"key":6,"value":"6"},
        {"key":7,"value":"7"},
        {"key":8,"value":"8"},
    ];

    const prorateTypes = [
        {"key":"0","value":i18next.t("Do not prorate")},
        {"key":"1","value":i18next.t("Enable proration")},
    ];

    let collectDay = [];

    if(form?.period_interval_unit==="week"){
        collectDay = [
            {"key":"monday","value":i18next.t("Every Monday")},
            {"key":"tuesday","value":i18next.t("Every Tuesday")},
            {"key":"wednesday","value":i18next.t("Every Wednesday")},
            {"key":"thursday","value":i18next.t("Every Thursday")},
            {"key":"friday","value":i18next.t("Every Friday")},
            {"key":"saturday","value":i18next.t("Every Saturday")},
            {"key":"sunday","value":i18next.t("Every Sunday")},
        ];
    }else if(form?.period_interval=="1" && form?.period_interval_unit==="month"){
        for (let i=1; i<7; i++){
            collectDay.push({"key":i+"_day","value":i18next.t("{{number}} day of the month",{
                    number:i18next.t("{{number}}"+ord(i),{number:i})
                })});
        }
    }else if((form?.period_interval>="1" && form?.period_interval_unit==="month") || form?.period_interval_unit==="year" ){
        for (let i=1; i<7; i++){
            collectDay.push({"key":i+"_day","value":i18next.t("{{number}} day of the period",{
                    number:i18next.t("{{number}}"+ord(i),{number:i})
                })});
        }
    }

    let billingDate = [];
    if(form?.period_interval_unit==="week"){
        billingDate = [
            {"key":"1","value":i18next.t("Every Monday")},
            {"key":"2","value":i18next.t("Every Tuesday")},
            {"key":"3","value":i18next.t("Every Wednesday")},
            {"key":"4","value":i18next.t("Every Thursday")},
            {"key":"5","value":i18next.t("Every Friday")},
            {"key":"6","value":i18next.t("Every Saturday")},
            {"key":"7","value":i18next.t("Every Sunday")},
        ];
    }else if(form?.period_interval=="1" && form?.period_interval_unit==="month"){
        for (let i=1; i<7; i++){
            billingDate.push({"key":i+"_day","value":i18next.t("{{number}} day of the month",{
                number:i18next.t("{{number}}"+ord(i),{number:i})
                })});
        }
    }else if((form?.period_interval>="1" && form?.period_interval_unit==="month") || form?.period_interval_unit==="year" ){
        for (let i=1; i<7; i++){
            billingDate.push({"key":i+"_day","value":i18next.t("{{number}} day of the period",{
                    number:i18next.t("{{number}}"+ord(i),{number:i})
                })});
        }
    }



    return (
            <Layout key={"section_1"} sidebar={sidebar} title={i18next.t(edit ? "Update Plan" : "New Plan")}>
                <div className="panel">
                    {
                        edit ?  <Back onClick={()=>{history.goBack()}} del={context.value.id!=form.id ? null : null}  /> : <Back onClick={()=>{history.goBack()}}  />
                    }

                    <div className="settings">
                        <div className="settings__container">
                            <div className="settings___inner">
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Field  label={i18next.t("Title")} value={form.title} type="text" onChange={(e)=>{
                                                onDataChange({
                                                    title: e.target.value
                                                })
                                            }} errors={validation?.title} />
                                        </div>
                                    </div>
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Select options={activeOptions} onChange={(e)=>{
                                                onDataChange({
                                                    is_active: e.target.value
                                                })
                                            }} selected={form.is_active} errors={validation?.is_active} label={i18next.t("Is Active?")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Field  label={i18next.t("Description")} value={form.description} type="text" onChange={(e)=>{
                                                onDataChange({
                                                    description: e.target.value
                                                })
                                            }} errors={validation?.description} />
                                        </div>
                                    </div>
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Select options={productTypes} onChange={(e)=>{
                                                onDataChange({
                                                    product_type: e.target.value
                                                })
                                            }} selected={form.product_type} errors={validation?.product_type} label={i18next.t("Product Type")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <h2 className={"panel-title"}>{i18next.t("Billing Period")}</h2>
                <div className="panel">
                    <div className="settings">
                        <div className="settings__container">
                            <div className="settings___inner">
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Select options={periods} onChange={(e)=>{
                                                const [interval,unit] = e.target.value.split("_");
                                                onDataChange({
                                                    period: e.target.value,
                                                    period_interval:interval,
                                                    period_interval_unit:unit
                                                });

                                            }} selected={form.period} errors={validation?.period} label={i18next.t("Period")} />
                                        </div>
                                    </div>
                                    {
                                        form?.period=="customize" ?
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Select child={ (
                                                        <Input onChange={(e)=>{
                                                            onDataChange({period_interval:e.target.value});
                                                        }} value={form?.period_interval} type={"number"} style={{width:'20%',float:'left'}}/>
                                                    )
                                                    }
                                                            style={{width:'80%'}}
                                                            onChange={(e)=>{
                                                                onDataChange({period_interval_unit:e.target.value});
                                                            }} label={i18next.t("Interval")} selected={form?.period_interval_unit} options={times.slice(1)} />
                                                </div>
                                            </div> : null
                                    }
                                </div>
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Field  label={i18next.t("Price")} value={form.price} type="number" onChange={(e)=>{
                                                onDataChange({
                                                    price: e.target.value
                                                })
                                            }} errors={validation?.price} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field checkbox_field">
                                            <Checkbox selected={form?.sync_renewals} dataTip={i18next.t("Synchronize Renewals")} label={i18next.t("Synchronize Renewals")} onClick={(e)=>{
                                                onDataChange({
                                                    sync_renewals: e.target.checked
                                                })
                                            }} />
                                        </div>
                                    </div>
                                </div>
                                {form?.sync_renewals===true ?
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Select onChange={(e)=>{
                                                onDataChange({renewals_collect_day:e.target.value});
                                            }} label={i18next.t("Billing Date")} selected={form?.renewals_collect_day} options={billingDate} />
                                        </div>
                                    </div>
                                </div>
                                : null}


                            </div>
                        </div>

                    </div>
                </div>
                {form?.sync_renewals===true && form?.product_type==="digital" ? <>
                <h2 className={"panel-title"}>{i18next.t("Proration")}</h2>
                <div className="panel">
                    <div className="settings">
                        <div className="settings__container">
                            <div className="settings___inner">
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Select onChange={(e)=>{
                                                onDataChange({
                                                    prorate: e.target.value
                                                })

                                            }} label={i18next.t("Prorate First Payment")} selected={form?.prorate} options={prorateTypes} />
                                        </div>
                                    </div>
                                </div>

                                {form?.product_type==="physical" && form?.prorate==="1" ?
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Select
                                                    child={ (
                                                            <Input onChange={(e)=>{
                                                                onDataChange({
                                                                    charge_sign_up_tolarence: e.target.value
                                                                })
                                                            }} value={form.charge_sign_up_tolarence} type={"number"} style={{width:'20%',float:'left'}} />
                                                        )
                                                        }
                                                    style={{width:'80%'}}
                                                    disabled={true}
                                                    label={i18next.t("Sign-up Grace Period")} selected={"day"} options={times} />


                                        </div>
                                    </div>
                                </div>
                                : null}

                            </div>

                        </div>

                    </div>
                </div>
                </> : null}
                {form?.sync_renewals===true && form?.product_type==="physical" ? <>
                    <h2 className={"panel-title"}>{i18next.t("Sign-up Grace Period")}</h2>
                    <div className="panel">
                        <div className="settings">
                            <div className="settings__container">
                                <div className="settings___inner">

                                        <div className="form__row">
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Select
                                                        child={ (
                                                            <Input onChange={(e)=>{
                                                                onDataChange({
                                                                    charge_sign_up_tolarence: e.target.value
                                                                })
                                                            }} value={form.charge_sign_up_tolarence} type={"number"} style={{width:'20%',float:'left'}} />
                                                        )
                                                        }
                                                        style={{width:'80%'}}
                                                        disabled={true}
                                                        label={i18next.t("Sign-up grace period")} selected={"day"} options={times} />


                                                </div>
                                            </div>
                                        </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </> : null}

                <h2 className={"panel-title"}>{i18next.t("Collection Policy")}</h2>
                <div className="panel">
                    <div className="settings">
                        <div className="settings__container">
                            <div className="settings___inner">
                                <div className="form__row">
                                    <div className="form__col">
                                        <div className="field form__field">
                                            <Select onChange={(e)=>{
                                                onDataChange({
                                                    collection_policy: e.target.value
                                                })

                                            }} label={i18next.t("Collection Policy")} selected={form?.collection_policy} options={chargeTypes.slice(0,-1)} />
                                        </div>
                                    </div>
                                    {form?.collection_policy==="BeforeCharge" ?
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Select child={ (
                                                    <Input onChange={(e)=>{
                                                        onDataChange({collect_day:e.target.value});
                                                    }} value={form?.collect_day} type={"number"} style={{width:'20%',float:'left'}}  />
                                                )
                                                }
                                                        style={{width:'80%'}}
                                                        label={i18next.t("How many days before the invoice will be collected?")} disabled={true} selected={"day"} options={times} />
                                            </div>
                                        </div> : null }
                                </div>
                                <div className="form__row">
                                    {form?.collection_policy==="BatchCharge" ?
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Select onChange={(e)=>{
                                                    onDataChange({collect_day:e.target.value});
                                                }} label={i18next.t("Collect time")} selected={form?.collect_day} options={collectDay} />
                                            </div>
                                        </div> : null }


                                </div>


                            </div>
                        </div>

                    </div>
                </div>

                <>
                    <h2 className={"panel-title"}>{i18next.t("Subscription Term")}</h2>
                    <div className="panel">
                        <div className="settings">
                            <div className="settings__container">
                                <div className="settings___inner">

                                    <Radio name={"term_length"} value={"recurring"} selected={form?.type}  label={i18next.t("Auto renew term")} onClick={()=>{
                                        onDataChange({
                                            type:'recurring'
                                        });
                                    }} />
                                    <Radio name={"term_length"} value={"oneof"} selected={form?.type} label={i18next.t("Expire subscription")} onClick={()=>{
                                        onDataChange({
                                            type:'oneof'
                                        });
                                    }} />

                                    <div className={"form__row "+(form?.type==="oneof" ? "" : "hidden")}>
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Field  label={i18next.t("Term Length")} value={form.term_length} type="number" onChange={(e)=>{
                                                    onDataChange({
                                                        term_length: e.target.value
                                                    })
                                                }} errors={validation?.term_length} />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                    <h2 className={"panel-title"}>{i18next.t("Free Trial Details")}</h2>
                    <div className="panel">
                        <div className="settings">
                            <div className="settings__container">
                                <div className="settings___inner">
                                    <div className="form__row">
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Select options={freePeriods} onChange={(e)=>{
                                                    const [interval,unit] = e.target.value.split("_");
                                                    onDataChange({
                                                        free_trial: e.target.value,
                                                        free_trial_interval:interval,
                                                        free_trial_interval_unit:unit,
                                                    })
                                                }} selected={form.free_trial} errors={validation?.free_trial} label={i18next.t("Trial Length")} />
                                            </div>
                                        </div>
                                        {
                                            form?.free_trial=="customize" ?
                                                <div className="form__col">
                                                    <div className="field form__field">
                                                        <Select child={ (
                                                            <Input onChange={(e)=>{

                                                                onDataChange({free_trial_interval:e.target.value});
                                                            }} value={form?.free_trial_interval} type={"number"} style={{width:'20%',float:'left'}}/>
                                                        )
                                                        }
                                                                style={{width:'80%'}}
                                                                onChange={(e)=>{
                                                                    onDataChange({free_trial_interval_unit:e.target.value});
                                                                }} label={i18next.t("Interval")} selected={form?.free_trial_interval_unit} options={times.slice(0,1)} />
                                                    </div>
                                                </div> : null
                                        }

                                    </div>
                                    {form?.free_trial!=="none" ?
                                        <div className="form__row">
                                            <div className="form__col">
                                                <div className="field form__field">
                                                    <Radio name={"billing_form"} value={true} selected={form.billing_form}  label={i18next.t("Billing Form Require")} onClick={()=>{
                                                        onDataChange({
                                                            billing_form:true
                                                        });
                                                    }} />
                                                    <Radio name={"billing_form"} value={false} selected={form.billing_form}  label={i18next.t("Billing Form don't Require")} onClick={()=>{
                                                        onDataChange({
                                                            billing_form:false
                                                        });
                                                    }} />

                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>

                            </div>

                        </div>
                    </div>
                    <h2 className={"panel-title"}>{i18next.t("Setup Fee")}</h2>
                    <div className="panel">
                        <div className="settings">
                            <div className="settings__container">
                                <div className="settings___inner">
                                    <div className="form__row">
                                        <div className="form__col">
                                            <div className="field form__field">
                                                <Field  label={i18next.t("Price")} value={form.setup_fee_price} type="number" onChange={(e)=>{
                                                    onDataChange({
                                                        setup_fee_price: e.target.value
                                                    })
                                                }} errors={validation?.setup_fee_price} />
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                    <h2 className={"panel-title"}>{i18next.t("Email Settings")}</h2>
                    <div className="panel">
                        <div className="settings">
                            <div className="settings__container">
                                <div className="settings___inner">
                                    <div className="form__row">
                                        <div className="form__col flex_100">
                                            <div className="field form__field checkbox_field">

                                                <Checkbox selected={form.email_templates?.new_subscription} dataTip="New Subscription" label={i18next.t("New Subscription")} onClick={(e)=>{
                                                    onDataChange({
                                                        email_templates:{
                                                            ...form.email_templates,
                                                            new_subscription:e.target.checked
                                                        }
                                                    });
                                                }} />
                                                <Checkbox selected={form.email_templates?.subscription_change} dataTip="Subscription Change" label={i18next.t("Subscription Change")} onClick={(e)=>{
                                                    onDataChange({
                                                        email_templates:{
                                                            ...form.email_templates,
                                                            subscription_change:e.target.checked
                                                        }
                                                    });
                                                }} />
                                                <Checkbox selected={form.email_templates?.subscription_expired} dataTip="Subscription Expired" label={i18next.t("Subscription Expired")} onClick={(e)=>{
                                                    onDataChange({
                                                        email_templates:{
                                                            ...form.email_templates,
                                                            subscription_expired:e.target.checked
                                                        }
                                                    });
                                                }} />
                                                <Checkbox selected={form.email_templates?.subscription_cancelled} dataTip="Subscription Cancelled" label={i18next.t("Subscription Cancelled")} onClick={(e)=>{
                                                    onDataChange({
                                                        email_templates:{
                                                            ...form.email_templates,
                                                            subscription_cancelled:e.target.checked
                                                        }
                                                    });
                                                }} />
                                                <Checkbox selected={form.email_templates?.new_invoice} dataTip="New Invoice" label={i18next.t("New Invoice")} onClick={(e)=>{
                                                    onDataChange({
                                                        email_templates:{
                                                            ...form.email_templates,
                                                            new_invoice:e.target.checked
                                                        }
                                                    });
                                                }} />
                                                <Checkbox selected={form.email_templates?.payment_confirmation} dataTip="Payment Confirmation" label={i18next.t("Payment Confirmation")} onClick={(e)=>{
                                                    onDataChange({
                                                        email_templates:{
                                                            ...form.email_templates,
                                                            payment_confirmation:e.target.checked
                                                        }
                                                    });
                                                }} />
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </>



                <button className="settings__btn btn width_100 mt-20" onClick={()=>{
                    onSubmit();
                }}>{i18next.t(edit ? 'Update' : 'Save')}</button>
            </Layout>
    );
}

export default withRouter (AppHOC(PlanForm));
