
import {useEffect, useState} from "react";
import * as R from 'ramda';
import {error, success, sumObject} from "../utils/func";
import {useHistory} from "react-router-dom";
import {
    getSubscriptions,
    getSubscriptionsExportUrl,
    getSubscriptionsSummary,
    putSubscription
} from "../api/subscription";


export const useSubscriptions = ({onLoadData=f=>f}) => {
    const [data, setData] = useState([]);
    const [entity, setEntity] = useState(0);
    const [form, setForm] = useState({
        company:null,
        contact_email:null,
        name:null,
        contact_phone_number:null,
    });
    const [validation,setValidation] = useState();
    const [submit, setSubmit] = useState(false);
    const [exportFile, setExportFile] = useState(false);
    const [update, setUpdate] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        status:"ACTIVE"
    });
    const [summaryLoading,setSummaryLoading] = useState(false);
    const [summary,setSummary] = useState({
        active : 0,
        canceled : 0,
        paused : 0,

    });
    const history = useHistory();

    useEffect(() => {
        const getData = async () => {
            const res = await getSubscriptions({
                page: page,
                filters: filters,
            });
            await setData(res.data)
            await setTotalPage(res.meta.last_page)
            setLoading(true);
            onLoadData();

        };
        setLoading(false);
        getData();
        return () => {

        };
    }, [page,filters]);

    useEffect(()=>{
        setPage(1);
    },[filters]);

    useEffect(()=>{
        const getData = async () => {
            const res = await getSubscriptionsSummary();
            await setSummary({
                ...res,
                all : res.ACTIVE + res.PAUSED + res.CANCELED,
                active : res.ACTIVE,
                paused : res.PAUSED,
                canceled : res.CANCELED,


            })
            await setSummaryLoading(true);
        };
        if(!summaryLoading){
            getData();
        }

    },[summaryLoading]);

    useEffect(()=>{
        const updateData = async () => {
            if(R.prop('id',update)!==undefined){
                try{
                    const res = await putSubscription(update.id,update.data);
                    update.callback()
                    success("")
                }catch (e) {
                    error("ERROR")
                }

            }
        };
        updateData();
    },[update]);

    useEffect(()=>{
        const syncData = async () => {

            try{
                const res = await putSubscription(form.id,form);

                success("")
                history.push("/accounts")
            }catch (e) {
                if(e.group === "validation"){
                    let validate = {};

                    for (let error in e.data){
                        validate = {...validate,[error] : e.data[error][0]};
                    }

                    setValidation(validate)
                }else{
                    error(e.message);
                }
            }

        };
        if(submit) {
            syncData();

        }
        setSubmit(false)
    },[submit]);


    useEffect(()=>{

        if(exportFile){

            const getExportFile = async () => {
                const res = await getSubscriptionsExportUrl({
                    page: page,
                    filters: filters,
                });


                window.location.href = res.url;
            }
            getExportFile();
            setExportFile(false);
        }

    },[exportFile]);




    return {
        form,
        validation,
        submit,
        data,
        totalPage,
        page,
        summary,
        loading,
        onChangePagination : (e) => {
            setPage(e);
        },
        onChangeFilters: (e) => {
            setFilters({...filters,...e})
        },
        onChange: (id,data,callback) => {
            setUpdate({
                id: id,
                data: data,
                callback:callback
            })
        },
        setRefresh: () => {
            setSummaryLoading(false);
            setFilters({...filters,fake:true});
        },
        onLoadEntity: (id) => {
            setEntity(id);
        },
        onDataChange: (v) => {
            setForm({...form,...v});
        },
        onSubmit: (v) =>{
            setSubmit(true)
        },
        onExport: () => {
            setExportFile(true);
        },
        onChangePage: (i) => {
            setPage(i)
        }
    }
};
