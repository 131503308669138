import React, {useEffect, useState} from "react";
import {useAccounts} from "../../hooks/useAccounts";
import SideBarChildItem from "../../components/Theme/SideBarChildItem";
import {badge} from "../../utils/func";
import i18next from "i18next";

const SidebarChild = ({history,data,onChangeFilters,defaultMenu="new"}) => {
    const path = history.location.pathname;
    const [menu,setMenu] = useState(defaultMenu);
    useEffect(()=>{
        console.log("güncelleniyor..")
    },[data]);
    return (
        <div className="sidebar__section">
            {/* widget*/}
            <div className="widget">
                <div className="widget__head">
                    <div className="widget__title title title_sm">{i18next.t("Payment Methods")}</div>
                    <div className="widget__text">{i18next.t("Your credit cards")}</div>
                </div>
                <div className="widget__body">
                    <div className="overview sidebar_child">
                        <SideBarChildItem key={"default"} active={menu==="new"} onClick={()=>{
                           history.push('/payment-method/new')
                            setMenu("new");
                        }} title={i18next.t("Add New Credit Card")} iconColor="blue" icon={"la la-credit-card-alt"} />
                        {
                            data.map((item)=>{
                                return (
                                    <>
                                        <SideBarChildItem key={item.id}  active={menu==item.id} onClick={()=>{
                                            history.push('/payment-method/'+item.id);
                                            setMenu(item.id);
                                        }} title={i18next.t('{{brand}} ending with {{digit}}',{
                                            brand:item.brand,
                                            digit:item.last_digit
                                        })} child={item.is_block ? <span className={"red"} style={{borderRadius:5,padding:'2px 10px'}}>{item.block_reason}</span> : null} iconColor={item.is_block ? 'red' : (item.is_default ? 'green' : 'blue')} icon={"la la-credit-card"} />
                                    </>
                                );
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SidebarChild;
