import React from "react";
import {checkUrl} from "../../utils/func";
import AppHOC from "../HOC/App";
import i18next from "i18next";

const SidebarChild = ({history}) => {
    const path = history.location.pathname;

    return (
        <div className="sidebar__section">
            {/* widget*/}
            <div className="widget">
                <div className="widget__head">
                    <div className="widget__title title title_sm">{i18next.t("Settings")}</div>
                </div>
                <div className="widget__body">
                    {/* updates*/}
                    <div className="updates sidebar_child">
                        <div className={"updates__item "+checkUrl(path,'/settings/users')} onClick={()=>{ history.push('/settings/users') }}>
                            <div className="updates__icon"><i className="la la-user-friends " /></div>
                            <div className="updates__name">{i18next.t("Users")}</div>
                        </div>
                        <div className={"updates__item "+checkUrl(path,'/settings/payment-method')} onClick={()=>{ history.push('/settings/payment-methods') }}>
                            <div className="updates__icon"><i className="la la-credit-card " /></div>
                            <div className="updates__name">{i18next.t("Payment Methods")}</div>
                        </div>
                        <div className={"updates__item "+checkUrl(path,'/settings/collections')} onClick={()=>{ history.push('/settings/collections') }}>
                            <div className="updates__icon"><i className="la la-receipt" /></div>
                            <div className="updates__name">{i18next.t("Collections")}</div>
                        </div>
                        <div className={"updates__item "+checkUrl(path,'/settings/general')} onClick={()=>{ history.push('/settings/general') }}>
                            <div className="updates__icon"><i className="la la-cogs" /></div>
                            <div className="updates__name">{i18next.t("General Settings")}</div>
                        </div>
                        <div className={"updates__item "+checkUrl(path,'/settings/invoice')} onClick={()=>{ history.push('/settings/invoice') }}>
                            <div className="updates__icon"><i className="la la-inbox " /></div>
                            <div className="updates__name">{i18next.t("Invoice")}</div>
                        </div>
                        <div className={"updates__item "+checkUrl(path,'/settings/integration')} onClick={()=>{ history.push('/settings/integrations') }}>
                            <div className="updates__icon"><i className="la la-plug " /></div>
                            <div className="updates__name">{i18next.t("Integrations")}</div>
                        </div>
                        <div className={"updates__item "+checkUrl(path,'/settings/plan')} onClick={()=>{ history.push('/settings/plans') }}>
                            <div className="updates__icon"><i className="la la-plug " /></div>
                            <div className="updates__name">{i18next.t("Plans")}</div>
                        </div>
                        <div className={"updates__item "+checkUrl(path,'/settings/checkout')} onClick={()=>{ history.push('/settings/checkout') }}>
                            <div className="updates__icon"><i className="la la-palette " /></div>
                            <div className="updates__name">{i18next.t("Checkout Settings")}</div>
                        </div>
                        <div className={"updates__item "+checkUrl(path,'/settings/email-template')} onClick={()=>{ history.push('/settings/email-templates') }}>
                            <div className="updates__icon"><i className="la la-envelope-open-text " /></div>
                            <div className="updates__name">{i18next.t("Email Templates")}</div>
                        </div>
                        <div className={"updates__item "+checkUrl(path,'/settings/tracking-codes')} onClick={()=>{ history.push('/settings/tracking-codes') }}>
                            <div className="updates__icon"><i className="la la-code " /></div>
                            <div className="updates__name">{i18next.t("Tracking Codes")}</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppHOC( SidebarChild );
