import React, {useEffect, useState} from 'react';
import {post} from "../utils/http";
import storage from "../utils/storage";
import i18next from "i18next";
import ReactTooltip from "react-tooltip";
import {isAuthPage, redirectCreditCard} from "../utils/func";
import {useHistory} from "react-router-dom";
import * as moment from "moment";

export const ProfileContext = React.createContext();


export function ProfileProvider (props) {
    const [value, setValue] = useState({
        email:null,
        company:{
            id:null,
            name:null,
            lang:null
        },
        requiredCreditCard:false
    });
    const history = useHistory();

    function getData(){
        async function fetchData() {
            const response = await post('/auth/check');
            setValue({
                ...response,
                show_name:(response.type==="ADMIN" ? response.company.name : response.name),
                isAdmin: response.type==="ADMIN",
                requiredCreditCard: response.requiredCreditCard
            });

            window.hj('identify', response.account_id, {
                email: response.email
            });


            if(response.requiredCreditCard){
                redirectCreditCard();
            }

            if(isAuthPage()){
                history.push("/");
            }

            if(storage.get("language")!=response.lang){
                storage.set("language",response.lang);
                i18next.changeLanguage(response.lang);


            }

            moment.locale(response.lang)

        }

        fetchData()
    }

    useEffect (() => {

        try{
            getData();


        }catch (e) {

        }
    }, [history.location.pathname]);


    const dispatch = () => {
        getData();
    };

    return (
        <ProfileContext.Provider value={{
            value,
            dispatch
        }}>
            {props.children}
        </ProfileContext.Provider>
    )
}
