import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import AppHOC from "../../HOC/App";
import {ProfileContext} from "../../../contexts/ProfileContext";
import Layout from "../../layouts";
import SidebarChild from "../sidebarChild";
import Field from "../../../components/Input/field";
import Table from "../../../components/Table/table";
import Head from "../../../components/Table/head";
import Avatar from "react-avatar";
import getStatusColorByAccount, {
    dateFormat,
    getStatusColorByPlan,
    moneyFormat,
    statusAccount, statusPlan
} from "../../../utils/func";
import {DropdownMenu} from "../../../components/Button";
import Pagination from "../../../components/Table/pagination";
import {useUsers} from "../../../hooks/useUsers";
import i18next from "i18next";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import {usePlan} from "../../../hooks/usePlan";
import ProgressIndicator from "../../../components/Theme/ProgressIndicator";

const Plans = ({history,location}) => {

    const context = useContext(ProfileContext);
    const sidebar = <SidebarChild history={history}/>;
    const { data ,totalPage,page,onChangePagination} = usePlan();




    return (
       <>
           <Layout sidebar={sidebar} title={i18next.t("Plans")}>
               <div>
                   {/* panel*/}
                   <div className="panel js-panel">
                       <div className="panel__head panel__head_line">
                           {/* btn group*/}
                           <div className="panel__group btn-group btn-group_tabs">
                               <button className="btn btn_light btn_icon" onClick={()=>{
                                   history.push("/settings/plan/create");
                               }}><i className="la la-pencil-alt "/> {i18next.t("New Plan")}</button>
                           </div>
                       </div>
                       <div className="panel__body">
                           <div className="panel__tab js-panel-tab" style={{display: 'block'}}>
                               {/* data*/}
                               <div className="data data_list">
                                   <div className="data__container">
                                       <Table head={
                                           <>
                                               <Head extraClassName="data__cell_md" sortKey={"name"}>{i18next.t("Plan")}</Head>
                                               <Head extraClassName="data__cell_md" sortKey={"email"}>{i18next.t("Price")}</Head>
                                               <Head extraClassName="data__cell_md" sortKey={"email"}>{i18next.t("Status")}</Head>
                                               <Head extraClassName={"data__cell_xs text-right"}>{i18next.t("Actions")}</Head>
                                           </>
                                       }>
                                           {
                                               data.map((v,i)=>{
                                                   return (
                                                       <div className="data__item" key={i}>
                                                           <div className="data__row">
                                                               <div className="data__cell data__cell_md c-p" onClick={()=>{
                                                                   history.push('/settings/plan/edit/'+v.id);
                                                               }}>
                                                                   <div className="data__main">
                                                                       <div className="data__avatar">
                                                                           <Avatar name={v.period_interval+" "+v.period_interval_unit}
                                                                                   maxInitials={2} round={true}
                                                                                   size={50}/>
                                                                       </div>
                                                                       <div className="data__wrap text_wrap_email">
                                                                           <div className="data__content">
                                                                               <strong>{v.title}</strong></div>
                                                                           <div className="data__label">{v.description}</div>
                                                                       </div>

                                                                   </div>
                                                               </div>
                                                               <div className="data__cell data__cell_md">
                                                                   <div className="data__content">{moneyFormat(v.price,v.currency)}</div>
                                                                   <div className="data__label">{i18next.t("Price")}</div>
                                                               </div>
                                                               <div className="data__cell data__cell_md">
                                                                   <div className="data__content">{statusPlan(v.is_active ? 'Active' : 'Passive')}</div>
                                                               </div>
                                                               <div className="data__cell data__cell_xs text-right">
                                                                   <div className="data__content">
                                                                       <div className="action action_stroke" onClick={()=>{
                                                                           history.push('/settings/plan/edit/'+v.id);
                                                                       }}>
                                                                           <div className={"c-p"}>
                                                                               <i className="la la-ellipsis-h "/>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   );
                                               })
                                           }
                                       </Table>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="panel__foot">
                           <Pagination totalPage={totalPage}  activePage={page} onChange={onChangePagination}/>
                       </div>
                   </div>
               </div>
           </Layout>
       </>
    );
}

export default withRouter (AppHOC(Plans));
