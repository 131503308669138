import React, {useState} from 'react';
import Layout from "../layouts";
import {ProfileProvider} from "../../contexts/ProfileContext";
import {withTranslation} from "react-i18next";
const AppHOC = (WrapperComponent) => {

    class HOC extends React.Component{
        constructor() {
            super();
        }
        render(){
            return (
                <WrapperComponent i18={this.props.i18n} {...this.props} />
            );
        }
    }

    return withTranslation()(HOC);

};

export default (AppHOC)
