import React from 'react';
import * as R from 'ramda';
import Input from "./index";
import i18next from "i18next";

export default function Select({selected="",onChange=f=>f,defaultValue='Select..',options,errors,label='',description='',icon='',child='',style,disabled=false}) {
    const isError = R.isNil(errors);
     defaultValue = i18next.t(defaultValue);
    return (
        <>
            <div className="field__label">{label}</div>
            <div className="field__wrap  mb-30">
                {child}
                <select disabled={disabled} value={selected || ''} style={style} onChange={onChange} className="field__select">
                    <option value={""}>{defaultValue}</option>
                    {
                        options.map((v,k)=>{
                            return (<option key={k} value={v.key}>{v.value}</option>)
                        })
                    }
                </select>

                {
                    !R.empty(icon) ? <div className="field__icon"><i className={"la la-"+icon} /></div> : ''
                }
                {
                    !R.empty(description) ? <em className={"description"}>{description}</em> : ''
                }

                {
                    isError ? null :
                        <>
                            <br />
                            <em className={"invalid_text"}>{errors}</em>
                            <br />
                        </>
                }

            </div>
        </>

    );
}
