import {useContext, useEffect, useState} from "react";
import {ProfileContext} from "../contexts/ProfileContext";
import {putSettings} from "../api/setting";
import {error, success} from "../utils/func";
import {profileEdit,changePassword as changePasswordApi} from "../api/profile";

export const useProfile = () => {
    const context = useContext(ProfileContext);

    const initialPasswordState = {
        current_password: null,
        password: null,
        password_confirmation:null
    };

    const initialValidation = {
        password:null,
        current_password:null

    };

    const [data, setData] = useState({
        profile: context.value,
        password : initialPasswordState
    });

    const [validation,setValidation] = useState(initialValidation);
    const [submit, setSubmit] = useState(false);

    // detail profile info
    useEffect(()=>{
        setData({...data,profile:{
                name:context.value.name,
                email:context.value.email,
                lang:context.value.lang,
                company:context.value.company?.name,
                company_lang:context.value.company?.lang,
                timezone:context.value.company?.timezone,
            }})
    },[context.value]);


    useEffect( () => {

        const saveProfileEdit = async () => {
            try{

                await profileEdit(data.profile);
                success("");
                context.dispatch();

            } catch (e) {

                error(e.message);

            }
        };

        const changePassword = async () => {
            try{

                await changePasswordApi(data.password);
                success("");
                setData({...data,password:initialPasswordState})
                setValidation(initialValidation)

            } catch (e) {

                if(e.group === "validation"){
                    let validate = {};

                    for (let error in e.data){
                        validate = {...validate,[error] : e.data[error][0]};
                    }

                    setValidation(validate)
                }else{
                    error(e.message);
                }

            }
        };

        if(submit==="profileEdit"){
            saveProfileEdit();
        }

        if(submit==="changePassword"){
            changePassword();

        }

        setSubmit(false)
    },[submit]);

    return {
        data,
        validation,
        onDataChange: (v) => {
            setData(v);
        },
        onSave: (target) => {
            setSubmit(target)
        }
    }
};
