import React, {useState} from "react";
import * as R from 'ramda';
const Head = ({children,extraClassName='',sortKey='',onClick}) =>{
    const [isDesc,setIsDesc] = useState(false);
    return (
        <div className={"data__cell "+extraClassName} onClick={()=>{
            if(!R.isEmpty(sortKey)){
                setIsDesc(!isDesc);
                onClick({"sort_key":sortKey,"order":isDesc ? 'desc' : 'asc'});
            }
        }}>
            <div className="data__filter">{children}
                {
                    R.isEmpty(sortKey)
                    ? null : (
                        isDesc ? <i className="la la-sort-alpha-up " /> : <i className="la la-sort-alpha-down " />
                        )
                }
            </div>
        </div>
    );
};

export default Head;
