import React from 'react';
import * as R from 'ramda';
import Input from "./index";
import i18next from "i18next";

export default function Checkbox({label,onClick,value,selected,dataTip}) {
    return (
        <>
            <label className="switch" data-tip={dataTip}><input className="switch__input" type="checkbox" checked={selected} onClick={onClick}/><span className="switch__content"> {label}</span></label>
        </>
    );
}
