import React from "react";
import Login from "../screens/Auth/Login";
import ForgotPassword from "../screens/Auth/ForgotPassword";
import Dashboard from "../screens/Dashboard/dashboard";
import storage from "../utils/storage";
import config from '../config';
import Register from "../screens/Auth/Register";
import ResetPassword from "../screens/Auth/ResetPassword";
import PaymentMethod from "../screens/Settings/PaymentMethod";
import Invoice from "../screens/Settings/Invoice";
import PaymentMethodDetail from "../screens/Settings/PaymentMethod/detail";
import Integration from "../screens/Settings/Integration";
import IntegrationDetail from "../screens/Settings/Integration/detail";
import SettingsCollections from "../screens/Settings/Collections";
import InvoiceDetail from "../screens/Settings/Invoice/detail";
import Accounts from "../screens/Accounts";
import SettingsIndex from "../screens/Settings";
import Users from "../screens/Settings/Users";
import UserForm from "../screens/Settings/Users/form";
import Collections from "../screens/Collections";
import ProfileDetail from "../screens/Profile/ProfileDetail";
import ChangePassword from "../screens/Profile/ChangePassword";
import NotificationsSetting from "../screens/Profile/NotificationsSetting";
import PaymentMethods from "../screens/PaymentMethods";
import Invite from "../screens/Auth/Invite";
import SettingsGeneral from "../screens/Settings/General";
import AccountForm from "../screens/Accounts/form";
import Checkout from "../screens/Checkout";
import Buy from "../screens/Checkout/buy";
import Success from "../screens/Checkout/success";
import Subscriptions from "../screens/Subscriptions";
import PlanForm from "../screens/Settings/Plans/form";
import Plans from "../screens/Settings/Plans";
import CheckoutSettings from "../screens/Settings/Checkout";
import EmailTemplates from "../screens/Settings/EmailTemplate";
import EmailTemplateForm from "../screens/Settings/EmailTemplate/form";
import TrackingCode from "../screens/Settings/TrackingCode";
import SaveCreditCard from "../screens/Public/saveCreditCard";
const routes = [
    {
        path: "/",
        exact: true,
        main: () => <Dashboard />
    },
    {
        path: "/auth/login",
        main: () => <Login />
    },
    {
        path: "/auth/register",
        main: () => <Register />
    },
    {
        path: "/auth/invite/:hash",
        main: () => <Invite />
    },
    {
        path: "/auth/logout",
        main: () => {
            storage.remove(config.token_name)
            window.location='/'
        }
    },
    {
        path: "/auth/forgot-password",
        main: () => <ForgotPassword />
    },
    {
        path: "/auth/reset-password/:token",
        main: () => <ResetPassword />
    },
    {
        path: "/settings/payment-method/:key",
        main: () => <PaymentMethodDetail />
    },
    {
        path: "/settings/payment-methods",
        main: () => <PaymentMethod />
    },
    {
        path: "/settings/collections",
        main: () => <SettingsCollections />
    },
    {
        path: "/settings/general",
        main: () => <SettingsGeneral />
    },
    {
        path: "/settings/invoice/:key",
        main: () => <InvoiceDetail />
    },
    {
        path: "/settings/invoice",
        main: () => <Invoice />
    },
    {
        path: "/settings/users/create",
        main: () => <UserForm />
    },
    {
        path: "/settings/user/:id",
        main: () => <UserForm edit={true} />
    },
    {
        path: "/settings/users",
        main: () => <Users />
    },
    {
        path: "/settings/integration/:key",
        main: () => <IntegrationDetail />
    },
    {
        path: "/settings/integrations",
        main: () => <Integration />
    },
    {
        path: "/settings/plan/create",
        exact:true,
        strict:true,
        main: () => <PlanForm />
    },{
        path: "/settings/plan/edit/:id",
        exact:true,
        strict:true,
        main: () => <PlanForm edit={true} />
    },
    {
        path: "/settings/plans",
        exact:true,
        strict:true,
        main: () => <Plans />
    },
    {
        path: "/settings/index",
        main: () => <SettingsIndex />
    },
    {
        path: "/profile/index",
        main: () => <SettingsIndex />
    },
    {
        path: "/profile/edit",
        main: () => <ProfileDetail />
    },
    {
        path: "/profile/notification",
        main: () => <NotificationsSetting />
    },
    {
        path: "/profile/change-password",
        main: () => <ChangePassword />
    },
    {
        path: "/profile/payment-method",
        main: () => <ProfileDetail />
    },
    {
        path: "/accounts",
        main: () => <Accounts />
    },
    {
        path: "/account/create",
        exact:true,
        sensitive:true,
        strict:true,
        main: () => <AccountForm />
    },
    {
        path: "/account/detail/:id",
        exact:true,
        strict:true,
        main: () => <AccountForm edit={true} />
    },
    {
        path: "/collections",
        main: () => <Collections />
    },
    {
        path: "/payment-methods",
        main: () => <PaymentMethods form={"new"} />
    },
    {
        path: "/payment-method/new",
        main: () => <PaymentMethods form={"new"} />
    },
    {
        path: "/payment-method/:id",
        main: () => <PaymentMethods form={"edit"} />
    },
    {
        path: "/checkout/success/:checkout_id",
        strict:true,
        exact:true,
        main: () => <Success/>
    },
    {
        path: "/checkout/:product_id",
        strict:true,
        exact:true,
        main: () => <Buy/>
    },
    {
        path: "/checkout",
        exact:true,
        main: () => <Checkout />
    },
    {
        path: "/subscriptions",
        exact:true,
        main: () => <Subscriptions />
    },
    {
        path: "/settings/checkout",
        strict:true,
        exact:true,
        main: () => <CheckoutSettings />
    },
    {
        path: "/settings/email-templates",
        strict:true,
        exact:true,
        main: () => <EmailTemplates />
    },
    {
        path: "/settings/email-template/:id",
        strict:true,
        exact:true,
        main: () => <EmailTemplateForm />
    },
    {
        path: "/settings/tracking-codes",
        main: () => <TrackingCode />
    },
    {
        path: "/public/save-credit-card/:token",
        main: () => <SaveCreditCard />
    }
];

export default routes;
