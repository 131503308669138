import {del, get, post, put} from "../utils/http";

export const getCreditCards = async (filters={}) => {
    try {
        return await get('/cards',filters);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const getCreditCard = async (id) => {
    try {
        return await get('/cards/'+id);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const saveCreditCard = async (data={}) => {
    try {
        return await post('/cards',data);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const deleteCreditCard = async (id) => {
    try {
        return await del('/cards/'+id);
    }catch (e) {
        throw e.response.data.error;
    }
}

export const defaultCreditCard = async (id) => {
    try {
        return await put('/cards/default/'+id);
    }catch (e) {
        throw e.response.data.error;
    }
}
