import React, {useContext, useEffect, useState} from 'react';
import Input from "../../components/Input";
import AuthHOC from "../HOC/Auth";
import { withRouter } from "react-router";
import {useResetPassword} from "../../hooks/useAuth";
import {urlParams} from "../../utils/func";
import i18next from "i18next";
import {DomainContext} from "../../contexts/DomainContext";
function ResetPassword({history,match,location}) {
    const domainContext = useContext(DomainContext);
    const {onPasswordChange,onRePasswordChange,onSubmit,validation,setToken,setEmail} = useResetPassword();

    useEffect(()=>{
        const token = match.params.token;
        const query = urlParams(location.search);
        setEmail(query.get("email"));
        setToken(token)
    });

    return (


                <div className="auth">
                    <div className="auth__main" style={{backgroundImage: 'url("/assets/img/bg-login-mobile.jpg")'}}>
                        <div className="auth__wrap">
                            <div className="auth__preview"><img className="auth__pic" src="/assets/img/logo-white.svg" width={48} alt="Logo" /></div>
                            <div className="auth__title title title_xl">{i18next.t("Reset Password")}</div>
                        </div>
                    </div>
                    <div className="auth__container">
                        <div className="auth__inner">
                            {domainContext.value?.logo ? <img src={domainContext.value.logo} /> : null} <br />
                            <div className="auth__head">
                                <div className="auth__title title title_xl">
                                    <p>{i18next.t("Reset Password")}</p>
                                </div>
                            </div>
                                <div className="field auth__field">
                                    <div className="field__label">{i18next.t("Password")}</div>
                                    <div className="field__wrap">
                                        <Input onChange={(val)=>{
                                            onPasswordChange(val)
                                        }}
                                               placeholder={i18next.t("New Password placeholder")}
                                               type="password"
                                               errors={validation.password}
                                        />
                                        <div className="field__icon"><i className="la la-lock " /></div>
                                    </div>
                                </div>
                                <div className="field auth__field">
                                    <div className="field__label">{i18next.t("Password Confirmation")}</div>
                                    <div className="field__wrap">
                                        <Input onChange={(val)=>{
                                            onRePasswordChange(val)
                                        }}
                                               placeholder={i18next.t("Password Confirmation placeholder")}
                                               type="password"
                                               errors={validation.rePassword}
                                        />
                                        <div className="field__icon"><i className="la la-lock " /></div>
                                    </div>
                                </div>
                                <div className="auth__btns"><button className="btn auth__btn" onClick={()=>{
                                    onSubmit();
                                }}>{i18next.t("Submit Reset Password")}</button>
                                    <button className="btn btn_light auth__btn" onClick={()=>{
                                        history.push("/auth/login");
                                    }}>{i18next.t("Sign In")}</button></div>
                        </div>
                    </div>
                    <div className="auth__bg" style={{backgroundImage: 'url("'+(domainContext.value?.sidebar ? domainContext.value?.sidebar : (domainContext.value?.sidebar===null ? "/assets/img/bg-login-sign-in.jpg" : ''))+'")'}}/>
                </div>
    );
}

export default withRouter( AuthHOC( ResetPassword ) );
