import React, {useContext, useState} from 'react';
import Input from "../../components/Input";
import AuthHOC from "../HOC/Auth";
import { withRouter } from "react-router";
import {useForgotPassword} from "../../hooks/useAuth";
import i18next from "i18next";
import {DomainContext} from "../../contexts/DomainContext";
function ForgotPassword({history}) {
    const domainContext = useContext(DomainContext);
    const {onEmailChange,onSubmit} = useForgotPassword();
    return (
        <div className="auth">
            <div className="auth__main" style={{backgroundImage: 'url("/assets/img/bg-login-mobile.jpg")'}}>
                <div className="auth__wrap">
                    <div className="auth__preview"><img className="auth__pic" src="/assets/img/logo-white.svg" width={48}
                                                        alt="Logo"/></div>
                    <div className="auth__title title title_xl">{i18next.t("Lost your password?")}</div>
                </div>
            </div>
            <div className="auth__container">
                <div className="auth__inner">
                    {domainContext.value?.logo ? <img src={domainContext.value.logo} /> : null} <br />
                    <div className="auth__head">
                        <div className="auth__title title title_xl">
                            <p>{i18next.t("Lost your password?")}</p>
                        </div>
                    </div>

                        <div className="field auth__field">
                            <div className="field__label">Email</div>
                            <div className="field__wrap">
                                <Input
                                onChange={(val)=>{
                                        onEmailChange(val);
                                    }}
                                placeholder={i18next.t("Enter your e-mail")}
                                type="email"
                            />
                                <div className="field__icon"><i className="la la-envelope "/></div>
                            </div>
                        </div>
                        <div className="auth__btns flex-end">
                            <button className="btn auth__btn" onClick={()=>{
                               onSubmit();
                            }}>{i18next.t("Recover")}</button>
                        </div>
                    <div className="auth__btns flex-end">
                        <button className="auth__btn" style={{textAlign:"right"}} onClick={()=>{
                            history.push("/auth/login");
                        }}>{i18next.t("Sign In")}</button>
                        </div>
                </div>
            </div>
            <div className="auth__bg" style={{backgroundImage: 'url("'+(domainContext.value?.sidebar ? domainContext.value?.sidebar : (domainContext.value?.sidebar===null ? "/assets/img/bg-login-sign-in.jpg" : ''))+'")'}}/>
        </div>

    );
}

export default withRouter( AuthHOC( ForgotPassword ) );
