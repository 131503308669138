import {useContext, useEffect, useState} from "react";
import {
    createUser,
    deleteUser,
    getNotificationSettings,
    getUser,
    getUsers,
    postNotificationSettings,
    updateUser
} from "../api/user";
import {useHistory} from "react-router-dom";
import * as R from "ramda";
import {error, success} from "../utils/func";
import {not} from "ramda";
import {forgotPassword} from "../api/auth";

export const useUsers = ({onLoad=f=>f}) => {
    const [data, setData] = useState([]);
    const [create, setCreate] = useState([]);
    const [update, setUpdate] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [filters, setFilters] = useState({});
    const [submit, setSubmit] = useState(false);
    useEffect(() => {
        const getData = async () => {
            const res = await getUsers({
                page: page,
                filters: filters,
            });
            await setData(res.data)
            await setTotalPage(res.meta.last_page)
            onLoad()

        };
        getData();
        return () => {

        };
    }, [page,filters]);

    useEffect(()=>{
        setPage(1);
    },[filters]);


    return {
        data,
        totalPage,
        page,
        onChangePagination : (e) => {
            setPage(e);
        },
        onChangeFilters: (e) => {
            setFilters({...filters,...e})
        },
        onCreateChange: (v) => {
            setCreate({...create,...v});
        }
    }
};


export const useUser = () => {

    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [validation,setValidation] = useState({
        name: null,
        email: null,
        password: null,
        rePassword: null,
    });
    const history = useHistory();

    useEffect(() => {
        const setData = async () => {
            try{
                if(R.prop('id',data)===undefined) {
                    const res = await createUser(data);
                }else{
                    const res = await updateUser(data.id,data);
                }
                success("");
                history.push('/settings/users');

            }catch (e) {
                setSubmit(false);
                if(e.group === "validation"){
                    let validate = {};

                    for (let error in e.data){
                        validate = {...validate,[error] : e.data[error][0]};
                    }

                    setValidation(validate)
                }else{
                    error(e.message);
                }
            }

        };
        if(submit){
            setData();
        }
        return () => {

        };
    }, [submit]);

    useEffect(()=>{
        const getData = async () => {
            if(R.prop('id',data)!==undefined && load) {
                try {
                    const res = await getUser(data.id);
                    setData({
                        id: res.id,
                        name: res.name,
                        email: res.email
                    });
                } catch (e) {
                    error(e.message);
                    history.goBack();
                }
            }
        };

        getData();
        setLoad(false);
    },[load]);

    useEffect(()=>{
        const deleteData = async() => {
            try {
                const res = await deleteUser(data.id);
                success("");
                history.push('/settings/users');
            } catch (e) {
                error(e.message);
                history.goBack();
            }
        };
        if(isDelete){
            deleteData();
        }
    },[isDelete]);


    return {
        data,
        validation,
        onDataChange: (v) => {
            setData({...data,...v});
        },
        onSubmit: (v) =>{
            setSubmit(true)
        },
        onLoadChange: () => {
            setLoad(true);
        },
        onDelete : () => {
            setIsDelete(true);
        },
        actions : {
            resetPassword : (email) => {
                const sendPassword = async (email) => {
                    try{
                        const res = forgotPassword({email:email});
                        success();
                    }catch (e) {
                        error(e)
                    }
                }

                sendPassword(email);

            },
        }
    }
};



export const useNotification = () => {
    const [notifications, setNotifications] = useState([]);
    const [submit,setSubmit] = useState(false);
    useEffect(()=>{
        const getNotifications = async () => {
          const res = await getNotificationSettings();
            setNotifications({...res})
        };

        getNotifications();
    },[]);

    useEffect(() => {
        if(submit){
            const saveNotificaions = async () => {
                const res = await postNotificationSettings(notifications);
                success();
            };
            saveNotificaions();
            setSubmit(false);
        }
    },[submit]);
    return {
        notifications,
        onSubmit: () => {
            setSubmit(true);
        },
        onChange: (v) => {
            setNotifications({...notifications,...v});
        }
    }
}
