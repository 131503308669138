import Select from "../Select";
import i18next from "i18next";
import Field from "../Input/field";
import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {post} from "../../utils/http";
import {ValidationError} from "yup";
import {scrollToTop} from "../../utils/func";

export const InvoiceForm = ({data,onChange,validation}) => {
    const [invoiceType,setInvoiceType] = useState("individual");
    const [submit,setSubmit] = useState(false);


    const invoiceTypes = [
        {"key":"individual","value":"Bireysel"},
        {"key":"corporate","value":"Kurumsal"}
    ];


    return (
        <div className="invoice_address_body">
            <div className="form__row">
                <div className="form__col" style={{flex:"100%"}}>
                    <div className="field form__field">
                        <Select onChange={(e)=>{
                            setInvoiceType(e.target.value);
                            onChange({invoice:{...data.invoice,invoiceType:e.target.value}});
                        }} defaultValue={"Seçiniz"}  label={i18next.t("Fatura Tipi")} errors={validation?.["invoice.invoiceType"]} selected={data?.invoice?.invoiceType} options={invoiceTypes} />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__col">
                    <div className="field form__field">
                        <Field onChange={(e)=>{
                            onChange({invoice:{...data.invoice,firstname:e.target.value}});
                        }} label="İsim" errors={validation?.["invoice.firstname"]} value={data?.invoice?.firstname} type="text" />
                    </div>
                </div>
                <div className="form__col">
                    <div className="field form__field">
                        <Field onChange={(e)=>{
                            onChange({invoice:{...data.invoice,lastname:e.target.value}});
                        }} label="Soyisim" errors={validation?.["invoice.lastname"]}  value={data?.invoice?.lastname} type="text" />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__col" style={{flex:"100%"}}>
                    <div className="field form__field">
                        <Field onChange={(e)=>{
                            onChange({invoice:{...data.invoice,email:e.target.value}});
                        }} label="Email" errors={validation?.["invoice.email"]} value={data?.invoice?.email} type="text" />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__col">
                    <div className="field form__field">
                        <Field onChange={(e)=>{
                            onChange({invoice:{...data.invoice,city:e.target.value}});
                        }} label="İl" errors={validation?.["invoice.city"]} value={data?.invoice?.city} type="text" />
                    </div>
                </div>
                <div className="form__col">
                    <div className="field form__field">
                        <Field onChange={(e)=>{
                            onChange({invoice:{...data.invoice,district:e.target.value}});
                        }} label="İlçe" errors={validation?.["invoice.district"]} value={data?.invoice?.district} type="text" />
                    </div>
                </div>
            </div>
            <div className="form__row">
                <div className="form__col" style={{flex:"100%"}}>

                    <Field className={"field__input"} onChange={(e)=>{
                        onChange({invoice:{...data.invoice,address:e.target.value}});
                    }} label="Adres" errors={validation?.["invoice.address"]} value={data?.invoice?.address} type="text" />

                </div>
            </div>

            {
                invoiceType=="individual" ?
                    <div className="form__row">
                        <div className="form__col">
                            <div className="field form__field">
                                <Field onChange={(e)=>{
                                    onChange({invoice:{...data.invoice,idNumber:e.target.value}});
                                }} label="TC kimlik Numarası" errors={validation?.["invoice.idNumber"]} value={data?.invoice?.idNumber} type="text" />
                            </div>
                        </div>
                    </div> :
                    <>
                        <div className="form__row">
                            <div className="form__col" style={{flex:"100%"}}>
                                <div className="field form__field">
                                    <Field onChange={(e)=>{
                                        onChange({invoice:{...data.invoice,companyName:e.target.value}});
                                    }} label="Şirket Adı" errors={validation?.["invoice.companyName"]} value={data?.invoice?.companyName} type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__col">
                                <div className="field form__field">
                                    <Field onChange={(e)=>{
                                        onChange({invoice:{...data.invoice,taxOffice:e.target.value}});
                                    }} label="Vergi Dairesi" errors={validation?.["invoice.taxOffice"]} value={data?.invoice?.taxOffice} type="text" />
                                </div>
                            </div>
                            <div className="form__col">
                                <div className="field form__field">
                                    <Field onChange={(e)=>{
                                        onChange({invoice:{...data.invoice,taxNumber:e.target.value}});
                                    }} label="Vergi Numarası" errors={validation?.["invoice.taxNumber"]} value={data?.invoice?.taxNumber} type="text" />
                                </div>
                            </div>
                        </div>
                    </>
            }


        </div>
    );
}
