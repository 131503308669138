export default class storage{
    static get(key , def=null){
        return localStorage.getItem(key) || def;
    }

    static set(key,value){
        return localStorage.setItem(key,value)
    }

    static remove(key){
        return localStorage.removeItem(key);
    }
};
