
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {getSettings, putSettings} from "../api/setting";
import {error, success} from "../utils/func";

export const useSettings = () => {
    const [group, setGroup] = useState(null);
    const [data, setData] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [goBack,setGoBack] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const setSetting = async () => {
            if (submit) {
                try{
                    await putSettings(group,data);
                    success("");
                    setSubmit(false);
                    if(goBack){
                        history.goBack();
                    }

                } catch (e) {
                    setSubmit(false);
                    error(e.message);

                }
            }
        };
        const getData = async () => {
            const res = await getSettings(group);
            await setData(res)
        };
        setSetting();
        if(group!=null) {
            getData();
        }
        return () => {

        };
    }, [submit,group]);

    return {
        data,
        onGroupChange: (e) => {

            setGroup(e);
        },
        onDataChange: (v) => {
            setData(v);
            console.log(data);
        },
        onGoBackChange: (e) => {
          setGoBack(e);
        },
        onSubmit: () => {
            setSubmit(true);
        }
    }
};
