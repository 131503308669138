import {useContext, useEffect, useState} from "react";
import {getIntegrations} from "../api/util";
import {getPlan, getPlans, savePlan, updatePlan} from "../api/plan";
import {error, success} from "../utils/func";
import {useHistory} from "react-router-dom";
import * as R from "ramda";
import {getEmailTemplate, getEmailTemplates, updateEmailTemplate} from "../api/emailTemplate";


export const useEmailTemplate = () => {
    const [data, setData] = useState([]);
    const [form, setForm] = useState({
        id:0,
        variables:[]
    });
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [filters, setFilters] = useState({});
    const [validation, setValidation] = useState([]);
    const [submit, setSubmit] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const getData = async () => {
            const res = await getEmailTemplates({
                page: page,
                filters: filters,
            });
            await setData(res.data)
            await setTotalPage(res.meta.last_page )

        };
        getData()
    }, [page,filters]);

    useEffect(()=>{
        const getEmailTemplateData = async () => {
            const res = await getEmailTemplate(form.id);
            let response = [];
            delete res.id;
            res.template.forEach((item)=>{
                response = {...response,[item.lang]:item};
            });
            await setForm({...form,...res,template:response})
        };
        if(form.id>0){
            getEmailTemplateData()
        }
        console.log(form.id+" değişti");
    },[form.id]);

    useEffect(()=>{
        setPage(1);
    },[filters]);

    useEffect(()=>{
        const saveTemplateReq = async () => {
            try {
                 const res = await updateEmailTemplate(form.id,{
                     template:form.code,
                     data:form.template
                 });
                success();
                history.push("/settings/email-templates");
            }catch (e) {
                error(e);
            }
        };

        if(submit){
            setSubmit(false);
            saveTemplateReq();

        }

    },[submit]);




    return {
        data,
        form,
        validation,
        totalPage,
        page,
        onSubmit:() => {
            setSubmit(true);
        },
        onChangePagination : (e) => {
            setPage(e);
        },
        onChangeFilters: (e) => {
            setFilters({...filters,...e})
        },
        onDataChange: (data) => {
            setForm({...form,...data});
        }
    }
};



