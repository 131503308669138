import * as lang from "./locales";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import config from "./config";
import storage from "./utils/storage";

const resources = {
    en: {
        translation: lang.en
    },
    tr: {
        translation: lang.tr
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: resources,
        lng: config.locale,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        keySeparator:false
    });
i18n.changeLanguage(storage.get("language"));
export default i18n;
